import axios from 'axios';
import querystring from 'querystring';
import jwtDecode from 'jwt-decode';
import { get, omit } from 'lodash';
import history from 'utils/history';
import { setProgress } from 'actions/Loading';
import { store } from '../';

import {
  checkTokenExpiration,
  checkRefreshTokenExpiration,
  saveToken,
  saveRefreshToken,
  clearTokens
} from 'utils/token';

import {
  baseUrl,
  trainingServiceUrl,
  logServiceUrl,
  keycloakUrl,
  realm,
  clientId,
  dmsUrl,
  dmsApiVersion,
  userApiVersion,
  trainingApiVersion,
  statisticServiceUrl,
  logApiVersion,
  messageServiceUrl,
  messageApiVersion,
  actuatorUrl,
} from 'config/environment';

import { flatDataToTreeNew, flatDataToTreeNewTrash } from "utils/documentTreeHelper";
import { Slide, toast } from "react-toastify";

const getFlowId = () => {
  return Math.random().toString().substr(2);
}

const getErrorMessage = (res, defaultMessage) => {
  if (res.localizedMessage) return res.localizedMessage;
  else if (res.message && typeof res.message === 'string') return res.message;
  else if (res.title && typeof res.title === 'string') return res.title;
  return defaultMessage;
}

axios.interceptors.request.use(
  async (config) => {
    config.onUploadProgress = function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      store.dispatch(setProgress(percentCompleted));
    };

    config.headers['Accept-Language'] = 'en-US;';

    if (
      config.url.includes(keycloakUrl)
      || config.url.includes('up-password-with-check')
      || config.url.includes('send-mail/forgot')
    ) return Promise.resolve(config);

    const token = checkTokenExpiration();
    if (token && token !== 'expired') {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.withCredentials = true;
    } else if (token && token === 'expired') {
      const refreshToken1 = checkRefreshTokenExpiration();
      if (refreshToken1 && refreshToken1 !== 'expired') {
        const { error, response } = await refreshToken(refreshToken1);
        if (!error) {
          saveToken(response.access_token);
          saveRefreshToken(response.refresh_token);

          config.headers['Authorization'] = 'Bearer ' + response.access_token;
          config.withCredentials = true;

        } else {
          clearTokens();
          history.push('/login');
        }
      } else {
        clearTokens();
        history.push('/login');
      }
    }
    else if (!token) {
      clearTokens();
      history.push('/login');
    }
    return Promise.resolve(config);
  },
  error => {
    Promise.reject(error)
  });

const getUsers = (data = {}) => {
  let requestUrl = `${baseUrl}/api/users/v2.0/?size=${data.pageSize || 100}&page=${data.page}`;
  if (data.filterParam) requestUrl += `&userContains=${data.filterParam}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => {
      const res = {
        ...response.data,
        content: get(response.data, 'content', []),
        totalPages: get(response, 'data.totalPages'),
        totalElements: get(response, 'data.totalElements'),
        clubName: get(data, 'clubName', ''),
        currentPage: get(data, 'page', 0)
      };
      return ({
        response: res
      })
    })
    .catch(err => {
      return ({error: { ...get(err, 'response.data', {}), message: getErrorMessage(get(err, "response.data", {}), 'Failed to get users')}})
    })
}

const getUser = (id) => {
  return axios.get(`${baseUrl}/api/users/${id}/${userApiVersion}/`)
    .then(response => ({response: response.data}))
    .catch(err => ({error: { ...get(err, 'response.data', {}), message: getErrorMessage(get(err, "response.data", {}), 'Failed to get user')}}))
}

const getFilteredUsers = (data) => {
  return axios.get(`${baseUrl}/api/users/filter/${userApiVersion}/?size=${data.pageSize || 20}&page=${get(data, 'page', 0)}&clubName=${get(data, 'clubName', '')}`)
    .then(response => {
      return {
        response: {
          ...response.data,
          content: get(response.data, 'content', []),
          totalPages: get(response, 'data.totalPages'),
          clubName: get(data, 'clubName', ''),
          currentPage: get(data, 'page', 0)
        }
      }
    })
    .catch(err => ({error: { ...get(err, 'response.data', {}), message: getErrorMessage(get(err, "response.data", {}), 'Failed to get users')}}))
}

const getLicenseeClubs = () => {
  return axios.get(`${baseUrl}/api/licensee/my-account/clubs/${userApiVersion}/`)
    .then(response => ({response: response.data}))
    .catch(err => ({error: { ...get(err, 'response.data', {}), message: getErrorMessage(get(err, "response.data", {}), 'Failed to get licensee clubs')}}))
}

const createUser = (data) => {
  const { payload, avatar } = data;

  return axios.post(`${baseUrl}/api/users/v1.1/`, payload)
    .then(response => {
      if (avatar) {
        uploadUserImage({ file: avatar, userId: get(response, 'data.id') });
      }
      return ({ response: response.data });
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create user')
      }
    }))
}

const updateUser = async (data) => {
  const payload = omit(data, [ 'address' ]);
  return axios.put(`${baseUrl}/api/user-detail/${userApiVersion}/`, payload)
    .then(response => {
      if (get(data, 'address')) {
        return updateUserAddress({
          ...get(data, 'address', {}),
          userId: parseInt(data.userId)
        });
      } else {
        return { response: response.data };
      }

    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user')
      }
    }))
}

const updateUserClub = async (data) => {
  return axios.put(`${baseUrl}/api/users/${data.id}/${userApiVersion}/`, { clubId: data.clubId })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user')
      }
    }))
}

const deleteUser = (id) => {
  return axios.delete(`${baseUrl}/api/users/${id}/sensitive-data/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete user')
      }
    }))
}

const updateUserCredits = (data) => {
  return axios.put(`${baseUrl}/api/credits/${userApiVersion}/`, data)
    .then(response => (
      { response: response.data }
    ))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user credits')
      }
    }))
}

const getClubs = () => {
  return axios.get(`${baseUrl}/api/club/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get clubs')
      }
    }))
}

const getMemberships = () => {
  return axios.get(`${baseUrl}/api/subscription/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get memberships')
      }
    }))
}

const updateMembership = (id, data) => {
  return axios.put(`${baseUrl}/api/membership/${id || 0}/user/${get(data, 'userId')}/${userApiVersion}/`, get(data, 'data'))
    .then(response => {
      if (get(data, 'callback')) data.callback();
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get membership')
      }
    }))
}

const login = ({ username, password }) => {
  const payload = querystring.stringify({
    client_id: clientId,
    username: username,
    password: password,
    grant_type: 'password'
  });
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }

  return axios.post(`${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/token`, payload, config)
    .then(response => {
      const tokenInfo = jwtDecode(get(response, 'data.access_token'));
      const roles = get(tokenInfo, 'realm_access.roles', {});
      if (
        !Object.values(roles).find((el) => (el === 'licensee')) &&
        !Object.values(roles).find((el) => (el === 'admin'))
      ) {
        return ({ error: { message: 'Access for user role not allowed' } });
      }
      return {
        response: {
          ...get(response, 'data', {}),
          decoded: tokenInfo
        }
      }
    })
    .catch(err => {
      return ({
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, "response.data", {}), 'Invalid username or password')
        }
      })
    });
}

const createLicensee = (data) => {
  return axios.post(`${baseUrl}/api/licensee/v1.1/`, data)
    .then(response => {
      return ({ response: response.data });
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create licensee')
      }
    }))
}

const updateLicensee = (id, data) => {
  return axios.put(`${baseUrl}/api/licensee/${id}/${userApiVersion}/`, data)
    .then(response => {
      if (get(data, 'address')) {
        return updateLicenseeAddress({
          ...get(data, 'address', {}),
          licenseeId: id
        });
      } else {
        return { response: response.data };
      }
    })
    .catch(err => {
      return ({
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, "response.data", {}), 'Failed to update licensee')
        }
      })
    })
}

const deleteLicensee = (id) => {
  return axios.delete(`${baseUrl}/api/licensee/${id}/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete licensee')
      }
    }))
}

const getLicensees = (data = {}) => {
  let requestUrl = `${baseUrl}/api/licensees/v2.0/?size=${data.pageSize || 100}&page=${data.page || 0}`;
  if (data.filterParam) requestUrl += `&licenseContains=${data.filterParam}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => {
      const res = {
        ...response.data,
        content: get(response.data, 'content', []),
        totalPages: get(response, 'data.totalPages'),
        totalElements: get(response, 'data.totalElements'),
        currentPage: get(data, 'page', 0)
      };
      return ({
        response: res
      })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get licensees')
      }
    }))
}

const getLicensee = (id) => {
  return axios.get(`${baseUrl}/api/licensee/${id}/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get licensee')
      }
    }))
}

const updateUserAddress = (data) => {
  return axios.put(`${baseUrl}/api/address/user/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user address')
      }
    }))
}

const updateLicenseeAddress = (data) => {
  return axios.put(`${baseUrl}/api/address/licensee/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update licensee address')
      }
    }))
}

const getTrainings = () => {
  const { UserRoles, Auth } = store.getState();
  const isLicensee = UserRoles.userRole === 'licensee';
  const principal = get(Auth, 'user.preferred_username');
  let query = ``;
  if (isLicensee) {
    query = `?status=PUBLISHED_FOR_ALL,PUBLISHED_FOR_STUDIO&active=true`;
    if (principal && principal.toLowerCase() === 'test') {
      query = `?status=PUBLISHED_FOR_DEMO&active=true`;
    }
  }

  return axios.get(`${trainingServiceUrl}/api/training/${trainingApiVersion}/${query}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get trainings')
      }
    }))
}

const getTraining = (id) => {
  return axios.get(`${trainingServiceUrl}/api/training/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get training')
      }
    }))
}

const createTraining = (data) => {
  return axios.post(`${trainingServiceUrl}/api/training/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create training')
      }
    }))
}

const updateTraining = (id, data) => {
  return axios.put(`${trainingServiceUrl}/api/training/${id}/${trainingApiVersion}/`, omit(data, [ 'sequencesToDelete' ]))
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update training')
      }
    }))
}

const createTrainingSubtitles = (data) => {
  return axios.post(`${trainingServiceUrl}/api/subtitle/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create subtitles')
      }
    }))
}

const updateTrainingSubtitles = (id, data) => {
  return axios.put(`${trainingServiceUrl}/api/subtitle/${id}/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update subtitles')
      }
    }))
}

const deleteTrainingSubtitles = (id) => {
  return axios.delete(`${trainingServiceUrl}/api/subtitle/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete subtitles')
      }
    }))
}

const deleteTraining = (id) => {
  return axios.delete(`${trainingServiceUrl}/api/training/move-to-recycle-bin/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete training')
      }
    }))
}

const getTrainingCategories = () => {
  return axios.get(`${trainingServiceUrl}/api/category/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get training categories')
      }
    }))
}

const refreshToken = (token) => {
  const payload = querystring.stringify({
    client_id: clientId,
    refresh_token: token,
    grant_type: 'refresh_token'
  });
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }

  return axios.post(`${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/token`, payload, config)
    .then(response => {
      return {
        response: {
          ...get(response, 'data', {}),
          decoded: jwtDecode(get(response, 'data.access_token')),
          isRefresh: true
        }
      }
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Session expired')
      }
    }))
}

const getMediaList = () => {
  return axios.get(`${trainingServiceUrl}/api/mediathek/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get media')
      }
    }))
}

const getMedia = (id) => {
  return axios.get(`${trainingServiceUrl}/api/mediathek/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get media')
      }
    }))
}

const createMedia = (data) => {
  return axios.post(`${trainingServiceUrl}/api/mediathek/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => {
      debugger
      return ({
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, "response.data", {}), 'Failed to create media')
        }
      })
    })
}

const updateMedia = (id, data) => {
  return axios.put(`${trainingServiceUrl}/api/mediathek/${id}/${trainingApiVersion}/`, omit(data, [ 'subtitlesToDelete' ]))
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update media')
      }
    }))
}

const deleteMedia = (id) => {
  return axios.delete(`${trainingServiceUrl}/api/mediathek/move-to-recycle-bin/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => {
      return ({
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete media')
        }
      })
    })
}

const uploadMedia = (data, action) => {
  const payload = new FormData();
  payload.append('file', data, `${Math.random().toString().substr(2)}.mp4`);
  return axios.post(`${trainingServiceUrl}/api/mediathek/upload/sample/${trainingApiVersion}/`, payload)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload file')
      }
    }))
}

const uploadStudioScreenVideo = (data) => {
  const payload = new FormData();
  const nameParts = data.name && data.name.split('.');
  const extension = get(nameParts, '1');
  payload.append('video', data, `${Math.random().toString().substr(2)}.${extension}`);

  return axios.post(`${trainingServiceUrl}/api/studio-screen/upload/video/${trainingApiVersion}/`, payload)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload file')
      }
    }))
}

const uploadStudioScreenImage = (data) => {
  const payload = new FormData();
  const nameParts = data.name && data.name.split('.');
  const extension = get(nameParts, '1');
  payload.append('image', data, `${Math.random().toString().substr(2)}.${extension}`);

  return axios.post(`${trainingServiceUrl}/api/studio-screen/upload/image/${trainingApiVersion}/?type=ADVERTISING`, payload)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload file')
      }
    }))
}

const uploadStudioHeaderImage = (data) => {
  const payload = new FormData();
  const nameParts = data.name && data.name.split('.');
  const extension = get(nameParts, '1');
  payload.append('image', data, `${Math.random().toString().substr(2)}.${extension}`);

  return axios.post(`${trainingServiceUrl}/api/studio-screen/upload/image/${trainingApiVersion}/?type=IMAGE_PLACEHOLDER`, payload)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload file')
      }
    }))
}

const createDefaultStudioInfo = (data) => {
  return axios.post(`${trainingServiceUrl}/api/studio-screen/default/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create studio news')
      }
    }))
}

const updateDefaultStudioInfo = (id, data) => {
  if (data.id) delete data.id;
  return axios.put(`${trainingServiceUrl}/api/studio-screen/default/${id}/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update studio news')
      }
    }))
}

const createLicenseeStudioInfo = (data) => {
  return axios.post(`${trainingServiceUrl}/api/studio-screen/licensee/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create studio news')
      }
    }))
}

const updateLicenseeStudioInfo = (id, data) => {
  if (data.id) delete data.id;
  return axios.put(`${trainingServiceUrl}/api/studio-screen/licensee/${id}/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update studio news')
      }
    }))
}

const getDefaultStudioInfo = () => {
  return axios.get(`${trainingServiceUrl}/api/studio-screen/default/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get studio news')
      }
    }))
}

const getLicenseeStudioInfo = () => {
  return axios.get(`${trainingServiceUrl}/api/studio-screen/licensee/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get studio news')
      }
    }))
}

const convertTrainingVideo = (data) => {
  return axios.post(`${trainingServiceUrl}/api/training/video/${trainingApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to convert video')
      }
    }))
}

const pushVideoCommand = (data) => {
  return axios.post(`${trainingServiceUrl}/api/video-control/command/${data}/${trainingApiVersion}/`, null)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to push video command')
      }
    }))
}

const pushScoreCard = (data) => {
  return axios.post(`${trainingServiceUrl}/api/video-control/score-card/${data}/${trainingApiVersion}/`, null)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to push score card')
      }
    }))
}

const pushTrainingVideo = (data) => {
  return axios.post(`${trainingServiceUrl}/api/video-control/${data}/${trainingApiVersion}/`, null)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to push training video')
      }
    }))
}

const getComments = (id) => {
  return axios.get(`${baseUrl}/api/user-detail/${id}/${userApiVersion}/`)
    .then(response => ({ response: get(response, 'data.comments', []) }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get comments')
      }
    }))
}

const createComment = (id, data) => {
  return axios.post(`${baseUrl}/api/user-detail/comments/${id}/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to create comment')
      }
    }))
}

const updateComment = (id, data) => {
  return axios.put(`${baseUrl}/api/user-detail/comments/${id}/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update comment')
      }
    }))
}

const deleteComment = (id) => {
  return axios.delete(`${baseUrl}/api/user-detail/comments/${id}/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete comment')
      }
    }))
}

const updateUserPassword = (data) => {
  return axios.put(`${baseUrl}/api/users/update-password/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user password')
      }
    }))
}

const updateUserPasswordWithCheck = (data) => {
  return axios.put(`${baseUrl}/api/customers/update-password-with-check/${userApiVersion}/`, data, {
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to reset password')
      }
    }))
}

const updateLicenseePassword = (data) => {
  return axios.put(`${baseUrl}/api/licensee/update-password/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to update user password')
      }
    }))
}

const uploadTeaserVideo = (data) => {
  const payload = new FormData();
  payload.append('file', data, `${Math.random().toString().substr(2)}.mp4`);

  return axios.post(`${trainingServiceUrl}/api/mediathek/upload/teaser/${trainingApiVersion}/`, payload)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload teaser video')
      }
    }))
}


const getDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/article/all/current/${dmsApiVersion}/?ownership=owned`)
    .then(response => {
      const documentsTree = flatDataToTreeNew(response.data);

      return {
        response: {
          list: response.data,
          tree: documentsTree,
        }
      };
    })
    .catch((err, res) => {
      console.log("documents error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get owned documents"
        }
      };
    });
};

const getSearchedDocuments = (data) => {
  return axios
    .get(`${dmsUrl}/api/content/${dmsApiVersion}/?page=${data.page || 0}&size=${data.pageSize || 100}&tag=${data.tag}`)
    .then(response => {
      return {
        response: response.data
      };
    })
    .catch((err, res) => {
      console.log("search error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get searched documents"
        }
      };
    });
};

const getTrashDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/recycle-bin-article/${dmsApiVersion}/`)
    .then(response => {
      const documentsTree = flatDataToTreeNewTrash(response.data, true);

      return {
        response: {
          list: response.data,
          tree: documentsTree,
        }
      };
    })
    .catch((err, res) => {
      console.log("documents error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get content of trash"
        }
      };
    });
};

const getTrashMedia = () => {
  return axios
    .get(`${trainingServiceUrl}/api/recycle-bin-mediathek/${dmsApiVersion}/`)
    .then(response => ({
      response: response.data
    }))
    .catch((err, res) => {
      console.log("trash error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get content of trash"
        }
      };
    });
};

const getTrashTrainings = () => {
  return axios
    .get(`${trainingServiceUrl}/api/recycle-bin-training/${dmsApiVersion}/`)
    .then(response => ({
      response: response.data
    }))
    .catch((err) => {
      console.log("trainings error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get content of trash"
        }
      };
    });
};

const restoreDocument = (id) => {
  return axios
    .post(`${dmsUrl}/api/recycle-bin-article/restore/${id}/${dmsApiVersion}/`)
    .then(response => {
      return {
        response: response.data
      };
    })
    .catch((err, res) => {
      console.log("documents error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to restore document"
        }
      };
    });
};

const restoreTraining = (id) => {
  return axios
    .post(`${trainingServiceUrl}/api/recycle-bin-training/restore/${id}/${dmsApiVersion}/`)
    .then(response => {
      return {
        response: response.data
      };
    })
    .catch((err, res) => {
      console.log("training error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to restore training"
        }
      };
    });
};

const restoreMedia = (id) => {
  return axios
    .post(`${trainingServiceUrl}/api/recycle-bin-mediathek/restore/${id}/${dmsApiVersion}/`)
    .then(response => {
      return {
        response: response.data
      };
    })
    .catch((err, res) => {
      console.log("media error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to restore media"
        }
      };
    });
};

const getUserDocuments = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/all/current/${dmsApiVersion}/?customerUUID=${id}`)
    .then(response => {
      return {
        response: response.data
      };
    })
    .catch((err, res) => {
      console.log("documents error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get user documents"
        }
      };
    });
}

const getSharedDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/article/all/current/${dmsApiVersion}/?ownership=shared`)
    .then(response => {
      const documentsTree = flatDataToTreeNew(response.data, true);
      return {
        response: {
          list: response.data,
          tree: documentsTree,
        }
      };
    })
    .catch((err, res) => {
      console.log("documents error", err);
      return {
        error: {
          ...get(err, "response.data", {}),
          data: get(err, 'message'),
          message: "Failed to get shared documents"
        }
      };
    });
};

const getDocument = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get document"
      }
    }));
};

const getDocumentByVersion = (data) => {
  return axios
    .get(`${dmsUrl}/api/article/version/${data.id}/${data.versionId}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get document versions"
      }
    }));
};


const getFilteredDocuments = (data) => {
  let requestUrl = `${dmsUrl}/api/article/filter/${dmsApiVersion}/?pageSize=${data.pageSize || 100}&page=${data.page || 0}`;
  if (data.name) requestUrl += `&name=${get(data, 'name', '')}`;
  return axios
    .get(requestUrl)
    .then(response => {
      return ({ response: response.data || [] })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get filtered documents"
      }
    }));
};

const createDocument = (data) => {
  return axios
    .post(`${dmsUrl}/api/article/${dmsApiVersion}/`, omit(data, 'callback'))
    .then(response => {
      if (data.callback) data.callback();
      if (data.type === "file") {
        window.lastDoc = response.data.id;
      }
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to create document"
      }
    }));
};

const updateDocument = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/article/${id}/v1.1/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to update document"
      }
    }));
};

const deleteDocument = (id) => {
  return axios
    .delete(`${dmsUrl}/api/article/move-to-recycle-bin/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete document"
      }
    }));
};

const deleteDocumentFromTrash = (id) => {
  return axios
    .delete(`${dmsUrl}/api/recycle-bin-article/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete document"
      }
    }));
};

const deleteMediaFromTrash = (id) => {
  return axios
    .delete(`${trainingServiceUrl}/api/recycle-bin-mediathek/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete media"
      }
    }));
};

const deleteTrainingFromTrash = (id) => {
  return axios
    .delete(`${trainingServiceUrl}/api/recycle-bin-training/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete training"
      }
    }));
};

// function fileReader(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = e => {
//       resolve(reader.result);
//     };
//
//     reader.readAsArrayBuffer(file);
//   });
// }

const uploadDocumentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);

  return axios.post(`${dmsUrl}/api/article/upload/v2.0/`, payload, {
    headers: {
      'X-File-Length': data.size
    }
  })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload user image')
      }
    }))
};


const batchUpdateDocumentPath = (data) => {
  return axios
    .put(`${dmsUrl}/api/article/${dmsApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Request failed"
      }
    }));
};

const createDocComment = (data) => {
  return axios
    .put(`${dmsUrl}/api/article/comment/${data.articleId}/${dmsApiVersion}/`, data.payload)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to create comment"
      }
    }));
};

const updateDocComment = (data) => {
  return axios
    .put(
      `${dmsUrl}/api/article/comment/${data.articleId}/${data.commentId}/${dmsApiVersion}/`,
      data.payload
    )
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to update comment"
      }
    }));
};

const deleteDocComment = (data) => {
  return axios
    .delete(`${dmsUrl}/api/article/comment/${data.articleId}/${data.commentId}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete comment"
      }
    }));
};

const moveDocument = (id, data) => {
  return axios({
    url: `${dmsUrl}/api/article/move/${id}/${dmsApiVersion}/`,
    method: 'post',
    data: data,
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to move document"
      }
    }));
}

const copyDocument = (id) => {
  return axios({
    url: `${dmsUrl}/api/article/copy/${id}/${dmsApiVersion}/`,
    method: 'post'
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to move document"
      }
    }));
}

const createDocumentPermissions = (id, data) => {
  return axios.post(`${dmsUrl}/api/article/permissions/${id}/${dmsApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to create document pemissions"
      }
    }));
}

const deleteDocumentPermissions = (id, data) => {
  return axios({
    url: `${dmsUrl}/api/article/permissions/${id}/${dmsApiVersion}/`,
    method: "delete",
    headers: {
      "Content-Type": 'application/json'
    },
    data: data
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete document pemissions"
      }
    }));
}

const getDocumentPermissions = (id) => {
  return axios.get(`${dmsUrl}/api/article/permissions/${id}/${dmsApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get document permissions"
      }
    }));
}

const getTrainingEvents = () => {
  return axios.get(`${trainingServiceUrl}/api/training-events/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get training events")
      }
    }));
}

const getTrainingEvent = (id) => {
  return axios.get(`${trainingServiceUrl}/api/training-events/${id}/${trainingApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get training event")
      }
    }));
}

const getTrainingEventsByRange = (data) => {
  return axios.get(`${trainingServiceUrl}/api/training-events/filter/${trainingApiVersion}/?startDate=${data.start}&finishDate=${data.end}`, {
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => {
      return ({
        error: {
          ...get(err, "response.data", {}),
          message: getErrorMessage(get(err, "response.data", {}), "Failed to get training events")
        }
      })
    });
}

const createTrainingEvent = (data) => {
  return axios.post(`${trainingServiceUrl}/api/training-events/${trainingApiVersion}/`, data, {
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(async (response) => {
      const { error } = await subscribeUsersToTraining(response.data[0].id, data.trainingGroup);
      if (error) {
        error.message.split(';').forEach((err) => err && toast(err, {
          transition: Slide,
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
          type: 'warning'
        }));
      }
      return { response: response.data }
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to create training event")
      }
    }));
}

const updateTrainingEvent = (id, data) => {
  return axios.put(`${trainingServiceUrl}/api/training-events/${id}/${trainingApiVersion}/`, data, {
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to update training event")
      }
    }));
}

const deleteTrainingEvent = (id) => {
  return axios({
    url: `${trainingServiceUrl}/api/training-events/${id}/${trainingApiVersion}/`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to delete training event")
      }
    }));
}

const getTrainingEventsByPrincipal = (data) => {
  return axios.get(`${trainingServiceUrl}/api/training-events/filter/${trainingApiVersion}/?userPrincipal=${data.principal}&finishDate=${data.to}&startDate=${data.from}`, {
    headers: {
      'X-Flow-ID': getFlowId()
    },
    withCredentials: true
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get training events")
      }
    }));
}

const uploadUserImage = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);
  return axios.post(`${baseUrl}/api/user-picture/upload/${userApiVersion}/?userId=${data.userId}`, payload, {
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload user image')
      }
    }))
}

const getUserImage = (data) => {
  return axios({
    url: `${baseUrl}/api/user-picture/download/${userApiVersion}/?userId=${data.userId}`,
    method: 'get',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => {
      if (response.status === 204) throw new Error();
      return ({ response: response.data });
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get user image")
      }
    }));
}

const deleteUserImage = (data) => {
  return axios({
    url: `${baseUrl}/api/user-picture/${userApiVersion}/?userId=${data.userId}`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to delete user image")
      }
    }));
}

const saveErrorLog = (data) => {
  return axios({
    url: `${logServiceUrl}/api/logger/front/message/${logApiVersion}/`,
    method: 'post',
    data: {
      ...data,
      flowId: getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to send logs"
      }
    }))
}

const updateTrainingStatus = (id, data) => {
  return axios({
    url: `${trainingServiceUrl}/api/training/${id}/${trainingApiVersion}/`,
    method: 'patch',
    headers: {
      'X-Flow-Id': getFlowId()
    },
    data: data
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to update training status")
      }
    }))
}

const copyTraining = (id) => {
  return axios({
    url: `${trainingServiceUrl}/api/training/${id}/copy/${trainingApiVersion}/`,
    method: 'post',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to copy training")
      }
    }));
}

const copyMedia = (id) => {
  return axios({
    url: `${trainingServiceUrl}/api/mediathek/${id}/copy/${trainingApiVersion}/`,
    method: 'post',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to copy media")
      }
    }));
}

const uploadTrainingPreviewImage = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios.post(`${trainingServiceUrl}/api/training/picture/upload/${trainingApiVersion}/`, payload, {
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload file')
      }
    }))
}

const deleteTrainingPreviewImage = (id) => {
  return axios({
    url: `${trainingServiceUrl}/api/training/picture/${id}/${trainingApiVersion}/`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to delete training preview image")
      }
    }));
}

const subscribeUsersToTraining = (trainingId, trainingGroup) => {
  const payload = trainingGroup.map(user => ({
    actions: 'SUBSCRIBE',
    userPrincipal: user.principal
  }));
  return axios.put(`${trainingServiceUrl}/api/training-events/${trainingId}/subscription/${trainingApiVersion}/`, payload, {
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to unsubscribe")
      }
    }));
};

const unsubscribeUsersFromTraining = (trainingId, users) => {
  if (!users) return;

  const payload = users.map(user => ({
    actions: 'UNSUBSCRIBE',
    userPrincipal: user.principal
  }));

  return axios.put(`${trainingServiceUrl}/api/training-events/${trainingId}/subscription/${trainingApiVersion}/`, payload, {
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to unsubscribe")
      }
    }));
};

const getLogs = (data) => {
  return axios.get(`${dmsUrl}/api/document-logs/filter/${trainingApiVersion}/?page=${data.page || 0}&pageSize=${data.pageSize || 100}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to load documents logs")
      }
    }));
};

const exportTrainingEmsSequence = (id) => {
  return axios.get(`${trainingServiceUrl}/api/training/${id}/ems-sequence/csv/export/${dmsApiVersion}/`)
    .then(response => {
      return response.data
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to export ems sequence")
      }
    }));
};

const exportSubtitles = (id) => {
  return axios.get(`${trainingServiceUrl}/api/training/${id}/subtitles/csv/export/${dmsApiVersion}/`)
    .then(response => response.data)
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to export subtitles")
      }
    }));
};

const exportVideoSequence = (id) => {
  return axios.get(`${trainingServiceUrl}/api/training/${id}/video-sequence/csv/export/${dmsApiVersion}/`)
    .then(response => response.data)
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to export video sequence")
      }
    }));
}

const importTrainingEmsSequence = (id, data) => {
  const payload = new FormData();
  payload.append('file', data);

  return axios
    .put(`${trainingServiceUrl}/api/training/${id}/ems-sequence/csv/import/${dmsApiVersion}/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to import ems sequence")
      }
    }));
};

const importSubtitles = (id, data) => {
  const payload = new FormData();
  payload.append('file', data);

  return axios
    .put(`${trainingServiceUrl}/api/training/${id}/subtitles/csv/import/${dmsApiVersion}/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then(response => {
      return { response: response.data };
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to import subtitles")
      }
    }));
};

const registerUser = (data) => {
  return axios({
    url: `${baseUrl}/api/users/registration/${userApiVersion}/`,
    method: 'post',
    data: data,
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to register user"
      }
    }))
}

const activateUserAccount = (id) => {
  return axios({
    url: `${baseUrl}/api/users/activation/${userApiVersion}/?id=${id}`,
    method: 'get',
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to activate user account"
      }
    }))
}

const requestPasswordReset = (data, origin) => {
  return axios({
    url: `${messageServiceUrl}/api/send-mail/forgot/${messageApiVersion}/?email=${data}&origin=${origin}`,
    method: 'post',
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to reset password"
      }
    }))
}

const getFileDownloadLink = (data) => {
  return axios({
    url: `${trainingServiceUrl}/api/file/download-link/${trainingApiVersion}/?fileDir=${data.dir}&fileKey=${data.key}`,
    method: 'get',
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get file download link"
      }
    }))
}

const uploadTrainingVideo = (data) => {
  const payload = new FormData();
  payload.append('file', data.file, `${Math.random().toString().substr(2)}.mp4`);
  return axios({
    url: `${trainingServiceUrl}/api/training/${data.trainingId}/video/upload/${trainingApiVersion}/`,
    method: 'put',
    data: payload
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to upload video"
      }
    }))
}

const getBrokenUsers = () => {
  return axios.get(`${baseUrl}/api/health-check/broken-keycloak-users/${userApiVersion}/`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to get broken keycloak users')
      }
    }))
}

const deleteBrokenUser = (id) => {
  return axios({
    method: 'delete',
    url: `${baseUrl}/api/health-check/broken-keycloak-users/${id}/${userApiVersion}/`
  })
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to delete user"
      }
    }))
}

const getEMSLogs = (data = {}) => {
  let requestUrl = `${logServiceUrl}/api/logger/ems/v1.1/?pageSize=${data.size || 100}&page=${data.page || 0}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${get(data, 'sortDirection', '')}&sort.${data.sortBy}.order=0`;
  if (data.logContains && data.logContains.length) requestUrl += `&logContains=${data.logContains}`;
  return axios.get(requestUrl)
    .then(response => ({
      response: {
        content: get(response.data, 'content', []),
        totalPages: get(response, 'data.totalPages'),
        totalElements: get(response, 'data.totalElements'),
        currentPage: get(data, 'page', 0)
      }
    }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get logs"
      }
    }));
}

const deleteEmsLogs = (data) => {
  return axios({
    url: `${logServiceUrl}/api/logger/ems/${logApiVersion}/`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    },
    data: data
  })
    .then(response => ({ response: response.data || {} }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get logs"
      }
    }));
}

const getEMSLogDownloadLink = (data) => {
  return axios.get(`${logServiceUrl}/api/logger/ems/download-link/${logApiVersion}/?fileKey=${data}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get log download link"
      }
    }));
}

const getAvatarLink = (data) => {
  return axios.get(`${baseUrl}/api/user-picture/download-link/${userApiVersion}/?userUUID=${data}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to get avatar download link"
      }
    }));
}

const getLicenseModels = () => {
  return axios.get(`${baseUrl}/api/license-template/${userApiVersion}/`)
    .then(response => ({ response: get(response.data, 'content', []) }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get license models")
      }
    }));
}

const getLicenseModel = (id) => {
  return axios.get(`${baseUrl}/api/license-template/${id}/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get license model")
      }
    }));
}

const createLicenseModel = (data) => {
  return axios.post(`${baseUrl}/api/license-template/${userApiVersion}/`, data)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to create model")
      }
    }));
}

const updateLicenseModel = (data) => {
  return axios.put(`${baseUrl}/api/license-template/${data.id}/${userApiVersion}/`, data.payload)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to update model")
      }
    }));
}

const deleteLicenseModel = (id) => {
  return axios.delete(`${baseUrl}/api/license-template/${id}/${userApiVersion}/`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete model")
      }
    }));
}

const assignLicense = (data) => {
  return axios.post(`${baseUrl}/api/licenses/${userApiVersion}/`, omit(data, 'callback'))
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        ignoreError: get(err, "response.data.status") === 409,
        callback: data.callback,
        message: getErrorMessage(get(err, "response.data", {}), "Failed to assign license to user")
      }
    }));
}

const updateUserLicense = (data) => {
  return axios.put(`${baseUrl}/api/licenses/${data.licenseId}/${userApiVersion}/`, data.payload)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        callback: data.callback,
        message: getErrorMessage(get(err, "response.data", {}), "Failed to update license")
      }
    }));
}

const getLicenseUsers = (data) => {
  return axios.get(`${baseUrl}/api/license-template/${data.licenseTemplateId}/users/${userApiVersion}/?page=${data.page || 0}&size=${data.size || 300}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to assign license to user")
      }
    }));
}

const getGroups = (data = {}) => {
  let requestUrl = `${baseUrl}/api/groups/${dmsApiVersion}/?page=${data.page || 0}&size=${data.pageSize || 100}`;
  if (data.filterParam) requestUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then(response => {
      return ({
        response: {
          ...response.data,
          totalPages: get(response, 'data.totalPages'),
          totalElements: get(response, 'data.totalElements'),
          currentPage: get(data, 'page', 0)
        }
      })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get groups")
      }
    }));
};

const getGroup = (groupId) => {
  return axios
    .get(`${baseUrl}/api/groups/${groupId}/${dmsApiVersion}/`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to load group")
      }
    }));
};

const createGroup = (data) => {
  return axios
    .post(`${baseUrl}/api/groups/${dmsApiVersion}/`, data)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to create group")
      }
    }));
};

const deleteGroup = (groupId) => {
  return axios
    .delete(`${baseUrl}/api/groups/${groupId}/${dmsApiVersion}/`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to delete group")
      }
    }));
};

const getGroupUsers = (data) => {
  let requestUrl = `${baseUrl}/api/customer-groups/filter/v1.0/?groupId=${data.groupId}&page=${data.page || 0}&size=${data.pageSize || 100}`;
  if (data.userRole) requestUrl += `&displayScope=${data.userRole}`;
  if (data.filterParam) requestUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to add user")
      }
    }))
}

const getAllCustomers = (data) => {
  let requestUrl = `${baseUrl}/api/customers/filter/v1.0/?page=${data.page || 0}&pageSize=${data.pageSize || 100}`;
  if (data.tag) requestUrl += `&tag=${data.tag}`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to add user")
      }
    }))
}

const addUsersToGroup = (data) => {
  return axios
    .put(`${baseUrl}/api/customer-groups/subscribe/${data.groupId}/${dmsApiVersion}/`, data.users)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to add user")
      }
    }));
};

const deleteUsersFromGroup = (data) => {
  return axios
    .put(`${baseUrl}/api/customer-groups/unsubscribe/${data.groupId}/${dmsApiVersion}/`, data.users)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete user")
      }
    }));
};

const getGroupTrainings = (data) => {
  return axios
    .get(`${trainingServiceUrl}/api/groups/trainings/${trainingApiVersion}/?groupUUID=${data.groupUuid}&pageSize=${data.pageSize || 300}&page=${data.page || 0}`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete user")
      }
    }));
}

const getTrainingGroups = (data) => {
  return axios
    .get(`${trainingServiceUrl}/api/groups/trainings/${trainingApiVersion}/?trainingId=${data.trainingId}&pageSize=${data.pageSize || 300}&page=${data.page || 0}`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete user")
      }
    }));
}

const addTrainingsToGroup = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/groups/${trainingApiVersion}/`, data)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete user")
      }
    }));
}

const deleteTrainingsFromGroup = (data) => {
  return axios({
    url: `${trainingServiceUrl}/api/groups/${trainingApiVersion}/`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    },
    data: data
  })
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get delete user")
      }
    }));
}

const getServiceVersions = () => {
  return axios({
    url: actuatorUrl,
    method: 'get'
  })
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get service versions")
      }
    }));
}

const getIncidentReports = (data = {}) => {
  let requestUrl = `${trainingServiceUrl}/api/incident-report/filter/${trainingApiVersion}/?size=${data.size || 100}&page=${data.page || 0}`
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${get(data, 'sortDirection', '')}`//`&direction=${get(data, 'sortDirection', '')}&sortBy=${data.sortBy}`;
  if (data.reportType) requestUrl += `&reportType=${data.reportType}`;
  if (data.application) requestUrl += `&application=${data.application}`;
  if (data.incidentContains && data.incidentContains.length) requestUrl += `&incidentContains=${data.incidentContains}`;
  return axios.get(requestUrl)
  .then(response => {
    return ({ response: response.data })
  })
  .catch(err => ({
    error: {
      ...get(err, "response.data", {}),
      message: getErrorMessage(get(err, "response.data", {}), "Failed to get incident reports")
    }
  }));
}

const deleteIncidentReports = (data = "") => {
  return axios.delete(`${trainingServiceUrl}/api/incident-report/${trainingApiVersion}/?incidentsIds=${data}`)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to delete reports")
      }
    }));
}

export const getHomeUserReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/home-user?size=${data.pageSize || 100}&page=${data.page}&from=${data.from}&to=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getTrainingCategoryReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/training-category?size=${data.pageSize || 100}&page=${data.page}&from=${data.from}&to=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getTrainingReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/trainings?size=${data.pageSize || 100}&page=${data.page}&trainingDateFrom=${data.from}&trainingDateTo=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getUserMembershipReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/user-membership?size=${data.pageSize || 100}&page=${data.page}&from=${data.from}&to=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  if (data.search) requestUrl += `&membershipName=${data.search}`;
  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getUserRegistrationReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/user-registration?size=${data.pageSize || 100}&page=${data.page}&from=${data.from}&to=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  if (data.search) requestUrl += `&studioName=${data.search}`;

  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getUserSubscriptionReports = (data) => {
  let requestUrl = `${statisticServiceUrl}/api/statistics/user-subscription?size=${data.pageSize || 100}&page=${data.page}&userCreatedFrom=${data.from}&userCreatedTo=${data.to}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  if (data.search) requestUrl += `&studioName=${data.search}`;
  // else requestUrl += `&sort.id.direction=ASC`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get reports")
      }
    }));
}

export const getStudiosAutocomplete = (data) => {
  let requestUrl = `${baseUrl}/api/club/filter/v1.0/?size=${data.size || 100}&page=${data.page}`;
  if (data.search) requestUrl += `&clubNameContains=${data.search}`;

  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get studios")
      }
    }));
}

export const getMembershipsAutocomplete = () => {
  let requestUrl = `${baseUrl}/api/subscription/v1.0/`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get memberships")
      }
    }));
}

export const createErrorReport = (data) => {
   return axios.post(`${statisticServiceUrl}/api/analytic/jira/v1.0/`, data)
     .then(response => ({ response: response.data }))
     .catch(err => ({
       error: {
         ...get(err, "response.data", {}),
         message: getErrorMessage(get(err, "response.data", {}), "Failed to create error report")
       }
     }));
}

export const uploadErrorReportAttachment = (data) => {
  const payload = new FormData();
  data.files && data.files.forEach(el => {
    payload.append('files', el);
  })
  return axios.post(`${statisticServiceUrl}/api/analytic/jira/${data.key}/attachments/v1.0/`, payload)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to upload files')
      }
    }))
}

export const getDeviceInfoStats = (data) => {
  let reqUrl = `${logServiceUrl}/api/ems/usageStatistic/v1.0/?page=${data.page}&size=${data.pageSize}`;
  if (data.filterParam) reqUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) reqUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios.get(reqUrl)
  .then(response => {
    const res = {
      ...response.data,
      content: get(response.data, 'content', []),
      totalPages: get(response, 'data.totalPages'),
      currentPage: get(data, 'page', 0)
    };
    return ({
      response: res
    })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to get device info stats')
    }
  }))
}

export const getDeviceInfoStatsDetail = (data) => {
  let reqUrl = `${logServiceUrl}/api/ems/usageStatistic/detail/v1.0/?deviceType=${data.deviceType}&page=${data.page}&size=${data.pageSize}`;
  if (data.filterParam) reqUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) reqUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios.get(reqUrl)
  .then(response => {
    const res = {
      ...response.data,
      content: get(response.data, 'content', []),
      totalPages: get(response, 'data.totalPages'),
      currentPage: get(data, 'page', 0),
      totalElements: get(response, 'data.totalElements'),
    };
    return ({
      response: res
    })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to get device info stats detail')
    }
  }))
}

export const getDeviceInfoWhiteList = (data) => {
  let reqUrl = `${logServiceUrl}/api/ems/deviceStatus/v1.0/?list=whiteList&page=${data.page}&size=${data.pageSize}`;
  if (data.filterParam) reqUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) reqUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios.get(reqUrl)
  .then(response => {
    const res = {
      ...response.data,
      content: get(response.data, 'content', []),
      totalPages: get(response, 'data.totalPages'),
      currentPage: get(data, 'page', 0),
      totalElements: get(response, 'data.totalElements'),
    };
    return ({
      response: res
    })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to get device info whitelist')
    }
  }))
}

export const getDeviceInfoBlackList = (data) => {
  let reqUrl = `${logServiceUrl}/api/ems/deviceStatus/v1.0/?list=blackList&page=${data.page}&size=${data.pageSize}`;
  if (data.filterParam) reqUrl += `&contains=${data.filterParam}`;
  if (data.sortBy) reqUrl += `&sort.${data.sortBy}.direction=${data.sortDirection || 'ASC'}&sort.${data.sortBy}.order=0`;
  return axios.get(reqUrl)
  .then(response => {
    const res = {
      ...response.data,
      content: get(response.data, 'content', []),
      totalPages: get(response, 'data.totalPages'),
      currentPage: get(data, 'page', 0),
      totalElements: get(response, 'data.totalElements'),
    };
    return ({
      response: res
    })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to get device info blacklist')
    }
  }))
}

export const deleteDeviceInfo = (data) => {
  return axios.delete(`${logServiceUrl}/api/ems/deviceStatus/${data.id}/v1.0/`)
  .then(response => {
    return ({
      response: {
        data: response.data,
        type: data.key
      }
    })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete device info')
    }
  }))
}

export const deleteDeviceStatsInfo = (data) => {
  return axios({
    url: `${logServiceUrl}/api/ems/usageStatistic/v1.0/?deviceType=${data}`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId()
    }
  })
  .then(response => {
    return ({ response: response.data })
  })
  .catch(err => ({
    error: {
      ...get(err, 'response.data', {}),
      message: getErrorMessage(get(err, "response.data", {}), 'Failed to delete device info')
    }
  }))
}

export const getBlacklistInfoDetails = (id) => {
  let requestUrl = `${logServiceUrl}/api/ems/deviceStatus/${id}/v1.0/`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get blacklist details info")
      }
    }));
}

export const updateBlacklistDetails = (data) => {
  let requestUrl = `${logServiceUrl}/api/ems/deviceStatus/${data.id}/v1.0/`;
  return axios.put(requestUrl, omit(data, 'id'))
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to update blacklist details info")
      }
    }));
}

export const migrateLicenseeTerrasoftData = (id) => {
  return axios.put(`${baseUrl}/api/terrasoft/users/synchronize?licenseeUuid=${id}&code=$2y$08$w1xnZpYmhTZNr3OQRE6guuZ2YPhveoTtbE95QH.y3IZy3nNjA69.2`)
    .then(response => {
      return ({ response: response.data })
    })
    .catch(err => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, "response.data", {}), 'Failed to migrate licensee data')
      }
    }))
}

export const getReleaseNotes = () => {
  let requestUrl = `mock`;
  return axios.get(requestUrl)
    .then(response => ({ response: response.data }))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: getErrorMessage(get(err, "response.data", {}), "Failed to get release notes")
      }
    }));
}

export default {
  exportTrainingEmsSequence,
  exportSubtitles,
  exportVideoSequence,
  importTrainingEmsSequence,
  importSubtitles,
  getUsers,
  getUser,
  getFilteredUsers,
  createUser,
  updateUser,
  deleteUser,
  updateUserCredits,
  getClubs,
  getMemberships,
  login,
  getLicensees,
  createLicensee,
  updateLicensee,
  deleteLicensee,
  getLicensee,
  refreshToken,
  updateUserAddress,
  updateLicenseeAddress,
  getTrainings,
  getTraining,
  createTraining,
  updateTraining,
  deleteTraining,
  getLicenseeClubs,
  getTrainingCategories,
  createTrainingSubtitles,
  updateTrainingSubtitles,
  deleteTrainingSubtitles,
  getMediaList,
  createMedia,
  updateMedia,
  deleteMedia,
  getMedia,
  uploadMedia,
  uploadStudioScreenImage,
  uploadStudioScreenVideo,
  createDefaultStudioInfo,
  updateDefaultStudioInfo,
  createLicenseeStudioInfo,
  updateLicenseeStudioInfo,
  getDefaultStudioInfo,
  getLicenseeStudioInfo,
  convertTrainingVideo,
  pushVideoCommand,
  pushScoreCard,
  pushTrainingVideo,
  getComments,
  createComment,
  updateComment,
  deleteComment,
  updateUserPassword,
  updateMembership,
  uploadTeaserVideo,
  getDocuments,
  getSearchedDocuments,
  getTrashDocuments,
  getTrashMedia,
  getTrashTrainings,
  getSharedDocuments,
  getUserDocuments,
  getDocument,
  getDocumentByVersion,
  getFilteredDocuments,
  createDocument,
  updateDocument,
  deleteDocument,
  uploadDocumentFile,
  batchUpdateDocumentPath,
  moveDocument,
  copyDocument,
  createDocComment,
  updateDocComment,
  deleteDocComment,
  getDocumentPermissions,
  createDocumentPermissions,
  deleteDocumentPermissions,
  getTrainingEvent,
  getTrainingEvents,
  getTrainingEventsByRange,
  createTrainingEvent,
  updateTrainingEvent,
  deleteTrainingEvent,
  getTrainingEventsByPrincipal,
  uploadUserImage,
  deleteUserImage,
  getUserImage,
  saveErrorLog,
  restoreDocument,
  restoreTraining,
  restoreMedia,
  deleteDocumentFromTrash,
  deleteTrainingFromTrash,
  deleteMediaFromTrash,
  updateLicenseePassword,
  updateTrainingStatus,
  updateUserClub,
  copyTraining,
  copyMedia,
  uploadTrainingPreviewImage,
  deleteTrainingPreviewImage,
  subscribeUsersToTraining,
  unsubscribeUsersFromTraining,
  getLogs,
  registerUser,
  requestPasswordReset,
  activateUserAccount,
  updateUserPasswordWithCheck,
  getFileDownloadLink,
  uploadTrainingVideo,
  getBrokenUsers,
  deleteBrokenUser,
  getEMSLogs,
  getEMSLogDownloadLink,
  getAvatarLink,
  getLicenseModel,
  getLicenseModels,
  createLicenseModel,
  updateLicenseModel,
  deleteLicenseModel,
  assignLicense,
  updateUserLicense,
  getLicenseUsers,
  getGroup,
  getGroups,
  addUsersToGroup,
  deleteUsersFromGroup,
  deleteGroup,
  getGroupUsers,
  getAllCustomers,
  createGroup,
  getGroupTrainings,
  addTrainingsToGroup,
  deleteTrainingsFromGroup,
  getTrainingGroups,
  uploadStudioHeaderImage,
  getServiceVersions,
  deleteEmsLogs,
  getIncidentReports,
  deleteIncidentReports,
  getHomeUserReports,
  getTrainingCategoryReports,
  getTrainingReports,
  getUserMembershipReports,
  getUserRegistrationReports,
  getUserSubscriptionReports,
  getStudiosAutocomplete,
  getMembershipsAutocomplete,
  createErrorReport,
  uploadErrorReportAttachment,
  getDeviceInfoStats,
  getDeviceInfoWhiteList,
  getDeviceInfoBlackList,
  deleteDeviceInfo,
  getDeviceInfoStatsDetail,
  deleteDeviceStatsInfo,
  migrateLicenseeTerrasoftData,
  getBlacklistInfoDetails,
  updateBlacklistDetails,
  getReleaseNotes,
}
