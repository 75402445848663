import { takeEvery, call, put } from 'redux-saga/effects';
import * as actions from '../actions/Reports';
import api from '../utils/api';
import { get } from 'lodash';

/***************************** Subroutines ************************************/

function* getReports(action) {
  let apiFunc;

  switch(get(action, 'reportType')) {
    case 'home-user':
      apiFunc = api.getHomeUserReports;
      break;
    case 'training-category':
      apiFunc = api.getTrainingCategoryReports;
      break;
    case 'training':
      apiFunc = api.getTrainingReports;
      break;
    case 'membership':
      apiFunc = api.getUserMembershipReports;
      break;
    case 'registration':
      apiFunc = api.getUserRegistrationReports;
      break;
    case 'subscription':
      apiFunc = api.getUserSubscriptionReports;
      break;
    default:
      apiFunc = api.getHomeUserReports;
  };

  try {
    const { id, data } = action;
    const {response, error} = typeof id !== 'undefined' ? yield call(apiFunc, id, data) : yield call(apiFunc, data);
    if (!error)
      yield put(actions.getReports.success(response));
    else
      yield put(actions.getReports.failure(error));

  } catch(err) {
    yield put(actions.getReports.failure(err));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetReports() {
  yield takeEvery(actions.GET_REPORTS.REQUEST, getReports)
}
