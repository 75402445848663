import * as AuthActionTypes from '../actions/Auth';
export const SET_USER_ROLE = 'SET_USER_ROLE';

const initialState = {
  userRole: ''
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case SET_USER_ROLE:
      return {...state, userRole: action.payload}
    default:
      return state;
  }
}
