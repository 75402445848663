import { takeEvery, put, select } from 'redux-saga/effects';
import * as trainingActions from '../actions/Training'
import api from '../utils/api';
import { apiRequest } from './index';
import history from 'utils/history';
import { get } from 'lodash';

import {
    toast,
    Slide
} from 'react-toastify';

/***************************** Subroutines ************************************/

// Get trainings
const fetchTrainings = apiRequest.bind(null, trainingActions.getTrainings, api.getTrainings)
const fetchTraining = apiRequest.bind(null, trainingActions.getTraining, api.getTraining)
const removeTraining = apiRequest.bind(null, trainingActions.deleteTraining, api.deleteTraining)
const fetchTrainingCategories = apiRequest.bind(null, trainingActions.getTrainingCategories, api.getTrainingCategories)
const addTraining = apiRequest.bind(null, trainingActions.createTraining, api.createTraining)
const editTraining = apiRequest.bind(null, trainingActions.updateTraining, api.updateTraining)
const convertTrainingVideo = apiRequest.bind(null, trainingActions.convertTrainingVideo, api.convertTrainingVideo)
const uploadTeaserVideo = apiRequest.bind(null, trainingActions.uploadTeaserVideo, api.uploadTeaserVideo)
const getTrainingEventsByPrincipal = apiRequest.bind(null, trainingActions.getTrainingEventsByPrincipal, api.getTrainingEventsByPrincipal)
const setTrainingStatus = apiRequest.bind(null, trainingActions.setTrainingStatus, api.updateTrainingStatus)
const copyTraining = apiRequest.bind(null, trainingActions.copyTraining, api.copyTraining)

const pushTrainingVideo = apiRequest.bind(null, trainingActions.pushTrainingVideo, api.pushTrainingVideo)
const pushScoreCard = apiRequest.bind(null, trainingActions.pushScoreCard, api.pushScoreCard)
const pushVideoCommand = apiRequest.bind(null, trainingActions.pushVideoCommand, api.pushVideoCommand)

function* handleUpdateTrainings() {
  yield history.push('/trainings');
}

function* handleCreateTrainings() {
  const state = yield select();
  const allData = get(state, 'Training.trainings', []);
  const pageSize = get(state, 'Training.trainingsPageSize', 20);
  const tableFilter = get(state, 'Training.tableFilter', '');
  const lastPage = tableFilter ?  0 : Math.ceil((allData.length + 1) / pageSize) - 1;
  yield put({ type: trainingActions.SET_TRAININGS_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push('/trainings');
}

function* handleCopyTrainingSuccess() {
  yield put({ type: trainingActions.GET_TRAININGS.REQUEST });
}

function* handleVideoCommandSuccess() {
  yield toast('Success', {
        transition: Slide,
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
        type: 'success'
    });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLoadTrainings() {
  yield takeEvery(trainingActions.GET_TRAININGS.REQUEST, fetchTrainings)
}

export function* watchCopyTrainingRequest() {
  yield takeEvery(trainingActions.COPY_TRAINING.REQUEST, copyTraining)
}

export function* watchCopyTrainingSuccess() {
  yield takeEvery(trainingActions.COPY_TRAINING.SUCCESS, handleCopyTrainingSuccess)
}

export function* watchLoadTraining() {
  yield takeEvery(trainingActions.GET_TRAINING.REQUEST, fetchTraining)
}

export function* watchDeleteTraining() {
  yield takeEvery(trainingActions.DELETE_TRAINING.REQUEST, removeTraining)
}

export function* watchDeleteTrainingSuccess() {
  yield takeEvery(trainingActions.DELETE_TRAINING.SUCCESS, fetchTrainings)
}

export function* watchLoadTrainingCategories() {
  yield takeEvery(trainingActions.GET_TRAINING_CATEGORIES.REQUEST, fetchTrainingCategories)
}

export function* watchCreateTraining() {
  yield takeEvery(trainingActions.CREATE_TRAINING.REQUEST, addTraining)
}

export function* watchUpdateTraining() {
  yield takeEvery(trainingActions.UPDATE_TRAINING.REQUEST, editTraining)
}

export function* watchCreateTrainingSuccess() {
  yield takeEvery(trainingActions.CREATE_TRAINING.SUCCESS,  handleCreateTrainings)
}

export function* watchUpdateTrainingSuccess() {
  yield takeEvery(trainingActions.UPDATE_TRAINING.SUCCESS,  handleUpdateTrainings)
}

export function* watchConvertTrainingVideo() {
  yield takeEvery(trainingActions.CONVERT_TRAINING_VIDEO.REQUEST, convertTrainingVideo)
}

export function* watchUploadTeaserVideo() {
  yield takeEvery(trainingActions.UPLOAD_TEASER_VIDEO.REQUEST, uploadTeaserVideo)
}

export function* watchPushTrainingVideo() {
  yield takeEvery(trainingActions.PUSH_TRAINING_VIDEO.REQUEST, pushTrainingVideo)
}

export function* watchPushScoreCard() {
  yield takeEvery(trainingActions.PUSH_SCORE_CARD.REQUEST, pushScoreCard)
}

export function* watchPushVideoCommand() {
  yield takeEvery(trainingActions.PUSH_VIDEO_COMMAND.REQUEST, pushVideoCommand)
}

export function* watchPushVideoCommandSuccess() {
  yield takeEvery(trainingActions.PUSH_VIDEO_COMMAND.SUCCESS, handleVideoCommandSuccess)
}

export function* watchPushTrainingVideoSuccess() {
  yield takeEvery(trainingActions.PUSH_TRAINING_VIDEO.SUCCESS, handleVideoCommandSuccess)
}

export function* watchGetTrainingEventsByPrincipal() {
  yield takeEvery(trainingActions.GET_TRAINING_EVENTS_BY_PRINCIPAL.REQUEST, getTrainingEventsByPrincipal)
}

export function* watchSetTrainingStatusRequest() {
  yield takeEvery(trainingActions.SET_TRAINING_STATUS.REQUEST, setTrainingStatus)
}

export function* watchSetTrainingStatusSuccess() {
  yield takeEvery(trainingActions.SET_TRAINING_STATUS.SUCCESS, fetchTrainings)
}
