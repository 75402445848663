import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import line from '../../assets/utils/images/selector.svg';
import Switch from "react-switch";

import {
    faBars,
    faMoon,
    faSun
} from '@fortawesome/free-solid-svg-icons';

import {
    faSignOutAlt
} from '@fortawesome/fontawesome-free-solid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MobileMenuOverlay from './Components/MobileMenuOverlay';
import { CustomerNav, LicenseeNav, AdminNav } from '../AppNav/NavItems';

import { logout } from '../../actions/Auth';
import { connect } from 'react-redux';
import { get } from 'lodash';

const getNav = (userRole) => {
  if (userRole === 'user') return CustomerNav;
  if (userRole === 'licensee') return LicenseeNav;
  if (userRole === 'admin') return AdminNav;
}

const pagesWithFixedHeader = [
  "/update-file",
  "/create-file",
  "/view-file",
  "/view-user",
  "/edit-user",
  "/create-user",
  "/view-training",
  "/edit-training",
  "/create-training",
  "/view-media",
  "/edit-media",
  "/create-media",
  "/view-licensee",
  "/edit-licensee",
  "/create-licensee"
 ];

const checkHeaderFixed = history => {
  const path = get(history, "location.pathname");
  return pagesWithFixedHeader.reduce((acc, el) => {
    if (acc) return acc;
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const Component = ({ logout, noTabs = false, user, userRole, history, darkTheme, toggleDarkTheme }) => {
  const [menuOpen, toggleMenu] = useState(false);

  const isHeaderFixed = checkHeaderFixed(history);
  return (
    <>
    <div className={`d-header
      ${isHeaderFixed ? "d-header-fixed" : ""}
      `}>
      <div className="d-header-top">
        <div className="d-header-menu-mobile" onClick={() => toggleMenu(true)}><FontAwesomeIcon icon={faBars} /></div>
        <div className="d-header-title">Welcome {get(user, 'preferred_username')}!</div>
        <div className="d-top-header-content-right">
            <div className="d-switch">
              <Switch
                onChange={toggleDarkTheme}
                checked={darkTheme}
                checkedIcon={<FontAwesomeIcon icon={faSun}/>}
                uncheckedIcon={<FontAwesomeIcon icon={faMoon}/>}
                onHandleColor={'#1ab9ec'}
                onColor={'#fff'}
              />
            </div>

            <div className="d-logout-text" onClick={() => logout()}>Logout</div>
            <div className="d-logout-img" onClick={() => logout()}><FontAwesomeIcon icon={faSignOutAlt} /></div>
        </div>
      </div>
      {
        !noTabs &&
        (<div className="d-header-bottom">
          <div className="d-tab d-tab-active">MY BODY CLUB <img alt="line" src={line} /></div>
          <div className="d-tab">OVERVIEW</div>
        </div>)
      }
    </div>
    <MobileMenuOverlay
      open={menuOpen}
      closeMenu={() => toggleMenu(false)}
      nav={getNav(userRole)}
      />
    </>
  )
}

const mapStateToProps = ({ UserRoles, Auth, ThemeOptions }) => ({
  userRole: UserRoles.userRole,
  user: Auth.user,
  darkTheme: ThemeOptions.darkTheme
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout.request()),
  toggleDarkTheme: (payload) => dispatch({ type: 'SET_THEME', payload })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
