export const baseUrl = "https://api.bodyclub24.com/bc-user-service";
export const trainingServiceUrl = "https://api.bodyclub24.com/bc-training-service";
export const logServiceUrl = "https://api.bodyclub24.com/bc-log-service";
export const dmsUrl = "https://api.bodyclub24.com/bc-dms-service";
export const messageServiceUrl = "https://api.bodyclub24.com/bc-message-service";
export const keycloakUrl = "https://keycloak.bodyclub24.com";
export const actuatorUrl = "https://api.bodyclub24.com/actuator/info";
export const statisticServiceUrl = 'https://api.bodyclub24.com/bc-statistic-service';
export const realm = "bodyclub-prod";
export const clientId = "bodyclub-client";
export const bucket = 'bc-training';
export const version = '1.1';
export const dmsApiVersion = 'v1.0';
export const userApiVersion = 'v1.0';
export const trainingApiVersion = 'v1.0';
export const logApiVersion = 'v1.0';
export const messageApiVersion = 'v1.0';
