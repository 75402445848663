import * as ActionTypes from 'actions/Groups';
import * as AuthActionTypes from 'actions/Auth';
import {get} from "lodash";

const initialState = {
  groups: [],
  group: {},
  groupUsers: [],
  availableGroupsCustomers: [],
  userGroups: [],
  groupTrainings: [],
  groupsPage: 0,
  groupsPages: 0,
  groupsTotalElements: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return {...initialState};
    case ActionTypes.GET_USER_GROUPS.SUCCESS:
      return {
        ...state,
        userGroups: action.payload,
      };
    case ActionTypes.GET_GROUPS.SUCCESS:
      return {
        ...state,
        groups: get(action.payload, 'content', []),
        groupsPage: get(action, 'payload.currentPage', 0),
        groupsPages: get(action, 'payload.totalPages', 0),
        groupsTotalElements: get(action, 'payload.totalElements', 0),
      };
    case ActionTypes.GET_GROUP.SUCCESS:
      return {
        ...state,
        group: action.payload
      };
    case ActionTypes.GET_GROUP_BY_NAME.SUCCESS:
      return {
        ...state,
        group: action.payload
      };
    case ActionTypes.GET_GROUP_USERS.SUCCESS:
      return {
          ...state,
          groupUsers: get(action.payload, 'content', []),
          usersPage: get(action, 'payload.currentPage', 0),
          usersPages: get(action, 'payload.totalPages', 0),
      };
    case ActionTypes.GET_GROUP_TRAININGS.SUCCESS:
      return {
        ...state,
        groupTrainings: get(action.payload, 'content[0].trainings', []),
        groupTrainingsPage: get(action, 'payload.currentPage', 0),
        groupTrainingsPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_TRAINING_GROUPS.SUCCESS:
      return {
        ...state,
        trainingGroups: get(action.payload, 'content[0].groups', []),
        trainingGroupsPage: get(action, 'payload.currentPage', 0),
        trainingGroupsPages: get(action, 'payload.totalPages', 1),
      };
    default:
      return state;
  }
}
