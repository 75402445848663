import {Route, Redirect, Switch, withRouter} from 'react-router-dom';
import React, {Suspense, lazy, Fragment, useEffect} from 'react';
import Loader from 'react-loaders';
import {connect} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Notification from 'components/Notification';

import { matchesUnauthPath } from 'utils/navHelper';
import { getClubs, getLicenseeClubs } from 'actions/Club';
import { getMemberships } from 'actions/Membership';
import { checkAuth } from 'actions/Auth';
import { getLicensees } from 'actions/Admin';
import { getMediaList } from 'actions/Media';
import { getDocuments } from 'actions/Documents';
import { getTrainingCategories, getTrainings } from 'actions/Training';

import AppHeader from '../AppHeader/';
import AppSidebar from '../AppSidebar/';
import AppFooter from '../AppFooter/';

import logoOverlay from 'assets/utils/images/logo-overlay@2x.png';

import {
    ToastContainer,
} from 'react-toastify';

import Loading from 'components/Loading';

const NotFoundPage = lazy(() => import('pages/NotFound'));

const CustomerDashboardPage = lazy(() => import('pages/CustomerDashboard'));
const CustomerDashboardCalendarPage = lazy(() => import('pages/CustomerDashboardCalendar'))
const CustomerDashboardWorkoutsPage = lazy(() => import('pages/CustomerDashboardWorkouts'));
const CustomerDashboardWorkoutsPlainPage = lazy(() => import('pages/CustomerDashboardWorkoutsPlain'));

const UserListPage = lazy(() => import('pages/UserList'));
const LicenseeListPage = lazy(() => import('pages/LicenseeList'));
const CreateUserPage= lazy(() => import('pages/CreateUser'));
const UserDetails = lazy(() => import('pages/UserDetails'));
const CreateLicenseePage = lazy(() => import('pages/CreateLicensee'));
const CreateTrainingPage = lazy(() => import('pages/CreateTraining'));
const TrainingListPage = lazy(() => import('pages/TrainingList'));
const LicenseeDetailsPage = lazy(() => import('pages/LicenseeDetails'));
const TrainingDetailsPage = lazy(() => import('pages/TrainingDetails'));
const LoginPage = lazy(() => import('pages/Login'));
const RegisterPage = lazy(() => import('pages/Register'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ActivateAccountPage = lazy(() => import('pages/ActivateAccount'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const StudioDetailsPage = lazy(() => import('pages/StudioDetails'));
const StudioScreenDetailsPage = lazy(() => import('pages/StudioScreenDetails'));
const MediaListPage = lazy(() => import('pages/MediaList'));
const CreateMediaPage = lazy(() => import('pages/CreateMedia'));
const MediaDetailsPage = lazy(() => import('pages/MediaDetails'));
const DocumentListPage = lazy(() => import('pages/DocumentList'));
const DocumentDetailsPage = lazy(() => import('pages/DocumentDetails'));
const CreateDocumentPage = lazy(() => import('pages/CreateDocument'));
const TrashPage = lazy(() => import('pages/Trash'));
const LogsPage = lazy(() => import('pages/Logs'));
const BrokenUsersPage = lazy(() => import('pages/BrokenUserList'));
const EMSLogsPage = lazy(() => import('pages/EMSLogsList'));
const CreateLicenseModelPage = lazy(() => import('pages/CreateLicenseModel'));
const LicenseModelDetailsPage = lazy(() => import('pages/LicenseModelDetails'));
const LicenseModelsListPage = lazy(() => import('pages/LicenseModelsList'));
const GroupDetails = lazy(() => import('pages/GroupDetails'));
const GroupsList = lazy(() => import('pages/GroupsList'));
const RadiationProtectionLogs = lazy(() => import('pages/RadiationProtectionLogsList'));
const ReportsList = lazy(() => import('pages/ReportsList'));
const DataProtection = lazy(() => import('pages/DataProtection'));
const DeviceInformationList = lazy(() => import('pages/DeviceInformationList'));
const DeviceInformationStatsDetails = lazy(() => import('pages/DeviceInformationStatsDetails'));
const DeviceInformationBlackListDetails = lazy(() => import('pages/DeviceInformationBlackListDetails'));

// user dashboard pages
const CalendarPage = lazy(() => import('pages/Calendar'));
const MyTrainingPage = lazy(() => import('pages/MyTraining'));
const MyDiaryPage = lazy(() => import('pages/MyDiary'));
const TrainingCalendarPage = lazy(() => import('pages/TrainingCalendar'));

const ScoreCardPage = lazy(() => import('pages/ScoreCard'));
const TrainingOfferPage = lazy(() => import('pages/TrainingOffer'));
const TrainingSelectionPage = lazy(() => import('pages/TrainingSelection'));

const StudioCalendarPage = lazy(() => import('pages/StudioCalendar'));
const MyStudioPage = lazy(() => import('pages/StudioTrainingList'));

const AppMain = (props) => {
    useEffect(() => {
      if (props.userRole) {
        if (window.localStorage) {
          const darkTheme = window.localStorage.getItem('BC_DARK_THEME');
          if (darkTheme === 'true') props.setDarkTheme(true);
        }

        props.loadTrainings()
        props.loadTrainingCategories()
        props.getDocuments()
        props.loadMemberships()

        if (props.userRole === 'licensee') props.loadLicenseeClubs()

        if (props.userRole === 'admin') {
          // props.getUsers()
          //props.getLicensees()
          props.loadClubs() // required for creating user ???
          props.loadMedia() // required for getting media for training create/edit
        }

      }
    }, [props.userRole]);
    useEffect(() => {
     props.checkAuth()
    }, [])
    if (
      matchesUnauthPath(props.location.pathname)
      // props.location.pathname === '/login'
      // || props.location.pathname === '/register'
      // || props.location.pathname.includes('/forgot-password')
      // || props.location.pathname.includes('/reset-password')
      // || props.location.pathname.includes('/activate-account')
    ) return (
      <Suspense fallback={
          <div className="loader-container">
              <div className="loader-container-inner">
                  <div className="text-center">
                      <Loader type="ball-grid-beat"/>
                  </div>
                  <h6 className="mt-3">
                      Loading...
                  </h6>
              </div>
          </div>
      }>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/activate-account" component={ActivateAccountPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/data-protection" component={DataProtection} />
          <ToastContainer/>
          <Notification />
      </Suspense>

    )
    // TEMP: for new design pages
    if (
      props.location.pathname === '/dashboard'
      || props.location.pathname === '/dashboard-workouts'
      || props.location.pathname === '/dashboard-activities'
      || props.location.pathname === '/dashboard-calendar') return (
      <Suspense fallback={
          <div className="loader-container">
              <div className="loader-container-inner">
                  <div className="text-center">
                      <Loader type="ball-grid-beat"/>
                  </div>
                  <h6 className="mt-3">
                      Loading...
                  </h6>
              </div>
          </div>
      }>
          <Route path="/dashboard" component={CustomerDashboardPage}/>
          <Route path="/dashboard-workouts" component={CustomerDashboardWorkoutsPage}/>
          <Route path="/dashboard-activities" component={CustomerDashboardWorkoutsPlainPage}/>
          <Route path="/dashboard-calendar" component={CustomerDashboardCalendarPage}/>
      </Suspense>
    )
    return (
      <Fragment>
          <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Fragment>

              <div className="d-container">
                <div className="d-sidebar-base"></div>
                <AppSidebar darkTheme={props.darkTheme} />
                <img alt="logo"className="d-logo" src={logoOverlay} />
                <div className="d-content d-content-padding">
                  <AppHeader noTabs />
                  <div className="app-main__outer">
                  <div className="app-main__inner ">
                  <Suspense fallback={
                      <div className="loader-container">
                          <div className="loader-container-inner">
                              <div className="text-center">
                                  <Loader type="ball-grid-beat"/>
                              </div>
                              <h6 className="mt-3">
                                  Loading...
                              </h6>
                          </div>
                      </div>
                  }>
                  {
                    props.userRole === 'admin' &&
                    (
                      <Switch>
                        <Route path="/licensee" component={LicenseeListPage}/>
                        <Route path="/trash" component={TrashPage}/>
                        <Route path="/logs" component={LogsPage}/>
                        <Route path="/create-licensee" component={CreateLicenseePage}/>
                        <Route path="/trainings" component={TrainingListPage}/>
                        <Route path="/create-training" component={CreateTrainingPage}/>
                        <Route path="/view-training/:id" component={TrainingDetailsPage}/>
                        <Route path="/edit-training/:id" component={TrainingDetailsPage}/>
                        <Route path="/view-licensee/:id" component={LicenseeDetailsPage}/>
                        <Route path="/edit-licensee/:id" component={LicenseeDetailsPage}/>
                        <Route path="/users" component={UserListPage}/>
                        <Route path="/view-user/:id" component={UserDetails}/>
                        <Route path="/create-user" component={CreateUserPage}/>
                        <Route path="/edit-user/:id" component={UserDetails}/>
                        <Route path="/studio-screen-details" component={StudioScreenDetailsPage}/>
                        <Route path="/media" component={MediaListPage} />
                        <Route path="/create-media" component={CreateMediaPage} />
                        <Route path="/view-media/:id" component={MediaDetailsPage} />
                        <Route path="/edit-media/:id" component={MediaDetailsPage} />
                        <Route path="/studio-details" component={StudioDetailsPage} />
                        <Route path="/documents" component={DocumentListPage} />
                        <Route path="/create-file" component={CreateDocumentPage} />
                        <Route path="/view-file/:id" exact component={DocumentDetailsPage} />
                        <Route path="/view-file/:id/:versionId" exact component={DocumentDetailsPage} />
                        <Route path="/update-file/:id" exact component={DocumentDetailsPage} />
                        <Route path="/broken-users" component={BrokenUsersPage} />
                        <Route path="/app-logs" component={EMSLogsPage} />
                        <Route path="/license-models" component={LicenseModelsListPage} />
                        <Route path="/create-license" component={CreateLicenseModelPage} />
                        <Route path="/edit-license/:id" component={LicenseModelDetailsPage} />
                        <Route path="/groups" exact component={GroupsList}/>
                        <Route path="/view-group/:id" exact component={GroupDetails}/>
                        <Route path="/incident-logs" exact component={RadiationProtectionLogs} />
                        <Route path="/reports" exact component={ReportsList} />
                        <Route path="/device-info/:resource" exact component={DeviceInformationList} />
                        <Route path="/device-info/stats/:id" exact component={DeviceInformationStatsDetails} />
                        <Route path="/device-info/blacklist/:id" exact component={DeviceInformationBlackListDetails} />
                       <Route path="*" component={NotFoundPage} />
                      </Switch>
                    )
                  }
                  {
                    props.userRole === 'licensee' &&
                    (
                      <Switch>
                        <Route path="/users" component={UserListPage}/>
                        <Route path="/trash" component={TrashPage}/>
                        <Route path="/logs" component={LogsPage}/>
                        <Route path="/view-user/:id" component={UserDetails}/>
                        <Route path="/create-user" component={CreateUserPage}/>
                        <Route path="/edit-user/:id" component={UserDetails}/>
                        <Route path="/studio-calendar" component={StudioCalendarPage}/>
                        <Route path="/studio" component={MyStudioPage}/>
                        <Route path="/studio-details" component={StudioDetailsPage}/>
                        <Route path="/trainings" component={TrainingListPage}/>
                        <Route path="/calendar" component={CalendarPage}/>
                        <Route path="/documents" component={DocumentListPage} />
                        <Route path="/create-file" component={CreateDocumentPage} />
                        <Route path="/view-file/:id" exact component={DocumentDetailsPage} />
                        <Route path="/view-file/:id/:versionId" exact component={DocumentDetailsPage} />
                        <Route path="/update-file/:id" exact component={DocumentDetailsPage} />
                        <Route path="/view-training/:id" render={(props) => <TrainingDetailsPage {...props} disableEdit />}/>
                        <Route path="/incident-logs" exact component={RadiationProtectionLogs} />
                        <Route path="/groups" exact component={GroupsList}/>
                        <Route path="/view-group/:id" exact component={GroupDetails}/>
                        <Route path="*" component={NotFoundPage} />
                      </Switch>
                    )
                  }
                  {
                    props.userRole === 'user' &&
                    (
                      <Switch>
                        <Route path="/my-training" component={MyTrainingPage}/>
                        <Route path="/my-diary" component={MyDiaryPage}/>
                        <Route path="/my-calendar" component={CalendarPage}/>
                        <Route path="/training-calendar" component={TrainingCalendarPage}/>

                        <Route path="/training-score" component={ScoreCardPage}/>
                        <Route path="/training-offer" component={TrainingOfferPage}/>
                        <Route path="/training-selection" component={TrainingSelectionPage}/>
                        <Route path="*" component={NotFoundPage} />
                      </Switch>
                    )
                  }


                  </Suspense>

                  {/*
                    !props.userRole && <Route exact path="*" render={() => (
                          <Redirect to="/login"/>
                      )}/>
                  */}
                  {
                    props.userRole === 'user' &&
                    <Route exact path="/" render={() => (
                        <Redirect to="/my-training"/>
                    )}/>
                  }
                  {
                    props.userRole === 'licensee' &&
                    <Route exact path="/" render={() => (
                        <Redirect to="/users"/>
                    )}/>
                  }
                  {
                    props.userRole === 'admin' &&
                    <Route exact path="/" render={() => (
                        <Redirect to="/users"/>
                    )}/>
                  }


                  </div>
                </div>
              </div>
              </div>
              </Fragment>
              </ReactCSSTransitionGroup>

            <ToastContainer/>
            <Notification />
            {<Loading loading={props.loading} progress={props.progress} />}
            <AppFooter/>
        </Fragment>
    )
};

const mapStateToProps = ({ UserRoles, Loading, ThemeOptions }) => ({
  userRole: UserRoles.userRole,
  loading: Loading.loading,
  progress: Loading.progress,
  darkTheme: ThemeOptions.darkTheme
})

const mapDispatchToProps = dispatch => ({
  loadClubs: () => dispatch(getClubs.request()),
  loadMedia: () => dispatch(getMediaList.request()),
  getLicensees: () => dispatch(getLicensees.request()),
  loadTrainings: () => dispatch(getTrainings.request()),
  getDocuments: () => dispatch(getDocuments.request()),
  loadMemberships: () => dispatch(getMemberships.request()),
  loadLicenseeClubs: () => dispatch(getLicenseeClubs.request()),
  checkAuth: () => dispatch(checkAuth.request()),
  loadTrainingCategories: () => dispatch(getTrainingCategories.request()),
  setDarkTheme: (payload) => dispatch({ type: 'SET_THEME', payload })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppMain));
