import * as ActionTypes from '../actions/Calendar';
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  events: [],
  startDate: null,
  endDate: null
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_EVENTS_BY_RANGE.SUCCESS:
    case ActionTypes.GET_EVENTS:
      return { ...state, events: action.payload }
    case ActionTypes.SET_START_DATE:
      return { ...state, startDate: action.payload }
    case ActionTypes.SET_END_DATE:
      return { ...state, endDate: action.payload }
    default:
      return state;
  }
}
