import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_MEDIA_LIST = createRequestTypes('GET_MEDIA_LIST');
export const GET_MEDIA = createRequestTypes('GET_MEDIA');
export const UPDATE_MEDIA = createRequestTypes('UPDATE_MEDIA');
export const CREATE_MEDIA = createRequestTypes('CREATE_MEDIA');
export const DELETE_MEDIA = createRequestTypes('DELETE_MEDIA');
export const UPLOAD_MEDIA = createRequestTypes('UPLOAD_MEDIA');
export const COPY_MEDIA = createRequestTypes('COPY_MEDIA');
export const SET_MEDIA_PAGE = 'SET_MEDIA_PAGE';
export const CLEAR_MEDIA = 'CLEAR_MEDIA';
export const SET_MEDIA_TABLE_FILTER = 'SET_MEDIA_TABLE_FILTER';
export const SET_MEDIA_PAGE_SIZE = 'SET_MEDIA_PAGE_SIZE';

export const setMediaPageSize = (payload) => ({
  type: SET_MEDIA_PAGE_SIZE,
  payload
})

export const setMediaTableFilter = (payload) => ({
  type: SET_MEDIA_TABLE_FILTER,
  payload
})


export const getMediaList = {
  request: () => action(GET_MEDIA_LIST[REQUEST]),
  success: (payload) => action(GET_MEDIA_LIST[SUCCESS], {payload}),
  failure: (payload) => action(GET_MEDIA_LIST[FAILURE], {payload}),
}

export const getMedia = {
  request: (id) => action(GET_MEDIA[REQUEST], {id}),
  success: (payload) => action(GET_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(GET_MEDIA[FAILURE], {payload}),
}

export const updateMedia = {
  request: (id, data) => action(UPDATE_MEDIA[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_MEDIA[FAILURE], {payload}),
}

export const createMedia = {
  request: (data) => action(CREATE_MEDIA[REQUEST], {data}),
  success: (payload) => action(CREATE_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_MEDIA[FAILURE], {payload}),
}

export const deleteMedia = {
  request: (id) => action(DELETE_MEDIA[REQUEST], {id}),
  success: (payload) => action(DELETE_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_MEDIA[FAILURE], {payload}),
}

export const uploadMedia = {
  request: (id) => action(UPLOAD_MEDIA[REQUEST], {id}),
  success: (payload) => action(UPLOAD_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_MEDIA[FAILURE], {payload})
}

export const copyMedia = {
  request: (id) => action(COPY_MEDIA[REQUEST], {id}),
  success: (payload) => action(COPY_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(COPY_MEDIA[FAILURE], {payload})
}
