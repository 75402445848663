import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const CREATE_LICENSEE = createRequestTypes('CREATE_LICENSEE');

export const GET_LICENSEES = createRequestTypes('GET_LICENSEES');

export const GET_BROKEN_USERS = createRequestTypes('GET_BROKEN_USERS');
export const DELETE_BROKEN_USER = createRequestTypes('DELETE_BROKEN_USER');

export const GET_LICENSEE = createRequestTypes('GET_LICENSEE');
export const UPDATE_LICENSEE = createRequestTypes('UPDATE_LICENSEE');
export const DELETE_LICENSEE = createRequestTypes('DELETE_LICENSEE');
export const UPDATE_LICENSEE_PASSWORD = createRequestTypes('UPDATE_LICENSEE_PASSWORD');
export const GET_ALL_CUSTOMERS = createRequestTypes('GET_ALL_CUSTOMERS');

export const SET_LICENSEE_PAGE = 'SET_LICENSEE_PAGE';
export const SET_LICENSEE_TABLE_FILTER = 'SET_ADMIN_TABLE_FILTER';
export const SET_LICENSEE_PAGE_SIZE = 'SET_LICENSEE_PAGE_SIZE';

export const GET_EMS_LOGS = createRequestTypes('GET_EMS_LOGS');
export const DELETE_EMS_LOGS = createRequestTypes('DELETE_EMS_LOGS');
export const SET_EMS_LOGS_PAGE = 'SET_EMS_LOGS_PAGE';
export const SET_EMS_LOGS_PAGE_SIZE = 'SET_EMS_LOGS_PAGE_SIZE';

export const GET_INCIDENT_REPORTS = createRequestTypes('GET_INCIDENT_REPORTS');
export const DELETE_INCIDENT_REPORTS = createRequestTypes('DELETE_INCIDENT_REPORTS');
export const SET_INCIDENT_REPORTS_PAGE = 'SET_INCIDENT_REPORTS_PAGE';
export const SET_INCIDENT_REPORTS_PAGE_SIZE = 'SET_INCIDENT_REPORTS_PAGE_SIZE';

export const setLicenseePageSize = (payload) => ({
  type: SET_LICENSEE_PAGE_SIZE,
  payload
})

export const setLicenseeTableFilter = (payload) => ({
  type: SET_LICENSEE_TABLE_FILTER,
  payload
})

export const setIncidentReportsPageSize = (payload) => ({
  type: SET_INCIDENT_REPORTS_PAGE_SIZE,
  payload
})

export const setIncidentReportsPage = (payload) => ({
  type: SET_INCIDENT_REPORTS_PAGE,
  payload
})

export const setEmsLogsPage = (payload) => ({
  type: SET_EMS_LOGS_PAGE,
  payload
})

export const setEmsLogsPageSize = (payload) => ({
  type: SET_EMS_LOGS_PAGE_SIZE,
  payload
})

export const getIncidentReports = {
  request: (data) => action(GET_INCIDENT_REPORTS[REQUEST], {data}),
  success: (payload) => action(GET_INCIDENT_REPORTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_INCIDENT_REPORTS[FAILURE], {payload}),
}

export const deleteIncidentReports = {
  request: (data) => action(DELETE_INCIDENT_REPORTS[REQUEST], {data}),
  success: (payload) => action(DELETE_INCIDENT_REPORTS[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_INCIDENT_REPORTS[FAILURE], {payload}),
}

export const getEMSLogs = {
  request: (data) => action(GET_EMS_LOGS[REQUEST], {data}),
  success: (payload) => action(GET_EMS_LOGS[SUCCESS], {payload}),
  failure: (payload) => action(GET_EMS_LOGS[FAILURE], {payload}),
}

export const createLicensee = {
  request: (data) => action(CREATE_LICENSEE[REQUEST], {data}),
  success: (payload) => action(CREATE_LICENSEE[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_LICENSEE[FAILURE], {payload}),
}

export const getLicensees = {
  request: (data) => action(GET_LICENSEES[REQUEST], {data}),
  success: (payload) => action(GET_LICENSEES[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSEES[FAILURE], {payload}),
}

export const getLicensee = {
  request: (id) => action(GET_LICENSEE[REQUEST], {id}),
  success: (payload) => action(GET_LICENSEE[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSEE[FAILURE], {payload}),
}

export const updateLicensee = {
  request: (id, data) => action(UPDATE_LICENSEE[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_LICENSEE[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_LICENSEE[FAILURE], {payload}),
}

export const deleteLicensee = {
  request: (id) => action(DELETE_LICENSEE[REQUEST], {id}),
  success: (payload) => action(DELETE_LICENSEE[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_LICENSEE[FAILURE], {payload}),
}

export const updateLicenseePassword = {
  request: (data) => action(UPDATE_LICENSEE_PASSWORD[REQUEST], {data}),
  success: (payload) => action(UPDATE_LICENSEE_PASSWORD[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_LICENSEE_PASSWORD[FAILURE], {payload}),
}

export const getBrokenUsers = {
  request: () => action(GET_BROKEN_USERS[REQUEST]),
  success: (payload) => action(GET_BROKEN_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_BROKEN_USERS[FAILURE], {payload}),
}

export const deleteBrokenUser = {
  request: (id) => action(DELETE_BROKEN_USER[REQUEST], {id}),
  success: (payload) => action(DELETE_BROKEN_USER[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_BROKEN_USER[FAILURE], {payload}),
}

export const getAllCustomers = {
  request: (id) => action(GET_ALL_CUSTOMERS[REQUEST], {id}),
  success: (payload) => action(GET_ALL_CUSTOMERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_ALL_CUSTOMERS[FAILURE], {payload}),
}

export const deleteEmsLogs = {
  request: (data) => action(DELETE_EMS_LOGS[REQUEST], {data}),
  success: (payload) => action(DELETE_EMS_LOGS[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_EMS_LOGS[FAILURE], {payload}),
}
