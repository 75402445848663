import { takeEvery } from 'redux-saga/effects';
import * as clubActions from '../actions/Club';
import api from '../utils/api';
import { apiRequest } from './index';

/***************************** Subroutines ************************************/

// Get clubs
const fetchClubs = apiRequest.bind(null, clubActions.getClubs, api.getClubs)
const fetchLicenseeClubs = apiRequest.bind(null, clubActions.getLicenseeClubs, api.getLicenseeClubs)


/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLoadClubs() {
  yield takeEvery(clubActions.GET_CLUBS.REQUEST, fetchClubs)
}

export function* watchLoadLicenseeClubs() {
  yield takeEvery(clubActions.GET_LICENSEE_CLUBS.REQUEST, fetchLicenseeClubs)
}
