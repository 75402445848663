import * as loadingActions from 'actions/Loading';

export default function reducer(state = {
  loading: false,
  progress: 0,
  requestsInProgressCount: 0
}, action) {
    if (action.type === loadingActions.SET_PROGRESS) return { ...state, progress: action.payload }
    if (action.type === loadingActions.SET_LOADING) return { ...state, loading: action.payload }
    if (action.type.includes('TOKEN')) return state;
    if (action.type.includes('REQUEST')) {
      return { ...state, loading: true, requestsInProgressCount: state.requestsInProgressCount + 1 }
    }
    if (action.type.includes('SUCCESS') || action.type.includes('FAILURE')) {
      const reqCount = state.requestsInProgressCount - 1 >= 0 ? state.requestsInProgressCount - 1 : 0;
      return { ...state, loading: reqCount === 0 ? false : true, progress: 0, requestsInProgressCount: reqCount }
    }
    return state
}
