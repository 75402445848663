import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_MEMBERSHIPS = createRequestTypes('GET_MEMBERSHIPS');
export const UPDATE_MEMBERSHIP = createRequestTypes('UPDATE_MEMBERSHIP');

export const getMemberships = {
  request: () => action(GET_MEMBERSHIPS[REQUEST]),
  success: (payload) => action(GET_MEMBERSHIPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_MEMBERSHIPS[FAILURE], {payload}),
}

export const updateMembership = {
  request: (id, data) => action(UPDATE_MEMBERSHIP[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_MEMBERSHIP[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_MEMBERSHIP[FAILURE], {payload}),
}
