import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const CREATE_DEFAULT_STUDIO_INFO = createRequestTypes('CREATE_DEFAULT_STUDIO_INFO');
export const UPDATE_DEFAULT_STUDIO_INFO = createRequestTypes('UPDATE_DEFAULT_STUDIO_INFO');
export const CREATE_LICENSEE_STUDIO_INFO = createRequestTypes('CREATE_LICENSEE_STUDIO_INFO');
export const UPDATE_LICENSEE_STUDIO_INFO = createRequestTypes('UPDATE_LICENSEE_STUDIO_INFO');
export const GET_DEFAULT_STUDIO_INFO = createRequestTypes('GET_DEFAULT_STUDIO_INFO');
export const GET_LICENSEE_STUDIO_INFO = createRequestTypes('GET_LICENSEE_STUDIO_INFO');
export const UPLOAD_DEFAULT_STUDIO_IMAGE = createRequestTypes('UPLOAD_DEFAULT_STUDIO_IMAGE');
export const UPLOAD_DEFAULT_STUDIO_VIDEO = createRequestTypes('UPLOAD_DEFAULT_STUDIO_VIDEO');
export const UPLOAD_LICENSEE_STUDIO_IMAGE = createRequestTypes('UPLOAD_LICENSEE_STUDIO_IMAGE');
export const UPLOAD_LICENSEE_STUDIO_VIDEO = createRequestTypes('UPLOAD_LICENSEE_STUDIO_VIDEO');
export const UPLOAD_HEADER_IMAGE = createRequestTypes('UPLOAD_HEADER_IMAGE');
export const UPLOAD_STUDIO_FILE = createRequestTypes('UPLOAD_STUDIO_FILE');

export const getDefaultStudioInfo = {
  request: () => action(GET_DEFAULT_STUDIO_INFO[REQUEST]),
  success: (payload) => action(GET_DEFAULT_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(GET_DEFAULT_STUDIO_INFO[FAILURE], {payload}),
}

export const getLicenseeStudioInfo = {
  request: () => action(GET_LICENSEE_STUDIO_INFO[REQUEST]),
  success: (payload) => action(GET_LICENSEE_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSEE_STUDIO_INFO[FAILURE], {payload}),
}

export const createDefaultStudioInfo = {
  request: (data) => action(CREATE_DEFAULT_STUDIO_INFO[REQUEST], {data}),
  success: (payload) => action(CREATE_DEFAULT_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_DEFAULT_STUDIO_INFO[FAILURE], {payload}),
}

export const updateDefaultStudioInfo = {
  request: (id, data) => action(UPDATE_DEFAULT_STUDIO_INFO[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_DEFAULT_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_DEFAULT_STUDIO_INFO[FAILURE], {payload}),
}

export const createLicenseeStudioInfo = {
  request: (data) => action(CREATE_LICENSEE_STUDIO_INFO[REQUEST], {data}),
  success: (payload) => action(CREATE_LICENSEE_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_LICENSEE_STUDIO_INFO[FAILURE], {payload}),
}

export const updateLicenseeStudioInfo = {
  request: (id, data) => action(UPDATE_LICENSEE_STUDIO_INFO[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_LICENSEE_STUDIO_INFO[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_LICENSEE_STUDIO_INFO[FAILURE], {payload}),
}

export const uploadLicenseeStudioImage = {
  request: (data) => action(UPLOAD_LICENSEE_STUDIO_IMAGE[REQUEST], {data}),
  success: (payload) => action(UPLOAD_LICENSEE_STUDIO_IMAGE[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_LICENSEE_STUDIO_IMAGE[FAILURE], {payload}),
}

export const uploadLicenseeStudioVideo = {
  request: (data) => action(UPLOAD_LICENSEE_STUDIO_VIDEO[REQUEST], {data}),
  success: (payload) => action(UPLOAD_LICENSEE_STUDIO_VIDEO[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_LICENSEE_STUDIO_VIDEO[FAILURE], {payload}),
}

export const uploadDefaultStudioImage = {
  request: (data) => action(UPLOAD_DEFAULT_STUDIO_IMAGE[REQUEST], {data}),
  success: (payload) => action(UPLOAD_DEFAULT_STUDIO_IMAGE[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_DEFAULT_STUDIO_IMAGE[FAILURE], {payload}),
}

export const uploadDefaultStudioVideo = {
  request: (data) => action(UPLOAD_DEFAULT_STUDIO_VIDEO[REQUEST], {data}),
  success: (payload) => action(UPLOAD_DEFAULT_STUDIO_VIDEO[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_DEFAULT_STUDIO_VIDEO[FAILURE], {payload}),
}

export const uploadHeaderImage = {
  request: (data) => action(UPLOAD_HEADER_IMAGE[REQUEST], {data}),
  success: (payload) => action(UPLOAD_HEADER_IMAGE[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_HEADER_IMAGE[FAILURE], {payload}),
}
