import * as ActionTypes from '../actions/Studio';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  defaultStudioInfo: null,
  licenseeStudioInfo: null,
  licenseeVideoUrl: null,
  licenseeImageUrl: null,
  defaultImageUrl: null,
  defaultVideoUrl: null
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_DEFAULT_STUDIO_INFO.SUCCESS:
      return {
        ...state,
        defaultStudioInfo: action.payload,
        defaultVideoUrl: get(action, 'payload.videoKey', null),
        defaultImageUrl: get(action, 'payload.imageKey', null)
      }
    case ActionTypes.GET_LICENSEE_STUDIO_INFO.SUCCESS:
      return {
        ...state,
        licenseeStudioInfo: action.payload,
        licenseeVideoUrl: get(action, 'payload.videoKey', null),
        licenseeImageUrl: get(action, 'payload.imageKey', null),
        imagePlaceholderKey: get(action, 'payload.imagePlaceholderKey', null)
      }
    case ActionTypes.UPLOAD_DEFAULT_STUDIO_VIDEO.SUCCESS:
      return { ...state, defaultVideoUrl: get(action, 'payload.key') }
    case ActionTypes.UPLOAD_LICENSEE_STUDIO_VIDEO.SUCCESS:
      return { ...state, licenseeVideoUrl: get(action, 'payload.key') }
    case ActionTypes.UPLOAD_DEFAULT_STUDIO_IMAGE.SUCCESS:
      return { ...state, defaultImageUrl: get(action, 'payload.key') }
    case ActionTypes.UPLOAD_LICENSEE_STUDIO_IMAGE.SUCCESS:
      return { ...state, licenseeImageUrl: get(action, 'payload.key') }
    case ActionTypes.UPLOAD_HEADER_IMAGE.SUCCESS:
      return { ...state, imagePlaceholderKey: get(action, 'payload.key') }
    default:
      return state;
  }
}
