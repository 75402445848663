import React, {Component} from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

import MetisMenu from 'react-metismenu';

import {CustomerNav, LicenseeNav, AdminNav} from './NavItems';

const CustomLink = (props) => {
  return (<NavLink
    className={`${props.className} ${props.active ? props.classNameActive : ''}`}
    to={props.to}>
    {props.children}
    </NavLink>)
}

class Nav extends Component {
  handleActiveMenu = () => {

  }
  render() {
    const { userRole } = this.props;
    return (
      <>

          { userRole === 'user' &&
            <>
            <h5 className="app-sidebar__heading">CUSTOMER DASHBOARD</h5>
            <MetisMenu
              content={CustomerNav}
              activeLinkFromLocation
              activeLinkTo={this.props.location.pathname}
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
              LinkComponent={CustomLink}
              />
            </>
          }
          { userRole === 'licensee' &&
            <>
            <h5 className="app-sidebar__heading">LICENSEE DASHBOARD</h5>
            <MetisMenu
              content={LicenseeNav}
              activeLinkFromLocation
              activeLinkTo={this.props.location.pathname}
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
              LinkComponent={CustomLink}
              />
            </>
          }
          { userRole === 'admin' &&
            <>
            <h5 className="app-sidebar__heading">BC ADMIN DASHBOARD</h5>
            <MetisMenu
              content={AdminNav}
              activeLinkFromLocation
              activeLinkTo={this.props.location.pathname}
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
              LinkComponent={CustomLink}
              />
            </>
            }
          </>
      );
  }

  isPathActive(path) {
      return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole
})

export default connect(mapStateToProps, null)(withRouter(Nav));
