import { takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as trashActions from 'actions/Trash';

const getTrashDocuments = apiRequest.bind(null, trashActions.getTrashDocuments, api.getTrashDocuments);
const getTrashMedia = apiRequest.bind(null, trashActions.getTrashMedia, api.getTrashMedia);
const getTrashTrainings = apiRequest.bind(null, trashActions.getTrashTrainings, api.getTrashTrainings);

export function* watchGetTrashDocumentsRequest() {
  yield takeEvery(trashActions.GET_TRASH_DOCUMENTS.REQUEST, getTrashDocuments)
}

export function* watchGetTrashTrainingsRequest() {
  yield takeEvery(trashActions.GET_TRASH_TRAININGS.REQUEST, getTrashTrainings)
}

export function* watchGetTrashMediaRequest() {
  yield takeEvery(trashActions.GET_TRASH_MEDIA.REQUEST, getTrashMedia)
}
