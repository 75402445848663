import * as ActionTypes from '../actions/Training';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  trainings: [],
  training: null,
  trainingPage: 0,
  categories: [],
  trainingsInitialPage: 0,
  trainingsPageSize: 100,
  trainingVideoUrl: null,
  teaserVideoUrl: null,
  tableFilter: '',
  tableFilterCategory: null,
  scores: [],
  logsTableFilter: ''
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.SET_TRAININGS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload }
    case ActionTypes.SET_LOGS_TABLE_FILTER:
      return { ...state, logsTableFilter: action.payload }
    case ActionTypes.SET_TRAININGS_TABLE_FILTER_CATEGORY:
      return { ...state, tableFilterCategory: action.payload }
    case ActionTypes.CONVERT_TRAINING_VIDEO.SUCCESS:
      return { ...state, duration: get(action, 'payload.duration'), trainingVideoUrl: get(action, 'payload.key') }
    case ActionTypes.UPLOAD_TEASER_VIDEO.SUCCESS:
      return { ...state, teaserDuration:  get(action, 'payload.duration'), teaserVideoUrl: get(action, 'payload.key') }
    case ActionTypes.SET_TRAININGS_PAGE:
      return { ...state, trainingsInitialPage: action.payload }
    case ActionTypes.SET_TRAINING_PAGE_SIZE:
      return { ...state, trainingsPageSize: action.payload }
    case ActionTypes.GET_TRAININGS.SUCCESS:
      return { ...state, trainings: action.payload, trainingVideoUrl: null, teaserVideoUrl: null }
    case ActionTypes.GET_TRAINING_EVENTS_BY_PRINCIPAL.SUCCESS:
      return { ...state, scores: action.payload }
    case ActionTypes.GET_TRAINING.SUCCESS:
      return {
        ...state,
        training: action.payload,
        trainingPage: get(action, 'payload.currentPage', 0)
       }
    case ActionTypes.GET_TRAINING_CATEGORIES.SUCCESS:
      return { ...state, categories: action.payload }
    case ActionTypes.CREATE_TRAINING.SUCCESS:
    case ActionTypes.UPDATE_TRAINING.SUCCESS:
      return state;
    default:
      return state;
  }
}
