import React, { useState } from 'react';
import { Button, Modal, Row, Label, Col, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './styles.scss';
import { setLoading } from 'actions/Loading';
import { connect } from 'react-redux';
import api from 'utils/api';
import { get } from 'lodash';
import {
  toast,
  Slide
} from 'react-toastify';

const VersionsModal = ({ open, setLoading, toggleModal, user, items }) => {
  const [reportMode, toggleReportMode] = useState(false);
  const [description, setDescription] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [fileInpKey, setFileInpKey] = useState(Math.random());

  const showMessage = (message, type = 'success') => {
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: false,
      position: 'bottom-center',
      type
    });
  }

  const uploadReportFiles = async (key) => {
    try {
      const payload = {
        files,
        key
      };

      const {error} = await api.uploadErrorReportAttachment(payload);
      setLoading(false);
      if (!error) showMessage('Successfully created report & uploaded files');
      else showMessage("Error uploading report files", 'error');
    } catch(err) {
      showMessage("Error uploading report files", 'error');
      setLoading(false);
      console.log(err);
    }
  }

  const deleteFile = (name) => {
    const newFiles = files && files.filter(el => {
      if (el.name === name) return false;
      return true;
    });
    setFiles(newFiles);
  }

  const handleFilesChange = (e) => {
    setFiles(Array.from(e.target.files));
    setFileInpKey(Math.random())
  }

  const sendReport = async () => {
    try {
      setLoading(true);
      const username = get(user, 'preferred_username');
      const payload = {
        "curlRequest": [], // todo
        "errorMessage": "", // todo
        "issueDescription": description,
        "reporterUsername": username
      }

      const { response, error } = await api.createErrorReport(payload);
      const key = get(response, 'key');
      if (error) {
        throw new Error('request failed'); 
      }
      if (key) {
        if (!files) {
          setLoading(false);
          showMessage("Successully created report");
        }
        else uploadReportFiles(key);
      }

    } catch(err) {
      showMessage("Error creating report", 'error');
      console.log(err);
      setLoading(false)
    }
  }
  const renderItems = (items) => {
    return Object.keys(items).map(key => {
      const el = items[key];
      if (el.name) return (
          <div key={el.name} className="version-info-item">
            <p>{el.name}</p>
            <p>{el.version}</p>
          </div>
        );
      if (Object.keys(el).length > 0) {
        return renderItems(el);
      }
      return null;
    })
  }
 return (
    <Modal isOpen={open}>
      <ModalHeader toggle={() => toggleModal(false)}>Services version information</ModalHeader>
      <ModalBody>
        {
          reportMode
          ? <>
            <Row>
              <Col>
                <Label>
                  Description
                </Label>
                <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Issue description" />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
              <Label>
                Attachments (optional)
              </Label>
              <label for="reportfileinp" className="file-btn btn btn-primary">Upload Files</label>
              <input key={fileInpKey} id="reportfileinp" onChange={handleFilesChange} type="file" multiple />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
              {
                files.map((el, i) => {
                  return (
                    <div key={`file-${i}`} className="file-row mt-2">
                      <div>{el.name}</div>
                      <Button onClick={() => deleteFile(el.name)}>
                        <i className="dropdown-icon lnr-trash"> </i>
                      </Button>
                    </div>
                  )
                })
              }
              </Col>
            </Row>
          </>
          : renderItems(items)
        }
      </ModalBody>
      <ModalFooter>
          {
            reportMode && (
              <Button color="primary" onClick={sendReport}>Send report</Button>
            )
          }
          <Button onClick={() => toggleReportMode(!reportMode)}>{reportMode ? 'Back to services versions' : 'Report a problem'}</Button>
          <Button onClick={() => toggleModal(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (data) => dispatch(setLoading(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VersionsModal);
