import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_EVENTS = createRequestTypes('GET_EVENTS');
export const CREATE_EVENT = createRequestTypes('CREATE_EVENT');
export const GET_EVENT = createRequestTypes('GET_EVENT');
export const UPDATE_EVENT = createRequestTypes('UPDATE_EVENT');
export const DELETE_EVENT = createRequestTypes('DELETE_EVENT');
export const GET_EVENTS_BY_RANGE = createRequestTypes('GET_EVENTS_BY_RANGE');
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

export const getEvents = {
  request: () => action(GET_EVENTS[REQUEST]),
  success: (payload) => action(GET_EVENTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_EVENTS[FAILURE], {payload}),
}

export const createEvent = {
  request: (data) => action(CREATE_EVENT[REQUEST], {data}),
  success: (payload) => action(CREATE_EVENT[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_EVENT[FAILURE], {payload}),
}

export const getEvent = {
  request: (id) => action(GET_EVENT[REQUEST], {id}),
  success: (payload) => action(GET_EVENT[SUCCESS], {payload}),
  failure: (payload) => action(GET_EVENT[FAILURE], {payload}),
}

export const updateEvent = {
  request: (id, data) => action(UPDATE_EVENT[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_EVENT[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_EVENT[FAILURE], {payload}),
}

export const deleteEvent = {
  request: (id) => action(DELETE_EVENT[REQUEST], {id}),
  success: (payload) => action(DELETE_EVENT[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_EVENT[FAILURE], {payload}),
}

export const getEventsByRange = {
  request: (data) => action(GET_EVENTS_BY_RANGE[REQUEST], {data}),
  success: (payload) => action(GET_EVENTS_BY_RANGE[SUCCESS], {payload}),
  failure: (payload) => action(GET_EVENTS_BY_RANGE[FAILURE], {payload}),
}

export const setStartDate = (payload) => ({
  type: SET_START_DATE,
  payload
})

export const setEndDate = (payload) => ({
  type: SET_END_DATE,
  payload
})
