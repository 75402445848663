import { takeEvery, select } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as groupsActions from 'actions/Groups';
import history from 'utils/history';

const getGroups = apiRequest.bind(null, groupsActions.getGroups, api.getGroups);
const getGroup = apiRequest.bind(null, groupsActions.getGroup, api.getGroup);
const getGroupByName = apiRequest.bind(null, groupsActions.getGroupByName, api.getGroupByName);
const getUserGroups = apiRequest.bind(null, groupsActions.getUserGroups, api.getUserGroups);
const getGroupUsers = apiRequest.bind(null, groupsActions.getGroupUsers, api.getGroupUsers);
const createGroup = apiRequest.bind(null, groupsActions.createGroup, api.createGroup);
const updateGroup = apiRequest.bind(null, groupsActions.updateGroup, api.updateGroup);
const deleteGroup = apiRequest.bind(null, groupsActions.deleteGroup, api.deleteGroup);
const updateUserGroups = apiRequest.bind(null, groupsActions.updateUserGroups, api.updateUserGroups);
const addTrainingsToGroup = apiRequest.bind(null, groupsActions.addTrainingsToGroup, api.addTrainingsToGroup);
const getGroupTrainings = apiRequest.bind(null, groupsActions.getGroupTrainings, api.getGroupTrainings);
const deleteTrainingsFromGroup = apiRequest.bind(null, groupsActions.deleteTrainingsFromGroup, api.deleteTrainingsFromGroup);
const getTrainingGroups = apiRequest.bind(null, groupsActions.getTrainingGroups, api.getTrainingGroups);
const addGroupsToTraining = apiRequest.bind(null, groupsActions.addGroupsToTraining, api.addTrainingsToGroup);
const deleteGroupsFromTraining = apiRequest.bind(null, groupsActions.deleteGroupsFromTraining, api.deleteTrainingsFromGroup);

function* handleChangeGroup() {
  yield history.push('/groups');
}

function* handleUserGroupsRefresh() {
  const getUserUuid = (state) => state.User.user.userUuid;
  const userUuid = yield select(getUserUuid);

  const arg = { id: userUuid }

  yield apiRequest.apply(null, [ groupsActions.getUserGroups, api.getUserGroups, arg ])
}

function* handleGroupTrainingsRefresh() {
  const getGroupUuid = (state) => state.Groups.group.uuid;
  const groupUuid = yield select(getGroupUuid);

  const arg = {
    data: {
      groupUuid
    }
  }

  yield apiRequest.apply(null, [ groupsActions.getGroupTrainings, api.getGroupTrainings, arg ])
}

function* handleTrainingGroupsRefresh() {
  const getTrainingId = (state) => state.Training.training.id;
  const trainingId = yield select(getTrainingId);

  const arg = {
    data: {
      trainingId: trainingId
    }
  }

  yield apiRequest.apply(null, [ groupsActions.getTrainingGroups, api.getTrainingGroups, arg ])
}


/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/

export function* watchGetGroupsRequest() {
  yield takeEvery(groupsActions.GET_GROUPS.REQUEST, getGroups)
}

export function* watchGetGroupRequest() {
  yield takeEvery(groupsActions.GET_GROUP.REQUEST, getGroup)
}

export function* watchGetGroupByNameRequest() {
  yield takeEvery(groupsActions.GET_GROUP_BY_NAME.REQUEST, getGroupByName)
}

export function* watchGetGroupUsersRequest() {
  yield takeEvery(groupsActions.GET_GROUP_USERS.REQUEST, getGroupUsers)
}

export function* watchGetUserGroupsRequest() {
  yield takeEvery(groupsActions.GET_USER_GROUPS.REQUEST, getUserGroups)
}

export function* watchCreateGroupRequest() {
  yield takeEvery(groupsActions.CREATE_GROUP.REQUEST, createGroup)
}

export function* watchUpdateGroupRequest() {
  yield takeEvery(groupsActions.UPDATE_GROUP.REQUEST, updateGroup)
}

export function* watchDeleteGroupRequest() {
  yield takeEvery(groupsActions.DELETE_GROUP.REQUEST, deleteGroup)
}

export function* watchDeleteGroupSuccess() {
  yield takeEvery(groupsActions.DELETE_GROUP.SUCCESS, getGroups)
}

export function* watchCreateGroupSuccess() {
  yield takeEvery(groupsActions.CREATE_GROUP.SUCCESS, getGroups)
}

export function* watchUpdateGroupSuccess() {
  yield takeEvery(groupsActions.UPDATE_GROUP.SUCCESS, handleChangeGroup)
}

export function* watchUpdateUserGroupsRequest() {
  yield takeEvery(groupsActions.UPDATE_USER_GROUPS.REQUEST, updateUserGroups)
}

export function* watchGetGroupTrainingsRequest() {
  yield takeEvery(groupsActions.GET_GROUP_TRAININGS.REQUEST, getGroupTrainings)
}

export function* watchAddTrainingsToGroupRequest() {
  yield takeEvery(groupsActions.ADD_TRAININGS_TO_GROUP.REQUEST, addTrainingsToGroup)
}

export function* watchDeleteTrainingsFromGroupRequest() {
  yield takeEvery(groupsActions.DELETE_TRAININGS_FROM_GROUP.REQUEST, deleteTrainingsFromGroup)
}

export function* watchDeleteTrainingsFromGroupSuccess() {
  yield takeEvery(groupsActions.DELETE_TRAININGS_FROM_GROUP.SUCCESS, handleGroupTrainingsRefresh)
}

export function* watchAddTrainingsToGroupSuccess() {
  yield takeEvery(groupsActions.ADD_TRAININGS_TO_GROUP.SUCCESS, handleGroupTrainingsRefresh)
}

export function* watchGetTrainingGroupsRequest() {
  yield takeEvery(groupsActions.GET_TRAINING_GROUPS.REQUEST, getTrainingGroups)
}

export function* watchAddGroupsToTrainingRequest() {
  yield takeEvery(groupsActions.ADD_GROUPS_TO_TRAINING.REQUEST, addGroupsToTraining)
}

export function* watchAddGroupsToTrainingSuccess() {
  yield takeEvery(groupsActions.ADD_GROUPS_TO_TRAINING.SUCCESS, handleTrainingGroupsRefresh)
}

export function* watchDeleteGroupsFromTrainingRequest() {
  yield takeEvery(groupsActions.DELETE_GROUPS_FROM_TRAINING.REQUEST, deleteGroupsFromTraining)
}

export function* watchDeleteGroupsFromTrainingSuccess() {
  yield takeEvery(groupsActions.DELETE_GROUPS_FROM_TRAINING.SUCCESS, handleTrainingGroupsRefresh)
}

export function* watchUpdateUserGroupsSuccess() {
  yield takeEvery(groupsActions.UPDATE_USER_GROUPS.SUCCESS, handleUserGroupsRefresh)
}
