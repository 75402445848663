import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_REPORTS = createRequestTypes('GET_REPORTS');
export const SET_REPORTS_PAGE_SIZE = 'SET_REPORTS_PAGE_SIZE';

export const getReports = {
  request: (data, reportType) => action(GET_REPORTS[REQUEST], {data, reportType}),
  success: (payload) => action(GET_REPORTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_REPORTS[FAILURE], {payload}),
};

export const setReportsPageSize = (payload) => ({
  type: SET_REPORTS_PAGE_SIZE,
  payload
});
