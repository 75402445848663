import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const LOGIN = createRequestTypes('LOGIN');
export const GET_TOKEN = createRequestTypes('GET_TOKEN');
export const GET_NEW_TOKEN = createRequestTypes('GET_NEW_TOKEN');
export const LOGOUT = createRequestTypes('LOGOUT');

export const login = {
  request: (data) => action(LOGIN[REQUEST], {data}),
  success: (payload) => action(LOGIN[SUCCESS], {payload}),
  failure: (payload) => action(LOGIN[FAILURE], {payload}),
}

export const checkAuth = {
  request: (data) => action(GET_TOKEN[REQUEST], {data}),
  success: (payload) => action(GET_TOKEN[SUCCESS], {payload}),
  failure: (payload) => action(GET_TOKEN[FAILURE], {payload}),
}

export const logout = {
  request: (data) => action(LOGOUT[REQUEST], {data}),
  success: (payload) => action(LOGOUT[SUCCESS], {payload}),
  failure: (payload) => action(LOGOUT[FAILURE], {payload}),
}

export const getNewToken = {
  request: (data) => action(GET_NEW_TOKEN[REQUEST], {data}),
  success: (payload) => action(GET_NEW_TOKEN[SUCCESS], {payload}),
  failure: (payload) => action(GET_NEW_TOKEN[FAILURE], {payload}),
}
