import { takeEvery, select } from 'redux-saga/effects';
import * as calendarActions from '../actions/Calendar';
import api from 'utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';
import moment from 'moment';

const getEvent = apiRequest.bind(null, calendarActions.getEvent, api.getTrainingEvent);
const getEvents = apiRequest.bind(null, calendarActions.getEvents, api.getTrainingEvents);
const getEventsByRange = apiRequest.bind(null, calendarActions.getEventsByRange, api.getTrainingEventsByRange);
const createEvent = apiRequest.bind(null, calendarActions.createEvent, api.createTrainingEvent);
const updateEvent = apiRequest.bind(null, calendarActions.updateEvent, api.updateTrainingEvent);
const deleteEvent = apiRequest.bind(null, calendarActions.deleteEvent, api.deleteTrainingEvent);

function* handleChangeEventsSuccess() {
  const state = yield select();
  const startDate = get(state, 'Calendar.startDate', new Date().toISOString());
  const finishDate = get(state, 'Calendar.endDate', new Date().toISOString());

  const arg = {
    data: {
      start: moment(startDate).subtract(1, 'months').toISOString(),
      end: moment(finishDate).add(1, 'months').toISOString()
    }
  }
  yield apiRequest.apply(null, [calendarActions.getEventsByRange, api.getTrainingEventsByRange, arg])
}

// /******************************************************************************/
// /******************************* WATCHERS *************************************/
// /******************************************************************************/

export function* watchGetEventRequest() {
  yield takeEvery(calendarActions.GET_EVENT.REQUEST, getEvent)
}

export function* watchGetEventsRequest() {
  yield takeEvery(calendarActions.GET_EVENTS.REQUEST, getEvents)
}

export function* watchGetEventsByRangeRequest() {
  yield takeEvery(calendarActions.GET_EVENTS_BY_RANGE.REQUEST, getEventsByRange)
}

export function* watchCreateEventRequest() {
  yield takeEvery(calendarActions.CREATE_EVENT.REQUEST, createEvent)
}

export function* watchUpdateEventRequest() {
  yield takeEvery(calendarActions.UPDATE_EVENT.REQUEST, updateEvent)
}

export function* watchDeleteEventRequest() {
  yield takeEvery(calendarActions.DELETE_EVENT.REQUEST, deleteEvent)
}

export function* watchCreateEventSuccess() {
  yield takeEvery(calendarActions.CREATE_EVENT.SUCCESS, handleChangeEventsSuccess)
}

export function* watchUpdateEventSuccess() {
  yield takeEvery(calendarActions.UPDATE_EVENT.SUCCESS, handleChangeEventsSuccess)
}

export function* watchDeleteEventSuccess() {
  yield takeEvery(calendarActions.DELETE_EVENT.SUCCESS, handleChangeEventsSuccess)
}
