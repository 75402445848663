import * as ActionTypes from '../actions/LicenseModels'
import * as AuthActionTypes from '../actions/Auth';
import { get } from "lodash";

const initialState = {
  licenseModels: [],
  licenseModel: {},
  licenseUsers: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_LICENSE_MODELS.SUCCESS:
      return { ...state, licenseModels: action.payload }
    case ActionTypes.GET_LICENSE_MODEL.SUCCESS:
    case ActionTypes.UPDATE_LICENSE_MODEL.SUCCESS:
      return { ...state, licenseModel: action.payload }
    case ActionTypes.GET_LICENSE_USERS.SUCCESS:
      return {
        ...state,
        licenseUsers: get(action.payload, 'content', []),
        licensePage: get(action.payload, 'page'),
        licenseTotalElements: get(action.payload, 'totalElements'),
        licenseTotalPages: get(action.payload, 'totalPages'),
      }
    default:
      return state;
  }
}
