import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_GROUPS = createRequestTypes('GET_GROUPS');
export const GET_GROUP = createRequestTypes('GET_GROUP');
export const GET_GROUP_BY_NAME = createRequestTypes('GET_GROUP_BY_NAME');
export const GET_GROUP_USERS = createRequestTypes('GET_GROUP_USERS');
export const GET_USER_GROUPS = createRequestTypes('GET_USER_GROUPS');
export const CREATE_GROUP = createRequestTypes('CREATE_GROUP');
export const UPDATE_GROUP = createRequestTypes('UPDATE_GROUP');
export const DELETE_GROUP = createRequestTypes('DELETE_GROUP');
export const ADD_USER_TO_GROUP = createRequestTypes('ADD_USER_TO_GROUP');
export const UPDATE_USER_GROUPS = createRequestTypes('UPDATE_USER_GROUPS');
export const GET_GROUP_TRAININGS = createRequestTypes('GET_GROUP_TRAININGS');
export const ADD_TRAININGS_TO_GROUP = createRequestTypes('ADD_TRAININGS_TO_GROUP');
export const DELETE_TRAININGS_FROM_GROUP = createRequestTypes('DELETE_TRAININGS_FROM_GROUP');
export const GET_TRAINING_GROUPS = createRequestTypes('GET_TRAINING_GROUPS');
export const ADD_GROUPS_TO_TRAINING = createRequestTypes('ADD_GROUPS_TO_TRAINING');
export const DELETE_GROUPS_FROM_TRAINING = createRequestTypes('DELETE_GROUPS_FROM_TRAINING');

export const getGroups = {
  request: (data) => action(GET_GROUPS[REQUEST], {data}),
  success: (payload) => action(GET_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUPS[FAILURE], {payload}),
}

export const getGroup = {
  request: (id) => action(GET_GROUP[REQUEST], {id}),
  success: (payload) => action(GET_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUP[FAILURE], {payload}),
}

export const getGroupByName = {
  request: (id) => action(GET_GROUP_BY_NAME[REQUEST], {id}),
  success: (payload) => action(GET_GROUP_BY_NAME[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUP_BY_NAME[FAILURE], {payload}),
}

export const addUserToGroup = {
  request: (data) => action(ADD_USER_TO_GROUP[REQUEST], {data}),
  success: (payload) => action(ADD_USER_TO_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(ADD_USER_TO_GROUP[FAILURE], {payload}),
}

export const getGroupUsers = {
  request: (data) => action(GET_GROUP_USERS[REQUEST], {data}),
  success: (payload) => action(GET_GROUP_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUP_USERS[FAILURE], {payload}),
};

export const getUserGroups = {
  request: (id) => action(GET_USER_GROUPS[REQUEST], {id}),
  success: (payload) => action(GET_USER_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_GROUPS[FAILURE], {payload}),
};

export const createGroup = {
  request: (data) => action(CREATE_GROUP[REQUEST], {data}),
  success: (payload) => action(CREATE_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_GROUP[FAILURE], {payload}),
}

export const updateGroup = {
  request: (id, data) => action(UPDATE_GROUP[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_GROUP[FAILURE], {payload}),
}

export const deleteGroup = {
  request: (id) => action(DELETE_GROUP[REQUEST], {id}),
  success: (payload) => action(DELETE_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_GROUP[FAILURE], {payload}),
}

export const updateUserGroups = {
  request: (id) => action(UPDATE_USER_GROUPS[REQUEST], {id}),
  success: (payload) => action(UPDATE_USER_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_USER_GROUPS[FAILURE], {payload}),
}

export const getGroupTrainings = {
  request: (data) => action(GET_GROUP_TRAININGS[REQUEST], {data}),
  success: (payload) => action(GET_GROUP_TRAININGS[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUP_TRAININGS[FAILURE], {payload}),
}

export const getTrainingGroups = {
  request: (data) => action(GET_TRAINING_GROUPS[REQUEST], {data}),
  success: (payload) => action(GET_TRAINING_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRAINING_GROUPS[FAILURE], {payload}),
}

export const addTrainingsToGroup = {
  request: (data) => action(ADD_TRAININGS_TO_GROUP[REQUEST], {data}),
  success: (payload) => action(ADD_TRAININGS_TO_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(ADD_TRAININGS_TO_GROUP[FAILURE], {payload}),
}

export const deleteTrainingsFromGroup = {
  request: (data) => action(DELETE_TRAININGS_FROM_GROUP[REQUEST], {data}),
  success: (payload) => action(DELETE_TRAININGS_FROM_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_TRAININGS_FROM_GROUP[FAILURE], {payload}),
}

export const addGroupsToTraining = {
  request: (data) => {
    console.log('add')
    return action(ADD_GROUPS_TO_TRAINING[REQUEST], { data })
  },
  success: (payload) => action(ADD_GROUPS_TO_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(ADD_GROUPS_TO_TRAINING[FAILURE], {payload}),
}

export const deleteGroupsFromTraining = {
  request: (data) => action(DELETE_GROUPS_FROM_TRAINING[REQUEST], {data}),
  success: (payload) => action(DELETE_GROUPS_FROM_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_GROUPS_FROM_TRAINING[FAILURE], {payload}),
}
