import React from 'react';
import MetisMenu from 'react-metismenu';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Component = ({ open = false, closeMenu, nav = [], history }) => (
  <div className={`d-mobile-menu-overlay ${open ? 'open' : ''}`}>
  <div className="d-close-button" onClick={closeMenu}><FontAwesomeIcon icon={faTimes} /></div>
  <div onClick={closeMenu}>
  <MetisMenu content={nav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
  </div>
  </div>
)

export default Component;
