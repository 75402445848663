import * as ActionTypes from '../actions/Club'
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  clubs: [],
  licenseeClubs: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_CLUBS.SUCCESS:
      return { ...state, clubs: action.payload }
    case ActionTypes.GET_LICENSEE_CLUBS.SUCCESS:
      return { ...state, licenseeClubs: action.payload }
    default:
      return state;
  }
}
