import React, { useState } from 'react';

import Nav from '../AppNav/VerticalNavWrapper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ServiceVersionModal from 'components/ServiceVersionsModal';
import api from 'utils/api';
import {version} from 'utils/constants';

import logo from '../../assets/utils/images/b24ImageDark.png';
import logoLight from '../../assets/utils/images/b24Image.png';

const Sidebar = ({ darkTheme }) => {
  const [modalOpen, toggleModal] = useState(false);
  const [items, setItems] = useState({});
  const showServiceVersions = async () => {
    const { response } = await api.getServiceVersions();
    if (response) {
      response.bcAdmin = {
        name: 'BC Admin panel',
        version
      }
      setItems(response);
      toggleModal(true);
    }
  }
  return (
    <div className="d-sidebar">
        <img
          onClick={showServiceVersions}
          style={{cursor: 'pointer'}}
          alt="sidebar-icon" src={darkTheme ? logoLight : logo}
          className="d-menu-logo"
          />
        <div className="d-sidebar-content">
          <ReactCSSTransitionGroup
              component="div"
              className={"d-sidebar-nav"}
              transitionName="SidebarAnimation"
              transitionAppear={true}
              transitionAppearTimeout={1500}
              transitionEnter={false}
              transitionLeave={false}>
              <PerfectScrollbar>
                  <div className="app-sidebar__inner">
                      <Nav/>
                  </div>
              </PerfectScrollbar>
          </ReactCSSTransitionGroup>
        </div>
        <ServiceVersionModal open={modalOpen} toggleModal={toggleModal} items={items} />
    </div>
  )
}

export default Sidebar;
