import { takeEvery } from 'redux-saga/effects';
import * as membershipActions from '../actions/Membership';
import api from '../utils/api';
import { apiRequest } from './index';

/***************************** Subroutines ************************************/

// Get memberships
const fetchMemberships = apiRequest.bind(null, membershipActions.getMemberships, api.getMemberships)
const updateMembership = apiRequest.bind(null, membershipActions.updateMembership, api.updateMembership)

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLoadMemberships() {
  yield takeEvery(membershipActions.GET_MEMBERSHIPS.REQUEST, fetchMemberships)
}

export function* watchUpdateMembership() {
  yield takeEvery(membershipActions.UPDATE_MEMBERSHIP.REQUEST, updateMembership)
}
