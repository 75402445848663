import { Component } from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../actions/Notifications';
import {
    toast,
    Slide
} from 'react-toastify';

class Notification extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      if (this.props.error.ignoreError) return null;

      toast(this.props.error && this.props.error.message, {
          transition: Slide,
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
          type: 'error'
      });
      setTimeout(() => {
        this.props.clearError();
      }, 2000)
    }
  }
  render() {
    return null;
  }
}

const mapStateToProps = ({ Notifications }) => ({
  error: Notifications.error
})

const mapDispatchToProps = dispatch => ({
  clearError
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
