 import { takeEvery, select, put } from 'redux-saga/effects';
 import * as licenseeActions from 'actions/Licensee';
 import api from 'utils/api';
 import { apiRequest } from './index';
 import { get } from 'lodash';
 import history from 'utils/history';
 import {
     toast,
     Slide
 } from 'react-toastify';

/***************************** Subroutines ************************************/

const fetchUsers = apiRequest.bind(null, licenseeActions.users, api.getUsers)
const fetchFilteredUsers = apiRequest.bind(null, licenseeActions.getFilteredUsers, api.getFilteredUsers)
const fetchUser = apiRequest.bind(null, licenseeActions.getUser, api.getUser)
const addUser = apiRequest.bind(null, licenseeActions.createUser, api.createUser)
const editUser = apiRequest.bind(null, licenseeActions.updateUser, api.updateUser)
const removeUser = apiRequest.bind(null, licenseeActions.deleteUser, api.deleteUser)
const updateUserPassword = apiRequest.bind(null, licenseeActions.updateUserPassword, api.updateUserPassword);

function* refreshUsers() {
  const state = yield select();
  const tableFilter = get(state, 'Licensee.tableFilter', '');
  const prevData = window.lastFetchParamsUsers || {};
  const arg = {
    ...get(arguments, '0', {}),
    data: {
      ...prevData,
      page: tableFilter ? 0 : get(state, 'Licensee.usersPage', 0),
      pageSize: get(state, 'Licensee.usersPageSize', 100)
    }
  };
  yield apiRequest.apply(null, [licenseeActions.users, api.getUsers, arg]);
}

function* handleUsersCreateSuccess(action) {
  const state = yield select();
  const allUsers = get(state, 'Licensee.users', []);
  const pageSize = get(state, 'Licensee.usersPageSize', 20);
  const lastPage = Math.ceil((allUsers.length + 1) / pageSize) -1 ;
  yield put({ type: licenseeActions.SET_USERS_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push('/users');
}

function* handleUsersUpdateSuccess(action) {
  yield history.push('/users');
}

function* handlePasswordUpdateSusccess() {
  yield toast('Success', {
        transition: Slide,
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
        type: 'success'
    });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

// watch licensee users update
export function* watchLoadUsers() {
  yield takeEvery(licenseeActions.USERS.REQUEST, fetchUsers)
}

export function* watchLoadUser(action) {
  yield takeEvery(licenseeActions.GET_USER.REQUEST, fetchUser)
}

export function* watchCreateUser() {
  yield takeEvery(licenseeActions.CREATE_USER.REQUEST, addUser)
}

export function* watchUpdateUser() {
  yield takeEvery(licenseeActions.UPDATE_USER.REQUEST, editUser)
}

export function* watchUpdateUserSuccess() {
  yield takeEvery(licenseeActions.UPDATE_USER.SUCCESS, handleUsersUpdateSuccess)
}

export function* watchCreateUserSuccess() {
  yield takeEvery(licenseeActions.CREATE_USER.SUCCESS, handleUsersCreateSuccess)
}

export function* watchDeleteUser() {
  yield takeEvery(licenseeActions.DELETE_USER.REQUEST, removeUser)
}

export function* watchDeleteUserSuccess() {
  yield takeEvery(licenseeActions.DELETE_USER.SUCCESS, refreshUsers)
}

export function* watchLoadFilteredUsers() {
  yield takeEvery(licenseeActions.GET_FILTERED_USERS.REQUEST, fetchFilteredUsers)
}

export function* watchUpdateUserPassword() {
  yield takeEvery(licenseeActions.UPDATE_USER_PASSWORD.REQUEST, updateUserPassword)
}

export function* watchUpdateUserPasswordSuccess() {
  yield takeEvery(licenseeActions.UPDATE_USER_PASSWORD.SUCCESS, handlePasswordUpdateSusccess)
}
