import { takeEvery, select } from 'redux-saga/effects';
import * as licenseModelsActions from 'actions/LicenseModels';
import api from '../utils/api';
import { apiRequest } from './index';
import history from "../utils/history";
import { get } from "lodash";
import * as licenseeActions from "../actions/Licensee";

const getLicenseModels = apiRequest.bind(null, licenseModelsActions.getLicenseModels, api.getLicenseModels)
const getLicenseModel = apiRequest.bind(null, licenseModelsActions.getLicenseModel, api.getLicenseModel)
const createLicenseModel = apiRequest.bind(null, licenseModelsActions.createLicenseModel, api.createLicenseModel)
const updateLicenseModel = apiRequest.bind(null, licenseModelsActions.updateLicenseModel, api.updateLicenseModel)
const deleteLicenseModel = apiRequest.bind(null, licenseModelsActions.deleteLicenseModel, api.deleteLicenseModel)
const assignLicense = apiRequest.bind(null, licenseModelsActions.assignLicense, api.assignLicense)
const updateUserCredits = apiRequest.bind(null, licenseModelsActions.updateUserCredits, api.updateUserCredits)
const getLicenseUsers = apiRequest.bind(null, licenseModelsActions.getLicenseUsers, api.getLicenseUsers)
const updateUserLicense = apiRequest.bind(null, licenseModelsActions.updateUserLicense, api.updateUserLicense)

function* handleChangeLicenseModel() {
  yield history.push('/license-models');
  const arg = {}
  yield apiRequest.apply(null, [licenseModelsActions.getLicenseModels, api.getLicenseModels, arg])
}

function* handleAssignLicenseFailure(action) {
  if (action.payload.callback && action.payload.status === 409) {
    action.payload.callback(action.payload.message)
  }

  yield refreshUser()
}

function* refreshUser() {
  const state = yield select();
  const userId = get(state, 'Licensee.user.id');
  const arg = {
    id: userId
  }
  yield apiRequest.apply(null, [ licenseeActions.getUser, api.getUser, arg ])
}


/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/

export function* watchGetLicenseModelsRequest() {
  yield takeEvery(licenseModelsActions.GET_LICENSE_MODELS.REQUEST, getLicenseModels)
}

export function* watchGetLicenseModelRequest() {
  yield takeEvery(licenseModelsActions.GET_LICENSE_MODEL.REQUEST, getLicenseModel)
}

export function* watchCreateLicenseModelRequest() {
  yield takeEvery(licenseModelsActions.CREATE_LICENSE_MODEL.REQUEST, createLicenseModel)
}

export function* watchUpdateLicenseModelRequest() {
  yield takeEvery(licenseModelsActions.UPDATE_LICENSE_MODEL.REQUEST, updateLicenseModel)
}

export function* watchDeleteLicenseModelRequest() {
  yield takeEvery(licenseModelsActions.DELETE_LICENSE_MODEL.REQUEST, deleteLicenseModel)
}

export function* watchCreateLicenseModelSuccess() {
  yield takeEvery(licenseModelsActions.CREATE_LICENSE_MODEL.SUCCESS, handleChangeLicenseModel)
}

export function* watchUpdateLicenseModelSuccess() {
  yield takeEvery(licenseModelsActions.UPDATE_LICENSE_MODEL.SUCCESS, handleChangeLicenseModel)
}

export function* watchDeleteLicenseModelSuccess() {
  yield takeEvery(licenseModelsActions.DELETE_LICENSE_MODEL.SUCCESS, handleChangeLicenseModel)
}

export function* watchAssignLicenseRequest() {
  yield takeEvery(licenseModelsActions.ASSIGN_LICENSE.REQUEST, assignLicense)
}

export function* watchAssignLicenseFailure() {
  yield takeEvery(licenseModelsActions.ASSIGN_LICENSE.FAILURE, handleAssignLicenseFailure)
}

export function* watchAssignLicenseSuccess() {
  yield takeEvery(licenseModelsActions.ASSIGN_LICENSE.SUCCESS, refreshUser)
}

export function* watchUpdateUserCreditsRequest() {
  yield takeEvery(licenseModelsActions.UPDATE_USER_CREDITS.REQUEST, updateUserCredits)
}

export function* watchUpdateUserCreditsSuccess() {
  yield takeEvery(licenseModelsActions.UPDATE_USER_CREDITS.SUCCESS, refreshUser)
}

export function* watchGetLicenseUsersRequest() {
  yield takeEvery(licenseModelsActions.GET_LICENSE_USERS.REQUEST, getLicenseUsers)
}

export function* watchUpdateUserLicenseRequest() {
  yield takeEvery(licenseModelsActions.UPDATE_USER_LICENSE.REQUEST, updateUserLicense)
}

