export const LicenseeNav = [
  {
        label: 'My Customers',
        to: '/users',
        icon: 'pe-7s-users',
        id: 1
  },
  {
        label: 'Trainings List',
        to: '/trainings',
        icon: 'pe-7s-graph3',
        id: 2
  },
  {
      label: 'Studio Details',
      to: '/studio-details',
      icon: 'pe-7s-display1',
      id: 3
  },
  {
    label: 'Groups',
    to: '/groups',
    icon: 'pe-7s-users',
    id: 111
  },
  {
      label: 'Documents',
      to: '/documents',
      icon: 'pe-7s-albums',
      id: 4
  },
  {
      label: 'Calendar',
      to: '/calendar',
      icon: 'pe-7s-calculator',
      id: 5
  },
  {
    label: 'EMS usage reports',
    to: '/incident-logs',
    icon: 'pe-7s-graph1',
    id: 6
  },
  {
    label: 'Trash',
    to: '/trash',
    icon: 'pe-7s-trash',
    id: 7
  },

  // {
  //       label: 'Studio Calendar',
  //       to: '#/studio-calendar',
  //       icon: 'pe-7s-calculator'
  // },
  // {
  //       label: 'Start Training',
  //       to: '#/start-training',
  //       icon: 'pe-7s-gym'
  // }
]

export const AdminNav = [
  {
    label: 'Customers List',
    to: '/users',
    icon: 'pe-7s-users',
    id: 8
  },
  {
        label: 'Licensee List',
        to: '/licensee',
        icon: 'pe-7s-culture',
        id: 9
  },
  {
        label: 'Trainings List',
        to: '/trainings',
        icon: 'pe-7s-graph3',
        id: 10
  },
  {
        label: 'Media',
        to: "/media",
        icon: 'pe-7s-video',
        id: 11
  },
  {
    label: 'Groups',
    to: '/groups',
    icon: 'pe-7s-users',
    id: 12
  },
  {
    label: 'Membership Models',
    to: '/license-models',
    icon: 'pe-7s-note2',
    id: 13
  },
  {
       label: 'Studio Screen Details',
       to: '/studio-screen-details',
       icon: 'pe-7s-display2',
       id: 14
  },
  {
      label: 'Documents',
      to: '/documents',
      icon: 'pe-7s-albums',
      id: 15
  },
  {
    label: 'System health',
    to: '/broken-users',
    icon: 'pe-7s-server',
    id: 16
  },
  {
    label: 'App logs',
    to: '/app-logs',
    icon: 'pe-7s-clock',
    id: 17
  },
  {
    label: 'EMS usage reports',
    to: '/incident-logs',
    icon: 'pe-7s-graph1',
    id: 18
  },
  {
    label: 'Statistics',
    id: 221,
    icon: 'pe-7s-graph3',
    to: '/reports',
  },
  {
    label: 'Device Information',
    id: 222,
    icon: 'pe-7s-plugin',
    to: '/device-info/stats'
  },
  {
    label: 'Trash',
    to: '/trash',
    icon: 'pe-7s-trash',
    id: 19
  },
]

export const CustomerNav = [
  {
    label: 'My Training',
    to: '/my-training',
    icon: 'pe-7s-home',
    id: 20
  },
  {
    label: 'My Calendar',
    to: '/my-calendar',
    icon: 'pe-7s-calculator',
     id: 21
  },
  {
    label: 'My Diary',
    to: '/my-diary',
    icon: 'pe-7s-note',
    id: 22
  }
]
