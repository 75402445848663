import * as ActionTypes from '../actions/Trash';
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  documents: [],
  trainings: [],
  media: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_TRASH_DOCUMENTS.SUCCESS:
      return { ...state, documents: action.payload };
    case ActionTypes.GET_TRASH_TRAININGS.SUCCESS:
      return { ...state, trainings: action.payload };
    case ActionTypes.GET_TRASH_MEDIA.SUCCESS:
      return { ...state, media: action.payload };
    default:
      return state;
  }
}
