import {action, createRequestTypes} from "../utils/actions";
import {FAILURE, REQUEST, SUCCESS} from "../utils/constants";

export const GET_TRASH_DOCUMENTS = createRequestTypes('GET_TRASH_DOCUMENTS');
export const GET_TRASH_TRAININGS = createRequestTypes('GET_TRASH_TRAININGS');
export const GET_TRASH_MEDIA = createRequestTypes('GET_TRASH_MEDIA');

export const getTrashDocuments = {
  request: () => action(GET_TRASH_DOCUMENTS[REQUEST]),
  success: (payload) => action(GET_TRASH_DOCUMENTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRASH_DOCUMENTS[FAILURE], {payload}),
}

export const getTrashTrainings = {
  request: () => action(GET_TRASH_TRAININGS[REQUEST]),
  success: (payload) => action(GET_TRASH_TRAININGS[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRASH_TRAININGS[FAILURE], {payload}),
}

export const getTrashMedia = {
  request: () => action(GET_TRASH_MEDIA[REQUEST]),
  success: (payload) => action(GET_TRASH_MEDIA[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRASH_MEDIA[FAILURE], {payload}),
}

