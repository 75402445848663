import { takeEvery, put, select } from 'redux-saga/effects';
import * as adminActions from '../actions/Admin';
import api from '../utils/api';
import { apiRequest } from './index';
import history from '../utils/history';
import { get } from 'lodash';
import {Slide, toast} from "react-toastify";

const createLicenseeInfo = apiRequest.bind(null, adminActions.createLicensee, api.createLicensee);
const getAllLicensees = apiRequest.bind(null, adminActions.getLicensees, api.getLicensees);
const getLicenseeById = apiRequest.bind(null, adminActions.getLicensee, api.getLicensee);
const updateLicenseeById = apiRequest.bind(null, adminActions.updateLicensee, api.updateLicensee);
const deleteLicenseeById = apiRequest.bind(null, adminActions.deleteLicensee, api.deleteLicensee);
const updateLicenseePassword = apiRequest.bind(null, adminActions.updateLicenseePassword, api.updateLicenseePassword);
const getBrokenUsers = apiRequest.bind(null, adminActions.getBrokenUsers, api.getBrokenUsers);
const deleteBrokenUser = apiRequest.bind(null, adminActions.deleteBrokenUser, api.deleteBrokenUser);
const getEMSLogs = apiRequest.bind(null, adminActions.getEMSLogs, api.getEMSLogs);
const getAllCustomers = apiRequest.bind(null, adminActions.getAllCustomers, api.getAllCustomers);
const deleteEmsLogs = apiRequest.bind(null, adminActions.deleteEmsLogs, api.deleteEmsLogs);
const getIncidentReports = apiRequest.bind(null, adminActions.getIncidentReports, api.getIncidentReports);
const deleteIncidentReports = apiRequest.bind(null, adminActions.deleteIncidentReports, api.deleteIncidentReports);

function* handleCreateLicenseeInfo(action)  {
  const state = yield select();
  const allUsers = get(state, 'Admin.licensees', []);
  const pageSize = get(state, 'Admin.licenseePageSize', 20);
  const tableFilter = get(state, 'Admin.tableFilter', '');
  const lastPage = tableFilter ? 0 : Math.floor((allUsers.length + 1) / pageSize) -1;
  yield put({ type: adminActions.SET_LICENSEE_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push('/licensee');
}

function* handlePasswordUpdateSuccess() {
  yield toast('Success', {
    transition: Slide,
    closeButton: true,
    autoClose: false,
    position: 'bottom-center',
    type: 'success'
  });
}


function* handleUpdateLicensee(action) {
  // const state = yield select();
  // const allUsers = get(state, 'Admin.licensees', []);
  // const pageSize = get(state, 'Admin.licenseePageSize', 20);
  // const lastPage = Math.floor((allUsers.length + 1) / pageSize) -1 ;
  // yield put({ type: adminActions.SET_LICENSEE_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push('/licensee');
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetLicensees() {
  yield takeEvery(adminActions.GET_LICENSEES.REQUEST, getAllLicensees)
}

export function* watchCreateLicensee() {
  yield takeEvery(adminActions.CREATE_LICENSEE.REQUEST, createLicenseeInfo)
}

export function* watchCreateLicenseeSuccess() {
  yield takeEvery(adminActions.CREATE_LICENSEE.SUCCESS, handleCreateLicenseeInfo)
}

export function* watchGetLicensee() {
  yield takeEvery(adminActions.GET_LICENSEE.REQUEST, getLicenseeById)
}

export function* watchUpdateLicensee() {
  yield takeEvery(adminActions.UPDATE_LICENSEE.REQUEST, updateLicenseeById)
}

export function* watchUpdateLicenseeSuccess() {
  yield takeEvery(adminActions.UPDATE_LICENSEE.SUCCESS, handleUpdateLicensee)
}

export function* watchDeleteLicensee() {
  yield takeEvery(adminActions.DELETE_LICENSEE.REQUEST, deleteLicenseeById)
}

export function* watchDeleteLicenseeSuccess() {
  yield takeEvery(adminActions.DELETE_LICENSEE.SUCCESS, getAllLicensees)
}

export function* watchUpdateLicenseePassword() {
  yield takeEvery(adminActions.UPDATE_LICENSEE_PASSWORD.REQUEST, updateLicenseePassword)
}

export function* watchUpdateLicenseePasswordSuccess() {
  yield takeEvery(adminActions.UPDATE_LICENSEE_PASSWORD.SUCCESS, handlePasswordUpdateSuccess)
}

export function* watchGetBrokenUsersRequest() {
  yield takeEvery(adminActions.GET_BROKEN_USERS.REQUEST, getBrokenUsers)
}

export function* watchDeleteBrokenUserRequest() {
  yield takeEvery(adminActions.DELETE_BROKEN_USER.REQUEST, deleteBrokenUser)
}

export function* watchDeleteBrokenUserSuccess() {
  yield takeEvery(adminActions.DELETE_BROKEN_USER.SUCCESS, getBrokenUsers)
}

export function* watchGetEMSLogsRequest() {
  yield takeEvery(adminActions.GET_EMS_LOGS.REQUEST, getEMSLogs)
}

export function* watchGetIncidentReportsRequest() {
  yield takeEvery(adminActions.GET_INCIDENT_REPORTS.REQUEST, getIncidentReports)
}

export function* watchDeleteIncidentReportsRequest() {
  yield takeEvery(adminActions.DELETE_INCIDENT_REPORTS.REQUEST, deleteIncidentReports)
}

export function* watchDeleteIncidentReportsSuccess() {
  yield takeEvery(adminActions.DELETE_INCIDENT_REPORTS.SUCCESS, getIncidentReports)
}

export function* watchGetAllCustomersRequest() {
  yield takeEvery(adminActions.GET_ALL_CUSTOMERS.REQUEST, getAllCustomers)
}

export function* watchDeleteEmsLogsRequest() {
  yield takeEvery(adminActions.DELETE_EMS_LOGS.REQUEST, deleteEmsLogs)
}

export function* watchDeleteEmsLogsSuccess() {
  yield takeEvery(adminActions.DELETE_EMS_LOGS.SUCCESS, getEMSLogs)
}
