import * as ActionTypes from '../actions/Media';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  mediaList: [],
  media: null,
  mediaInitialPage: 0,
  mediaPageSize: 100,
  mediaUrl: '',
  tableFilter: ''
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.SET_MEDIA_TABLE_FILTER:
      return { ...state, tableFilter: action.payload }
    case ActionTypes.CLEAR_MEDIA:
      return { ...state, media: null }
    case ActionTypes.UPLOAD_MEDIA.SUCCESS:
      return { ...state, mediaUrl: get(action.payload, 'key', ''), mediaDuration: get(action.payload, 'duration', '') }
    case ActionTypes.SET_MEDIA_PAGE:
      return { ...state, mediaInitialPage: action.payload }
    case ActionTypes.SET_MEDIA_PAGE_SIZE:
      return { ...state, mediaPageSize: action.payload }
    case ActionTypes.GET_MEDIA_LIST.SUCCESS:
      return {
        ...state,
        mediaUrl: '',
        mediaList: action.payload
       }
    case ActionTypes.GET_MEDIA.SUCCESS:
      return { ...state, media: action.payload }
    default:
      return state;
  }
}
