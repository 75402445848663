import React, {Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {
    faCalendarPlus,
    faVideo
} from '@fortawesome/free-solid-svg-icons';

import {Button} from 'reactstrap';

class AppFooter extends React.Component {
    render() {
      const {userRole} = this.props;

        return (
            <Fragment>
                <div className="app-footer">
                    <div className="app-footer__inner">
                        <div className="app-footer-left">
                        {
                          userRole === 'user' &&
                          (
                            <Link to="/training-calendar">
                              <Button> <FontAwesomeIcon icon={faCalendarPlus} /> Add Studio Training</Button>
                            </Link>
                          )
                        }
                        </div>
                        <div className="app-footer-right">
                        {
                          userRole === 'user' &&
                          (
                            <Link to="/training-selection">
                              <Button> <FontAwesomeIcon icon={faVideo} /> Add Video Training</Button>
                            </Link>
                          )
                        }
                        </div>
                    </div>
                </div>
            </Fragment>
        )}
}

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole
})

export default connect(mapStateToProps, null)(AppFooter);
