import { takeEvery, put, select } from 'redux-saga/effects';
import * as mediaActions from '../actions/Media';
import api from 'utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';
import history from 'utils/history';

const getMediaList = apiRequest.bind(null, mediaActions.getMediaList, api.getMediaList);
const getMedia = apiRequest.bind(null, mediaActions.getMedia, api.getMedia);
const updateMedia = apiRequest.bind(null, mediaActions.updateMedia, api.updateMedia);
const deleteMedia = apiRequest.bind(null, mediaActions.deleteMedia, api.deleteMedia);
const createMedia = apiRequest.bind(null, mediaActions.createMedia, api.createMedia);
const uploadMedia = apiRequest.bind(null, mediaActions.uploadMedia, api.uploadMedia);
const copyMedia = apiRequest.bind(null, mediaActions.copyMedia, api.copyMedia);

function* handleCreateMediaSuccess() {
  const state = yield select();
  const allData = get(state, 'Media.mediaList', []);
  const pageSize = get(state, 'Media.mediaPageSize', 20);
  const tableFilter = get(state, 'Media.tableFilter', '');
  const lastPage = tableFilter ? 0 : Math.ceil((allData.length + 1) / pageSize) -1;
  yield put({ type: mediaActions.SET_MEDIA_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push('/media');
}

function* handleUpdateMediaSuccess() {
  const state = yield select();
  const currMedia = get(state, 'Media.media');
  const allData = get(state, 'Media.mediaList', []);
  const pageSize = get(state, 'Media.mediaPageSize', 20);
  if (currMedia) {
    const currMediaIndex = allData.findIndex(el => (el.id === currMedia.id));
    const page = Math.ceil((currMediaIndex + 1) / pageSize) -1 ;
    yield put({ type: mediaActions.CLEAR_MEDIA });
    yield put({ type: mediaActions.SET_MEDIA_PAGE, payload: page });
  }
  yield history.push('/media');
}

function* handleCopyMediaSuccess() {
  yield put({ type: mediaActions.GET_MEDIA_LIST.REQUEST });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLoadMedia() {
  yield takeEvery(mediaActions.GET_MEDIA_LIST.REQUEST, getMediaList)
}

export function* watchGetMedia() {
  yield takeEvery(mediaActions.GET_MEDIA.REQUEST, getMedia)
}

export function* watchCopyMediaRequest() {
  yield takeEvery(mediaActions.COPY_MEDIA.REQUEST, copyMedia)
}

export function* watchCopyMediaSuccess() {
  yield takeEvery(mediaActions.COPY_MEDIA.SUCCESS, handleCopyMediaSuccess)
}

export function* watchUploadMedia() {
  yield takeEvery(mediaActions.UPLOAD_MEDIA.REQUEST, uploadMedia)
}

export function* watchCreateMedia() {
  yield takeEvery(mediaActions.CREATE_MEDIA.REQUEST, createMedia)
}

export function* watchUpdateMedia() {
  yield takeEvery(mediaActions.UPDATE_MEDIA.REQUEST, updateMedia)
}

export function* watchDeleteMedia() {
  yield takeEvery(mediaActions.DELETE_MEDIA.REQUEST, deleteMedia)
}

export function* watchDeleteMediaSuccess() {
  yield takeEvery(mediaActions.DELETE_MEDIA.SUCCESS, getMediaList)
}

export function* watchCreateMediaSuccess() {
  yield takeEvery(mediaActions.CREATE_MEDIA.SUCCESS, handleCreateMediaSuccess)
}

export function* watchUpdateMediaSuccess() {
  yield takeEvery(mediaActions.UPDATE_MEDIA.SUCCESS, handleUpdateMediaSuccess)
}
