import * as ActionTypes from '../actions/DeviceInfo';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  deviceInfoStats: [],
  deviceInfoWhiteList: [],
  deviceInfoBlackList: [],
  deviceInfoStatsDetails: [],
  tableFilterStatistics: '',
  tableFilterWhiteList: '',
  tableFilterBlackList: '',
  tableFilterStatisticsDetails: '',
  deviceInfoStatsPage: 0,
  deviceInfoStatsPageSize: 100,
  deviceInfoStatsPages: 0,
  deviceInfoStatsDetailsPage: 0,
  deviceInfoStatsDetailsPageSize: 100,
  deviceInfoStatsDetailsPages: 0,
  deviceInfoWhiteListPage: 0,
  deviceInfoWhiteListPageSize: 100,
  deviceInfoWhiteListPages: 0,
  deviceInfoBlackListPage: 0,
  deviceInfoBlackListPageSize: 100,
  deviceInfoBlackListPages: 0,
  blacklistInfoDetails: null,
  blacklistTotalElements: 0,
  whitelistTotalElements: 0,
  statsTotalElements: 0,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_DEVICE_INFO_STATS.SUCCESS:
      return {
        ...state,
        deviceInfoStats: get(action.payload, 'content'),
        deviceInfoStatsPage: get(action, 'payload.currentPage', 0),
        deviceInfoStatsPages: get(action, 'payload.totalPages', 0),
        statsTotalElements: get(action, 'payload.totalElements', 0),
      };
    case ActionTypes.GET_DEVICE_INFO_STATS_DETAILS.SUCCESS:
      return {
        ...state,
        deviceInfoStatsDetails: get(action.payload, 'content'),
        deviceInfoStatsDetailsPage: get(action, 'payload.currentPage', 0),
        deviceInfoStatsDetailsPages: get(action, 'payload.totalPages', 0),
        statsTotalElements: get(action, 'payload.totalElements', 0),
      };
    case ActionTypes.GET_DEVICE_INFO_WHITELIST.SUCCESS:
      return {
        ...state,
        deviceInfoWhiteList: get(action.payload, 'content'),
        deviceInfoWhiteListPage: get(action, 'payload.currentPage', 0),
        deviceInfoWhiteListPages: get(action, 'payload.totalPages', 0),
        whitelistTotalElements: get(action, 'payload.totalElements', 0),
      };
    case ActionTypes.GET_DEVICE_INFO_BLACKLIST.SUCCESS:
      return {
        ...state,
        deviceInfoBlackList: get(action.payload, 'content'),
        deviceInfoBlackListPage: get(action, 'payload.currentPage', 0),
        deviceInfoBlackListPages: get(action, 'payload.totalPages', 0),
        blacklistTotalElements: get(action, 'payload.totalElements', 0),
      };
    case ActionTypes.GET_BLACKLIST_DEVICE_INFO.SUCCESS:
      return {
        ...state,
        blacklistInfoDetails: action.payload
      }
    case ActionTypes.SET_DEVICE_INFO_TABLE_FILTER:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }
    case ActionTypes.SET_DEVICE_INFO_PAGE:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }
    case ActionTypes.SET_DEVICE_INFO_PAGE_SIZE:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }
    default:
      return state;
  }
}
