import * as ActionTypes from '../actions/Reports';
import * as AuthActionTypes from '../actions/Auth';
import {get} from 'lodash';

const initialState = {
  reports: [],
  reportsPage: 0,
  reportsPageSize: 100,
  reportsPages: 0,
  totalElements: 0
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_REPORTS.SUCCESS:
      return {
        ...state,
        reports: get(action.payload, 'content'),
        reportsPage: get(action, 'payload.currentPage', 0),
        reportsPages: get(action, 'payload.totalPages', 0),
        totalElements: get(action, 'payload.totalElements', 0),
       }
    case ActionTypes.SET_REPORTS_PAGE_SIZE:
      return { ...state, reportsPageSize: action.payload };
    default:
      return state;
  }
}
