import * as ActionTypes from '../actions/Admin';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  licensees: [],
  licensee: null,
  licenseeInitialPage: 0,
  licenseePageSize: 100,
  licenseeTotalElements: 0,
  tableFilter: '',
  brokenUsers: [],
  emsLogs: [],
  emsLogsPage: 0,
  emsLogsPages: 0,
  emsLogsInitialPage: 0,
  emsLogsPageSize: 100,
  incidentReports: [],
  incidentReportsPage: 0,
  incidentReportsPages: 0,
  incidentReportsTotalElements: 0,
  incidentReportsInitialPage: 0,
  incidentReportsPageSize: 100,
  licenseePages: 0,
  licenseePage: 0,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_BROKEN_USERS.SUCCESS:
      return { ...state, brokenUsers: action.payload }
    case ActionTypes.GET_INCIDENT_REPORTS.SUCCESS:
      return {
        ...state,
        incidentReports: action.payload.content,
        incidentReportsPage: action.payload.currentPage,
        incidentReportsPages: action.payload.totalPages,
        incidentReportsTotalElements: action.payload.totalElements,
      }
    case ActionTypes.SET_INCIDENT_REPORTS_PAGE:
      return { ...state, incidentReportsPage: action.payload }
    case ActionTypes.SET_INCIDENT_REPORTS_PAGE_SIZE:
      return { ...state, incidentReportsPageSize: action.payload }
    case ActionTypes.GET_EMS_LOGS.SUCCESS:
      return { ...state,
        emsLogs: action.payload.content,
        emsLogsPage: action.payload.currentPage,
        emsLogsPages: action.payload.totalPages,
        emsLogsTotalElements: action.payload.totalElements,
      }
    case ActionTypes.SET_EMS_LOGS_PAGE:
      return { ...state, emsLogsPage: action.payload }
    case ActionTypes.SET_EMS_LOGS_PAGE_SIZE:
      return { ...state, emsLogsPageSize: action.payload }
    case ActionTypes.SET_LICENSEE_TABLE_FILTER:
      return { ...state, tableFilter: action.payload }
    case ActionTypes.SET_LICENSEE_PAGE:
      return { ...state, licenseeInitialPage: action.payload }
    case ActionTypes.SET_LICENSEE_PAGE_SIZE:
      return { ...state, licenseePageSize: action.payload }
    case ActionTypes.GET_ALL_CUSTOMERS.SUCCESS:
      return {
        ...state,
        customers: get(action.payload, 'content', []),
        customersPage: get(action, 'payload.currentPage', 0),
        customersPages: get(action, 'payload.totalPages', 0),

      };
    case ActionTypes.GET_LICENSEES.SUCCESS:
      return {
        ...state,
        licensees: get(action.payload, 'content'),
        licenseePage: get(action, 'payload.currentPage', 0),
        licenseePages: get(action, 'payload.totalPages', 0),
        licenseeTotalElements: get(action, 'payload.totalElements', 0)
      };
    case ActionTypes.GET_LICENSEE.SUCCESS:
      return { ...state, licensee: action.payload }
    default:
      return state;
  }
}
