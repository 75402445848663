import { fork, all, call, put } from 'redux-saga/effects';
import { checkTokenExpiration, checkRefreshTokenExpiration } from '../utils/token';
import api from '../utils/api';

import {
  watchCreateLicensee,
  watchGetLicensees,
  watchCreateLicenseeSuccess,
  watchGetLicensee,
  watchUpdateLicensee,
  watchUpdateLicenseePassword,
  watchUpdateLicenseePasswordSuccess,
  watchUpdateLicenseeSuccess,
  watchDeleteLicensee,
  watchDeleteLicenseeSuccess,
  watchGetBrokenUsersRequest,
  watchDeleteBrokenUserRequest,
  watchDeleteBrokenUserSuccess,
  watchGetEMSLogsRequest,
  watchGetAllCustomersRequest,
  watchDeleteEmsLogsRequest,
  watchDeleteEmsLogsSuccess,
  watchGetIncidentReportsRequest,
  watchDeleteIncidentReportsRequest,
  watchDeleteIncidentReportsSuccess,
} from './Admin';

import {
  watchLoadUsers,
  watchLoadUser,
  watchCreateUser,
  watchUpdateUser,
  watchDeleteUser,
  watchDeleteUserSuccess,
  watchLoadFilteredUsers,
  watchUpdateUserSuccess,
  watchCreateUserSuccess,
  watchUpdateUserPassword,
  watchUpdateUserPasswordSuccess,
} from './Licensee';

import {
  watchLoadClubs,
  watchLoadLicenseeClubs,
} from './Club';

import {
  watchLoadDeviceInfoStats,
  watchLoadDeviceInfoWhiteList,
  watchLoadDeviceInfoBlackList,
  watchDeleteDeviceInfoSuccess,
  watchLoadDeviceInfoStatsDetails,
  watchDeleteDeviceInfoStatsSuccess,
  watchDeleteDeviceInfoStatsRequest,
  watchDeleteDeviceInfoRequest,
  watchUpdateBlacklistInfoRequest,
  watchGetBlacklistDetailsRequest, 
} from './DeviceInfo';

import {
  watchLoadMemberships,
  watchUpdateMembership
} from './Membership';

import {
  watchLoadMedia,
  watchGetMedia,
  watchCreateMedia,
  watchUpdateMedia,
  watchDeleteMedia,
  watchUploadMedia,
  watchDeleteMediaSuccess,
  watchCreateMediaSuccess,
  watchUpdateMediaSuccess,
  watchCopyMediaRequest,
  watchCopyMediaSuccess
} from './Media';

import {
  watchLoadTrainings,
  watchLoadTraining,
  watchDeleteTraining,
  watchLoadTrainingCategories,
  watchDeleteTrainingSuccess,
  watchCreateTraining,
  watchUpdateTraining,
  watchCreateTrainingSuccess,
  watchUpdateTrainingSuccess,
  watchConvertTrainingVideo,
  watchPushTrainingVideo,
  watchPushScoreCard,
  watchPushVideoCommand,
  watchPushVideoCommandSuccess,
  watchPushTrainingVideoSuccess,
  watchUploadTeaserVideo,
  watchGetTrainingEventsByPrincipal,
  watchSetTrainingStatusRequest,
  watchSetTrainingStatusSuccess,
  watchCopyTrainingRequest,
  watchCopyTrainingSuccess
} from './Training';

import {
  watchGetToken,
  watchLoginUser,
  watchLoginSuccess,
  watchLogout,
  watchRefreshToken,
  watchRefreshTokenSuccess,
  watchRefreshTokenFailure,
  handleInvalidToken,
  watchGetTokenFailure,
  watchSetUserRole
} from './Auth';

import {
  watchLoadDefaultStudioNews,
  watchLoadLicenseeStudioNews,
  watchCreateDefaultStudioNews,
  watchCreateLicenseeStudioNews,
  watchUpdateDefaultStudioNews,
  watchUpdateLicenseeStudioNews,
  watchUploadDefaultStudioImage,
  watchUploadDefaultStudioVideo,
  watchUploadLicenseeStudioImage,
  watchUploadLicenseeStudioVideo,
  watchLicenseeNewsCreateSuccess,
  watchLicenseeNewsUpdateSuccess,
  watchDefaultNewsCreateSuccess,
  watchDefaultNewsUpdateSuccess,
  watchUploadStudioHeaderImageVideo
} from './Studio';

import {
  watchGetComments,
  watchCreateComment,
  watchUpdateComment,
  watchDeleteComment,
  watchAddCommentSuccess,
  watchUpdateCommentSuccess,
  watchDeleteCommentSuccess
} from './Comments';

import {
  watchGetLicenseModelsRequest,
  watchGetLicenseModelRequest,
  watchCreateLicenseModelRequest,
  watchUpdateLicenseModelRequest,
  watchDeleteLicenseModelRequest,
  watchCreateLicenseModelSuccess,
  watchUpdateLicenseModelSuccess,
  watchDeleteLicenseModelSuccess,
  watchAssignLicenseRequest,
  watchAssignLicenseSuccess,
  watchUpdateUserCreditsRequest,
  watchUpdateUserCreditsSuccess,
  watchUpdateUserLicenseRequest,
  watchGetLicenseUsersRequest,
} from './LicenseModels';

import {
  watchGetDocumentsRequest,
  watchGetSearchedDocumentsRequest,
  watchGetSharedDocumentsRequest,
  watchGetDocumentRequest,
  watchGetUserDocumentsRequest,
  watchCreateDocumentRequest,
  watchUpdateDocumentRequest,
  watchDeleteDocumentRequest,
  watchCreateDocumentSuccess,
  watchUpdateDocumentSuccess,
  watchGetDocumentByVersionRequest,
  watchGetFilteredDocumentsRequest,
  watchgetLogsRequest
} from "./Documents";

import {
  watchGetEventRequest,
  watchGetEventsRequest,
  watchGetEventsByRangeRequest,
  watchCreateEventRequest,
  watchUpdateEventRequest,
  watchDeleteEventRequest,
  watchCreateEventSuccess,
  watchUpdateEventSuccess,
  watchDeleteEventSuccess
} from "./Calendar";

import {
  watchGetGroupsRequest,
  watchGetGroupRequest,
  watchCreateGroupRequest,
  watchUpdateGroupRequest,
  watchDeleteGroupRequest,
  watchCreateGroupSuccess,
  watchUpdateGroupSuccess,
  watchDeleteGroupSuccess,
  watchGetGroupUsersRequest,
  watchGetGroupTrainingsRequest,
  watchAddTrainingsToGroupRequest,
  watchDeleteTrainingsFromGroupRequest,
  watchGetTrainingGroupsRequest,
  watchDeleteTrainingsFromGroupSuccess,
  watchAddTrainingsToGroupSuccess,
  watchAddGroupsToTrainingRequest,
  watchAddGroupsToTrainingSuccess,
  watchDeleteGroupsFromTrainingSuccess,
  watchDeleteGroupsFromTrainingRequest
} from "./Groups";

import {
  watchGetTrashDocumentsRequest,
  watchGetTrashTrainingsRequest,
  watchGetTrashMediaRequest,
} from "./Trash";

import {
  watchGetReports
} from "./Reports";

// resuable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
export function* apiRequest(entity, apiFn, action) {
  const { id, data } = action;
  const {response, error} = typeof id !== 'undefined' ? yield call(apiFn, id, data) : yield call(apiFn, data)
  if(!error)
    yield put( entity.success(response) )
  else
    yield put( entity.failure(error) )
}

export function* secureApiRequest(entity, apiFn, action) {
  let token = checkTokenExpiration();

  if (!token || token === 'expired') {
    if (token !== 'expired') yield put({ type: 'LOGOUT_REQUEST' });
    else {
      const refreshToken = checkRefreshTokenExpiration();
      if (!refreshToken || refreshToken === 'expired') {
        yield call(handleInvalidToken);
      } else {
        const {response, error} = yield call(api.refreshToken, refreshToken);
        if (error) yield call(handleInvalidToken);
        token = response.access_token;

        window.localStorage.setItem('BC_TOKEN', token);
        window.localStorage.setItem('BC_REFRESH_TOKEN', response.refresh_token);
      }
    }
  }

  if (!token || token === 'expired') return;
  const { id, data } = action;
  const {response, error} = id
    ? data
      ? yield call(apiFn, id, data, token)
      : yield call(apiFn, id, token)
    : data
        ? yield call(apiFn, data, token)
        : yield call(apiFn, token)
  if(!error)
    yield put( entity.success(response) )
  else
    yield put( entity.failure(error) )
}

export default function* root() {
  yield all([
    fork(watchLoadUsers),
    fork(watchLoadUser),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchLoadClubs),
    fork(watchLoadLicenseeClubs),
    fork(watchDeleteUserSuccess),
    fork(watchGetToken),
    fork(watchLoginUser),
    fork(watchLoginSuccess),
    fork(watchLogout),
    fork(watchCreateLicensee),
    fork(watchCreateLicenseeSuccess),
    fork(watchGetLicensees),
    fork(watchGetLicensee),
    fork(watchUpdateLicensee),
    fork(watchUpdateLicenseeSuccess),
    fork(watchDeleteLicensee),
    fork(watchDeleteLicenseeSuccess),
    fork(watchRefreshToken),
    fork(watchRefreshTokenSuccess),
    fork(watchGetTokenFailure),
    fork(watchRefreshTokenFailure),
    fork(watchLoadMemberships),
    fork(watchLoadTrainings),
    fork(watchLoadTraining),
    fork(watchDeleteTraining),
    fork(watchLoadTrainingCategories),
    fork(watchLoadFilteredUsers),
    fork(watchCreateTraining),
    fork(watchUpdateTraining),
    fork(watchDeleteTrainingSuccess),
    fork(watchUpdateUserSuccess),
    fork(watchCreateUserSuccess),
    fork(watchCreateTrainingSuccess),
    fork(watchUpdateTrainingSuccess),
    fork(watchSetUserRole),
    fork(watchLoadMedia),
    fork(watchGetMedia),
    fork(watchCreateMedia),
    fork(watchUpdateMedia),
    fork(watchDeleteMedia),
    fork(watchUploadMedia),
    fork(watchDeleteMediaSuccess),
    fork(watchCreateMediaSuccess),
    fork(watchUpdateMediaSuccess),
    fork(watchLoadDefaultStudioNews),
    fork(watchLoadLicenseeStudioNews),
    fork(watchCreateDefaultStudioNews),
    fork(watchCreateLicenseeStudioNews),
    fork(watchUpdateDefaultStudioNews),
    fork(watchUpdateLicenseeStudioNews),
    fork(watchConvertTrainingVideo),
    fork(watchUploadDefaultStudioImage),
    fork(watchUploadDefaultStudioVideo),
    fork(watchUploadLicenseeStudioImage),
    fork(watchUploadLicenseeStudioVideo),
    fork(watchLicenseeNewsCreateSuccess),
    fork(watchLicenseeNewsUpdateSuccess),
    fork(watchDefaultNewsCreateSuccess),
    fork(watchDefaultNewsUpdateSuccess),
    fork(watchPushTrainingVideo),
    fork(watchPushScoreCard),
    fork(watchPushVideoCommand),
    fork(watchPushVideoCommandSuccess),
    fork(watchPushTrainingVideoSuccess),
    fork(watchGetComments),
    fork(watchCreateComment),
    fork(watchUpdateComment),
    fork(watchDeleteComment),
    fork(watchAddCommentSuccess),
    fork(watchUpdateCommentSuccess),
    fork(watchDeleteCommentSuccess),
    fork(watchUpdateLicenseePassword),
    fork(watchUpdateLicenseePasswordSuccess),
    fork(watchUpdateUserPassword),
    fork(watchUpdateUserPasswordSuccess),
    fork(watchUpdateMembership),
    fork(watchUploadTeaserVideo),
    fork(watchGetDocumentsRequest),
    fork(watchGetUserDocumentsRequest),
    fork(watchGetSharedDocumentsRequest),
    fork(watchGetFilteredDocumentsRequest),
    fork(watchGetDocumentRequest),
    fork(watchGetSearchedDocumentsRequest),
    fork(watchCreateDocumentRequest),
    fork(watchUpdateDocumentRequest),
    fork(watchDeleteDocumentRequest),
    fork(watchgetLogsRequest),
    fork(watchCreateDocumentSuccess),
    fork(watchUpdateDocumentSuccess),
    fork(watchGetDocumentByVersionRequest),
    fork(watchGetEventRequest),
    fork(watchGetEventsRequest),
    fork(watchGetEventsByRangeRequest),
    fork(watchCreateEventRequest),
    fork(watchUpdateEventRequest),
    fork(watchDeleteEventRequest),
    fork(watchCreateEventSuccess),
    fork(watchUpdateEventSuccess),
    fork(watchDeleteEventSuccess),
    fork(watchGetTrainingEventsByPrincipal),
    fork(watchSetTrainingStatusRequest),
    fork(watchSetTrainingStatusSuccess),
    fork(watchCopyMediaRequest),
    fork(watchGetTrashDocumentsRequest),
    fork(watchGetTrashTrainingsRequest),
    fork(watchGetTrashMediaRequest),
    fork(watchCopyMediaSuccess),
    fork(watchCopyTrainingRequest),
    fork(watchCopyTrainingSuccess),
    fork(watchGetBrokenUsersRequest),
    fork(watchDeleteBrokenUserRequest),
    fork(watchDeleteBrokenUserSuccess),
    fork(watchGetEMSLogsRequest),
    fork(watchGetLicenseModelsRequest),
    fork(watchGetLicenseModelRequest),
    fork(watchCreateLicenseModelRequest),
    fork(watchUpdateLicenseModelRequest),
    fork(watchDeleteLicenseModelRequest),
    fork(watchCreateLicenseModelSuccess),
    fork(watchUpdateLicenseModelSuccess),
    fork(watchDeleteLicenseModelSuccess),
    fork(watchAssignLicenseRequest),
    fork(watchAssignLicenseSuccess),
    fork(watchUpdateUserCreditsRequest),
    fork(watchUpdateUserCreditsSuccess),
    fork(watchUpdateUserLicenseRequest),
    fork(watchGetLicenseUsersRequest),
    fork(watchGetGroupsRequest),
    fork(watchGetGroupRequest),
    fork(watchCreateGroupRequest),
    fork(watchUpdateGroupRequest),
    fork(watchDeleteGroupRequest),
    fork(watchCreateGroupSuccess),
    fork(watchUpdateGroupSuccess),
    fork(watchDeleteGroupSuccess),
    fork(watchGetGroupTrainingsRequest),
    fork(watchAddTrainingsToGroupRequest),
    fork(watchDeleteTrainingsFromGroupRequest),
    fork(watchGetGroupUsersRequest),
    fork(watchGetTrainingGroupsRequest),
    fork(watchDeleteTrainingsFromGroupSuccess),
    fork(watchAddTrainingsToGroupSuccess),
    fork(watchGetAllCustomersRequest),
    fork(watchAddGroupsToTrainingRequest),
    fork(watchDeleteGroupsFromTrainingRequest),
    fork(watchAddTrainingsToGroupSuccess),
    fork(watchDeleteGroupsFromTrainingSuccess),
    fork(watchAddGroupsToTrainingSuccess),
    fork(watchUploadStudioHeaderImageVideo),
    fork(watchDeleteEmsLogsRequest),
    fork(watchDeleteEmsLogsSuccess),
    fork(watchGetIncidentReportsRequest),
    fork(watchDeleteIncidentReportsRequest),
    fork(watchDeleteIncidentReportsSuccess),
    fork(watchGetReports),
    fork(watchLoadDeviceInfoStats),
    fork(watchLoadDeviceInfoWhiteList),
    fork(watchLoadDeviceInfoBlackList),
    fork(watchDeleteDeviceInfoSuccess),
    fork(watchLoadDeviceInfoStatsDetails),
    fork(watchDeleteDeviceInfoStatsSuccess),
    fork(watchDeleteDeviceInfoRequest),
    fork(watchDeleteDeviceInfoStatsRequest),
    fork(watchUpdateBlacklistInfoRequest),
    fork(watchGetBlacklistDetailsRequest),
  ])
}
