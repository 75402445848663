import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_LICENSE_MODELS = createRequestTypes('GET_LICENSE_MODELS');
export const GET_LICENSE_MODEL = createRequestTypes('GET_LICENSE_MODEL');
export const CREATE_LICENSE_MODEL = createRequestTypes('CREATE_LICENSE_MODEL');
export const UPDATE_LICENSE_MODEL = createRequestTypes('UPDATE_LICENSE_MODEL');
export const DELETE_LICENSE_MODEL = createRequestTypes('DELETE_LICENSE_MODEL');
export const ASSIGN_LICENSE = createRequestTypes('ASSIGN_LICENSE');
export const UPDATE_USER_CREDITS = createRequestTypes('UPDATE_USER_CREDITS');
export const GET_LICENSE_USERS = createRequestTypes('GET_LICENSE_USERS');
export const UPDATE_USER_LICENSE = createRequestTypes('UPDATE_USER_LICENSE');

export const getLicenseModels = {
  request: () => action(GET_LICENSE_MODELS[REQUEST], {}),
  success: (payload) => action(GET_LICENSE_MODELS[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSE_MODELS[FAILURE], {payload})
}

export const getLicenseModel = {
  request: (id) => action(GET_LICENSE_MODEL[REQUEST], {id}),
  success: (payload) => action(GET_LICENSE_MODEL[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSE_MODEL[FAILURE], {payload})
}

export const createLicenseModel = {
  request: (data) => action(CREATE_LICENSE_MODEL[REQUEST], {data}),
  success: (payload) => action(CREATE_LICENSE_MODEL[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_LICENSE_MODEL[FAILURE], {payload})
}

export const updateLicenseModel = {
  request: (id) => action(UPDATE_LICENSE_MODEL[REQUEST], {id}),
  success: (payload) => action(UPDATE_LICENSE_MODEL[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_LICENSE_MODEL[FAILURE], {payload})
}

export const deleteLicenseModel = {
  request: (id) => action(DELETE_LICENSE_MODEL[REQUEST], {id}),
  success: (payload) => action(DELETE_LICENSE_MODEL[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_LICENSE_MODEL[FAILURE], {payload})
}

export const assignLicense = {
  request: (data) => action(ASSIGN_LICENSE[REQUEST], {data}),
  success: (payload) => action(ASSIGN_LICENSE[SUCCESS], {payload}),
  failure: (payload) => action(ASSIGN_LICENSE[FAILURE], {payload})
}

export const updateUserCredits = {
  request: (data) => action(UPDATE_USER_CREDITS[REQUEST], {data}),
  success: (payload) => action(UPDATE_USER_CREDITS[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_USER_CREDITS[FAILURE], {payload})
}

export const getLicenseUsers = {
  request: (data) => action(GET_LICENSE_USERS[REQUEST], {data}),
  success: (payload) => action(GET_LICENSE_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSE_USERS[FAILURE], {payload})
}

export const updateUserLicense = {
  request: (data) => action(UPDATE_USER_LICENSE[REQUEST], {data}),
  success: (payload) => action(UPDATE_USER_LICENSE[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_USER_LICENSE[FAILURE], {payload})
}