import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppMain from '../AppMain';

const Main = ({ darkTheme }) => {
  return (
      <ResizeDetector
          handleWidth
          render={({ width }) => (
              <div className={cx(
                  "app-container app-theme-gray",
                  {'dark-theme': darkTheme}
              )}>
                  <AppMain/>
              </div>
          )}
      />
  )
}

const mapStateToProps = state => ({
    darkTheme: state.ThemeOptions.darkTheme

});

export default withRouter(connect(mapStateToProps)(Main));
