import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CLUBS = createRequestTypes('GET_CLUBS');
export const GET_LICENSEE_CLUBS = createRequestTypes('GET_LICENSEE_CLUBS');

export const getClubs = {
  request: () => action(GET_CLUBS[REQUEST]),
  success: (payload) => action(GET_CLUBS[SUCCESS], {payload}),
  failure: (payload) => action(GET_CLUBS[FAILURE], {payload}),
}

export const getLicenseeClubs = {
  request: () => action(GET_LICENSEE_CLUBS[REQUEST]),
  success: (payload) => action(GET_LICENSEE_CLUBS[SUCCESS], {payload}),
  failure: (payload) => action(GET_LICENSEE_CLUBS[FAILURE], {payload}),
}
