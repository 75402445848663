import * as ActionTypes from '../actions/Comments'
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  comments: [],
  commentsUserId: ''
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.SET_COMMENT_USER_ID:
      return { ...state, commentsUserId: action.payload }
    case ActionTypes.GET_COMMENTS.SUCCESS:
      return { ...state, comments: action.payload }
    default:
      return state;
  }
}
