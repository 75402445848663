import * as ActionTypes from '../actions/Membership'
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  memberships: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.GET_MEMBERSHIPS.SUCCESS:
      return { ...state, memberships: action.payload }
    default:
      return state;
  }
}
