import React from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { Line } from 'rc-progress';
import './styles.scss';

const Component = ({ loading, progress }) => (
  <div className="d-loading" style={{ zIndex: loading ? 10000 : -1 }}>
    <BlockUi tag="div" blocking={loading} className="block-overlay-dark"
      loader={<Loader color="#ffffff" active type={"ball-triangle-path"}/>}>
    </BlockUi>
    <div className="rc-line-wrapper">
      <Line percent={progress} strokeLinecap={'square'} />
    </div>
  </div>
)

export default Component;
