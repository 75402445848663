import * as ActionTypes from 'actions/Documents';
import * as AuthActionTypes from 'actions/Auth';
import {get} from "lodash";

const initialState = {
  documents: {},
  sharedDocuments: {},
  userDocuments: [],
  document: null,
  logsPage: 0,
  logsPages: 0,
  filteredDocuments: [],
  logsInitialPage: 0,
  logsPageSize: 100,
  searchedDocumentsPageSize: 100,
  logs: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState }
    case ActionTypes.SET_LOGS_PAGE:
      return { ...state, logsInitialPage: action.payload };
    case ActionTypes.SET_LOGS_PAGE_SIZE:
      return { ...state, logsPageSize: action.payload };
    case ActionTypes.SET_SEARCHED_DOCUMENTS_PAGE_SIZE:
      return { ...state, searchedDocumentsPageSize: action.payload };
    case ActionTypes.GET_SHARED_DOCUMENTS.SUCCESS:
      return {
        ...state,
        sharedDocuments: action.payload
      }
    case ActionTypes.GET_DOCUMENTS.SUCCESS:
      return {
        ...state,
        documents: action.payload
      }
    case ActionTypes.GET_LOGS.SUCCESS:
      return {
        ...state,
        logs: action.payload.logs,
        logsPage: get(action, 'payload.currentPage', 0),
        totalLogs: action.payload.totalPages,
      };
    case ActionTypes.GET_FILTERED_DOCUMENTS.SUCCESS:
      return {
        ...state,
        filteredDocuments: get(action, 'payload', []),
        // filteredDocumentPage: get(action, 'payload.currentPage', 0),
        // totalFilteredDocPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_SEARCHED_DOCUMENTS.SUCCESS:
      return {
        ...state,
        searchedDocuments: action.payload.content,
        searchedDocumentsPage: get(action, 'payload.currentPage', 0),
        totalSearchedDocuments: action.payload.totalElements,
      }
    case ActionTypes.GET_USER_DOCUMENTS.SUCCESS:
      return {
        ...state,
        userDocuments: action.payload
      }
    case ActionTypes.GET_DOCUMENT.SUCCESS:
    case ActionTypes.GET_DOCUMENT_BY_VERSION.SUCCESS:
      return {
        ...state,
        document: action.payload
      }
    default:
      return state;
  }
}
