import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_DEVICE_INFO_STATS = createRequestTypes('GET_DEVICE_INFO_STATS');
export const GET_DEVICE_INFO_STATS_DETAILS = createRequestTypes('GET_DEVICE_INFO_STATS_DETAILS');
export const GET_DEVICE_INFO_WHITELIST = createRequestTypes('GET_DEVICE_INFO_WHITELIST');
export const GET_DEVICE_INFO_BLACKLIST = createRequestTypes('GET_DEVICE_INFO_BLACKLIST');
export const DELETE_DEVICE_INFO = createRequestTypes('DELETE_DEVICE_INFO');
export const DELETE_DEVICE_INFO_STATS = createRequestTypes('DELETE_DEVICE_INFO_STATS');
export const GET_BLACKLIST_DEVICE_INFO = createRequestTypes('GET_BLACKLIST_DEVICE_INFO');
export const UPDATE_BLACKLIST_DEVICE_INFO = createRequestTypes('UPDATE_BLACKLIST_DEVICE_INFO');
export const SET_DEVICE_INFO_TABLE_FILTER = 'SET_DEVICE_INFO_TABLE_FILTER';
export const SET_DEVICE_INFO_PAGE = 'SET_DEVICE_INFO_PAGE';
export const SET_DEVICE_INFO_PAGE_SIZE = 'SET_DEVICE_INFO_PAGE_SIZE';

export const deleteDeviceInfo = {
  request: (data) => action(DELETE_DEVICE_INFO[REQUEST], {data}),
  success: (payload) => action(DELETE_DEVICE_INFO[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_DEVICE_INFO[FAILURE], {payload}),
}

export const deleteDeviceStatsInfo = {
  request: (data) => action(DELETE_DEVICE_INFO_STATS[REQUEST], {data}),
  success: (payload) => action(DELETE_DEVICE_INFO_STATS[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_DEVICE_INFO_STATS[FAILURE], {payload}),
}

export const getDeviceInfoStats = {
  request: (data) => action(GET_DEVICE_INFO_STATS[REQUEST], {data}),
  success: (payload) => action(GET_DEVICE_INFO_STATS[SUCCESS], {payload}),
  failure: (payload) => action(GET_DEVICE_INFO_STATS[FAILURE], {payload}),
}

export const getDeviceStatsInfoDetails = {
  request: (data) => action(GET_DEVICE_INFO_STATS_DETAILS[REQUEST], {data}),
  success: (payload) => action(GET_DEVICE_INFO_STATS_DETAILS[SUCCESS], {payload}),
  failure: (payload) => action(GET_DEVICE_INFO_STATS_DETAILS[FAILURE], {payload}),
}

export const getBlacklistInfoDetails = {
  request: (id) => action(GET_BLACKLIST_DEVICE_INFO[REQUEST], {id}),
  success: (payload) => action(GET_BLACKLIST_DEVICE_INFO[SUCCESS], {payload}),
  failure: (payload) => action(GET_BLACKLIST_DEVICE_INFO[FAILURE], {payload}),
}

export const updateBlacklistDetails = {
  request: (data) => action(UPDATE_BLACKLIST_DEVICE_INFO[REQUEST], {data}),
  success: (payload) => action(UPDATE_BLACKLIST_DEVICE_INFO[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_BLACKLIST_DEVICE_INFO[FAILURE], {payload}),
}

export const getDeviceInfoWhiteList = {
  request: (data) => action(GET_DEVICE_INFO_WHITELIST[REQUEST], {data}),
  success: (payload) => action(GET_DEVICE_INFO_WHITELIST[SUCCESS], {payload}),
  failure: (payload) => action(GET_DEVICE_INFO_WHITELIST[FAILURE], {payload}),
}

export const getDeviceInfoBlackList = {
  request: (data) => action(GET_DEVICE_INFO_BLACKLIST[REQUEST], {data}),
  success: (payload) => action(GET_DEVICE_INFO_BLACKLIST[SUCCESS], {payload}),
  failure: (payload) => action(GET_DEVICE_INFO_BLACKLIST[FAILURE], {payload}),
}

export const setDeviceInfoTableFilter = (payload) => ({
  type: SET_DEVICE_INFO_TABLE_FILTER,
  payload
})

export const setDeviceInfoPage = (payload) => ({
  type: SET_DEVICE_INFO_PAGE,
  payload
})

export const setDeviceInfoPageSize = (payload) => ({
  type: SET_DEVICE_INFO_PAGE_SIZE,
  payload
})
