import { takeEvery } from 'redux-saga/effects';
import * as studioActions from '../actions/Studio';
import api from 'utils/api';
import { apiRequest } from './index';

import {
    toast,
    Slide
} from 'react-toastify';

const getDefaultStudioNews = apiRequest.bind(null, studioActions.getDefaultStudioInfo, api.getDefaultStudioInfo);
const getLicenseeStudioNews = apiRequest.bind(null, studioActions.getLicenseeStudioInfo, api.getLicenseeStudioInfo);
const createDefaultStudioNews = apiRequest.bind(null, studioActions.createDefaultStudioInfo, api.createDefaultStudioInfo);
const createLicenseeStudioNews = apiRequest.bind(null, studioActions.createLicenseeStudioInfo, api.createLicenseeStudioInfo);
const updateDefaultStudioNews = apiRequest.bind(null, studioActions.updateDefaultStudioInfo, api.updateDefaultStudioInfo);
const updateLicenseeStudioNews = apiRequest.bind(null, studioActions.updateLicenseeStudioInfo, api.updateLicenseeStudioInfo);

const uploadDefaultStudioImage = apiRequest.bind(null, studioActions.uploadDefaultStudioImage, api.uploadStudioScreenImage);
const uploadDefaultStudioVideo = apiRequest.bind(null, studioActions.uploadDefaultStudioVideo, api.uploadStudioScreenVideo);
const uploadLicenseeStudioImage = apiRequest.bind(null, studioActions.uploadLicenseeStudioImage, api.uploadStudioScreenImage);
const uploadLicenseeStudioVideo = apiRequest.bind(null, studioActions.uploadLicenseeStudioVideo, api.uploadStudioScreenVideo);
const uploadHeaderImage = apiRequest.bind(null, studioActions.uploadHeaderImage, api.uploadStudioHeaderImage);

function* handleNewsUpdateSuccess() {
  yield toast('Success', {
        transition: Slide,
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
        type: 'success'
    });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLicenseeNewsCreateSuccess() {
  yield takeEvery(studioActions.CREATE_LICENSEE_STUDIO_INFO.SUCCESS, handleNewsUpdateSuccess)
}

export function* watchLicenseeNewsUpdateSuccess() {
  yield takeEvery(studioActions.UPDATE_LICENSEE_STUDIO_INFO.SUCCESS, handleNewsUpdateSuccess)
}

export function* watchDefaultNewsCreateSuccess() {
  yield takeEvery(studioActions.CREATE_DEFAULT_STUDIO_INFO.SUCCESS, handleNewsUpdateSuccess)
}

export function* watchDefaultNewsUpdateSuccess() {
  yield takeEvery(studioActions.UPDATE_DEFAULT_STUDIO_INFO.SUCCESS, handleNewsUpdateSuccess)
}

export function* watchLoadDefaultStudioNews() {
  yield takeEvery(studioActions.GET_DEFAULT_STUDIO_INFO.REQUEST, getDefaultStudioNews)
}

export function* watchLoadLicenseeStudioNews() {
  yield takeEvery(studioActions.GET_LICENSEE_STUDIO_INFO.REQUEST, getLicenseeStudioNews)
}

export function* watchCreateDefaultStudioNews() {
  yield takeEvery(studioActions.CREATE_DEFAULT_STUDIO_INFO.REQUEST, createDefaultStudioNews)
}

export function* watchCreateLicenseeStudioNews() {
  yield takeEvery(studioActions.CREATE_LICENSEE_STUDIO_INFO.REQUEST, createLicenseeStudioNews)
}

export function* watchUpdateDefaultStudioNews() {
  yield takeEvery(studioActions.UPDATE_DEFAULT_STUDIO_INFO.REQUEST, updateDefaultStudioNews)
}

export function* watchUpdateLicenseeStudioNews() {
  yield takeEvery(studioActions.UPDATE_LICENSEE_STUDIO_INFO.REQUEST, updateLicenseeStudioNews)
}

export function* watchUploadDefaultStudioImage() {
  yield takeEvery(studioActions.UPLOAD_DEFAULT_STUDIO_IMAGE.REQUEST, uploadDefaultStudioImage)
}

export function* watchUploadDefaultStudioVideo() {
  yield takeEvery(studioActions.UPLOAD_DEFAULT_STUDIO_VIDEO.REQUEST, uploadDefaultStudioVideo)
}

export function* watchUploadLicenseeStudioImage() {
  yield takeEvery(studioActions.UPLOAD_LICENSEE_STUDIO_IMAGE.REQUEST, uploadLicenseeStudioImage)
}

export function* watchUploadLicenseeStudioVideo() {
  yield takeEvery(studioActions.UPLOAD_LICENSEE_STUDIO_VIDEO.REQUEST, uploadLicenseeStudioVideo)
}

export function* watchUploadStudioHeaderImageVideo() {
  yield takeEvery(studioActions.UPLOAD_HEADER_IMAGE.REQUEST, uploadHeaderImage)
}
