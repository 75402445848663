import { takeEvery, select } from 'redux-saga/effects';
import * as deviceInfoActions from 'actions/DeviceInfo';
import api from 'utils/api';
import { get } from 'lodash';
import { apiRequest } from './index';

/***************************** Subroutines ************************************/

// Get device info
const fetchDeviceInfoStats = apiRequest.bind(null, deviceInfoActions.getDeviceInfoStats, api.getDeviceInfoStats);
const fetchDeviceInfoWhiteList = apiRequest.bind(null, deviceInfoActions.getDeviceInfoWhiteList, api.getDeviceInfoWhiteList);
const fetchDeviceInfoBlackList = apiRequest.bind(null, deviceInfoActions.getDeviceInfoBlackList, api.getDeviceInfoBlackList);
const fetchDeviceInfoStatsDetails = apiRequest.bind(null, deviceInfoActions.getDeviceStatsInfoDetails, api.getDeviceInfoStatsDetail);
const deleteDeviceInfo = apiRequest.bind(null, deviceInfoActions.deleteDeviceInfo, api.deleteDeviceInfo);
const deleteDeviceStatsInfo = apiRequest.bind(null, deviceInfoActions.deleteDeviceStatsInfo, api.deleteDeviceStatsInfo);
const getBlacklistInfoDetails = apiRequest.bind(null, deviceInfoActions.getBlacklistInfoDetails, api.getBlacklistInfoDetails);
const updateBlacklistDetails = apiRequest.bind(null, deviceInfoActions.updateBlacklistDetails, api.updateBlacklistDetails);

function* refreshDeviceStatsInfo() {
  const state = yield select();
  const tableFilter = get(state, 'DeviceInfo.tableFilterStatistics', '');
  const prevData = window.lastFetchParamsStatsInfo || {};
  const arg = {
    ...get(arguments, '0', {}),
    data: {
      ...prevData,
      page: tableFilter ? 0 : get(state, 'DeviceInfo.deviceInfoStatsPage', 0),
      pageSize: get(state, 'DeviceInfo.deviceInfoStatsPageSize', 100)
    }
  };
  yield apiRequest.apply(null, [deviceInfoActions.getDeviceInfoStats, api.getDeviceInfoStats, arg]);
}

function* refreshDeviceListInfo(action) {
  const type = action.payload.type;
  const state = yield select();

  let tableFilter;
  let prevData = {};
  let pageSize;
  let page;

  if (type === 'whitelist') {
    tableFilter = get(state, 'DeviceInfo.tableFilterWhiteList', '');
    prevData = window.lastFetchParamsWhitelistInfo || {};
    page = tableFilter ? 0 : get(state, 'DeviceInfo.deviceInfoWhiteListPage', 0)
    pageSize = get(state, 'DeviceInfo.deviceInfoWhiteListPageSize', 100)
  } else {
    tableFilter = get(state, 'DeviceInfo.tableFilterBlackList', '');
    prevData = window.lastFetchParamsBlacklistInfo || {};
    page = tableFilter ? 0 : get(state, 'DeviceInfo.deviceInfoBlackListPage', 0)
    pageSize = get(state, 'DeviceInfo.deviceInfoBlackListPageSize', 100)
  }

  const arg = {
    ...get(arguments, '0', {}),
    data: {
      ...prevData,
      page,
      pageSize
    }
  };

  if (type === 'whitelist') {
    yield apiRequest.apply(null, [deviceInfoActions.getDeviceInfoWhiteList, api.getDeviceInfoWhiteList, arg]);
  } else {
    yield apiRequest.apply(null, [deviceInfoActions.getDeviceInfoBlackList, api.getDeviceInfoBlackList, arg]);
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLoadDeviceInfoStats() {
  yield takeEvery(deviceInfoActions.GET_DEVICE_INFO_STATS.REQUEST, fetchDeviceInfoStats)
}

export function* watchLoadDeviceInfoStatsDetails() {
  yield takeEvery(deviceInfoActions.GET_DEVICE_INFO_STATS_DETAILS.REQUEST, fetchDeviceInfoStatsDetails)
}

export function* watchLoadDeviceInfoWhiteList() {
  yield takeEvery(deviceInfoActions.GET_DEVICE_INFO_WHITELIST.REQUEST, fetchDeviceInfoWhiteList)
}

export function* watchLoadDeviceInfoBlackList() {
  yield takeEvery(deviceInfoActions.GET_DEVICE_INFO_BLACKLIST.REQUEST, fetchDeviceInfoBlackList)
}

export function* watchDeleteDeviceInfoRequest() {
  yield takeEvery(deviceInfoActions.DELETE_DEVICE_INFO.REQUEST, deleteDeviceInfo)
}

export function* watchDeleteDeviceInfoStatsRequest() {
  yield takeEvery(deviceInfoActions.DELETE_DEVICE_INFO_STATS.REQUEST, deleteDeviceStatsInfo)
}

export function* watchDeleteDeviceInfoSuccess() {
  yield takeEvery(deviceInfoActions.DELETE_DEVICE_INFO.SUCCESS, refreshDeviceListInfo)
}

export function* watchGetBlacklistDetailsRequest() {
  yield takeEvery(deviceInfoActions.GET_BLACKLIST_DEVICE_INFO.REQUEST, getBlacklistInfoDetails)
}

export function* watchDeleteDeviceInfoStatsSuccess() {
  yield takeEvery(deviceInfoActions.DELETE_DEVICE_INFO_STATS.SUCCESS, refreshDeviceStatsInfo)
}

export function* watchUpdateBlacklistInfoRequest() {
  yield takeEvery(deviceInfoActions.UPDATE_BLACKLIST_DEVICE_INFO.REQUEST, updateBlacklistDetails)
}
