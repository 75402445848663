import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_TRAININGS = createRequestTypes('GET_TRAININGS');
export const GET_TRAINING = createRequestTypes('GET_TRAINING');
export const DELETE_TRAINING = createRequestTypes('DELETE_TRAINING');
export const GET_TRAINING_CATEGORIES = createRequestTypes('GET_TRAINING_CATEGORIES');
export const CREATE_TRAINING = createRequestTypes('CREATE_TRAINING');
export const UPDATE_TRAINING = createRequestTypes('UPDATE_TRAINING');
export const CONVERT_TRAINING_VIDEO = createRequestTypes('CONVERT_TRAINING_VIDEO');
export const PUSH_TRAINING_VIDEO = createRequestTypes('PUSH_TRAINING_VIDEO');
export const PUSH_SCORE_CARD = createRequestTypes('PUSH_SCORE_CARD');
export const PUSH_VIDEO_COMMAND = createRequestTypes('PUSH_VIDEO_COMMAND');
export const UPLOAD_TEASER_VIDEO = createRequestTypes('UPLOAD_TEASER_VIDEO');
export const GET_TRAINING_EVENTS_BY_PRINCIPAL = createRequestTypes('GET_TRAINING_EVENTS_BY_PRINCIPAL');
export const SET_TRAINING_STATUS = createRequestTypes('SET_TRAINING_STATUS');
export const COPY_TRAINING = createRequestTypes('COPY_TRAINING');
export const SET_TRAININGS_PAGE = 'SET_TRAININGS_PAGE';
export const SET_TRAININGS_TABLE_FILTER = 'SET_TRAININGS_TABLE_FILTER';
export const SET_TRAININGS_TABLE_FILTER_CATEGORY = 'SET_TRAININGS_TABLE_FILTER_CATEGORY';
export const SET_TRAINING_PAGE_SIZE = 'SET_TRAINING_PAGE_SIZE';
export const SUBSCRIBE_USERS_TO_TRAINING = 'SUBSCRIBE_USERS_TO_TRAINING';
export const UNSUBSCRIBE_USER_FROM_TRAINING = 'UNSUBSCRIBE_USER_FROM_TRAINING';
export const SET_LOGS_TABLE_FILTER = 'SET_LOGS_TABLE_FILTER';

export const setLogsTableFilter = (payload) => ({
  type: SET_LOGS_TABLE_FILTER,
  payload 
})

export const setTrainingPageSize = (payload) => ({
  type: SET_TRAINING_PAGE_SIZE,
  payload
})

export const setTrainingsTableFilter = (payload) => ({
  type: SET_TRAININGS_TABLE_FILTER,
  payload
})

export const subscribeUsersToTraining = (payload) => ({
  type: SUBSCRIBE_USERS_TO_TRAINING,
  payload
});

export const setTrainingTableFilterCategory = (payload) => ({
  type: SET_TRAININGS_TABLE_FILTER_CATEGORY,
  payload
})

export const getTrainings = {
  request: () => action(GET_TRAININGS[REQUEST]),
  success: (payload) => action(GET_TRAININGS[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRAININGS[FAILURE], {payload}),
}

export const getTraining = {
  request: (id) => action(GET_TRAINING[REQUEST], {id}),
  success: (payload) => action(GET_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRAINING[FAILURE], {payload}),
}

export const createTraining = {
  request: (data) => action(CREATE_TRAINING[REQUEST], {data}),
  success: (payload) => action(CREATE_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_TRAINING[FAILURE], {payload}),
}

export const updateTraining = {
  request: (id, data) => action(UPDATE_TRAINING[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_TRAINING[FAILURE], {payload}),
}

export const deleteTraining = {
  request: (id) => action(DELETE_TRAINING[REQUEST], {id}),
  success: (payload) => action(DELETE_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_TRAINING[FAILURE], {payload}),
}

export const getTrainingCategories = {
  request: (id) => action(GET_TRAINING_CATEGORIES[REQUEST], {id}),
  success: (payload) => action(GET_TRAINING_CATEGORIES[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRAINING_CATEGORIES[FAILURE], {payload}),
}

export const convertTrainingVideo = {
  request: (data) => action(CONVERT_TRAINING_VIDEO[REQUEST], {data}),
  success: (payload) => action(CONVERT_TRAINING_VIDEO[SUCCESS], {payload}),
  failure: (payload) => action(CONVERT_TRAINING_VIDEO[FAILURE], {payload}),
}

export const pushTrainingVideo = {
  request: (data) => action(PUSH_TRAINING_VIDEO[REQUEST], {data}),
  success: (payload) => action(PUSH_TRAINING_VIDEO[SUCCESS], {payload}),
  failure: (payload) => action(PUSH_TRAINING_VIDEO[FAILURE], {payload}),
}

export const pushScoreCard = {
  request: (data) => action(PUSH_SCORE_CARD[REQUEST], {data}),
  success: (payload) => action(PUSH_SCORE_CARD[SUCCESS], {payload}),
  failure: (payload) => action(PUSH_SCORE_CARD[FAILURE], {payload}),
}

export const pushVideoCommand = {
  request: (data) => action(PUSH_VIDEO_COMMAND[REQUEST], {data}),
  success: (payload) => action(PUSH_VIDEO_COMMAND[SUCCESS], {payload}),
  failure: (payload) => action(PUSH_VIDEO_COMMAND[FAILURE], {payload}),
}

export const uploadTeaserVideo = {
  request: (data) => action(UPLOAD_TEASER_VIDEO[REQUEST], {data}),
  success: (payload) => action(UPLOAD_TEASER_VIDEO[SUCCESS], {payload}),
  failure: (payload) => action(UPLOAD_TEASER_VIDEO[FAILURE], {payload})
}

export const getTrainingEventsByPrincipal = {
  request: (data) => action(GET_TRAINING_EVENTS_BY_PRINCIPAL[REQUEST], {data}),
  success: (payload) => action(GET_TRAINING_EVENTS_BY_PRINCIPAL[SUCCESS], {payload}),
  failure: (payload) => action(GET_TRAINING_EVENTS_BY_PRINCIPAL[FAILURE], {payload})
}

export const setTrainingStatus = {
  request: (id, data) => action(SET_TRAINING_STATUS[REQUEST], {id, data}),
  success: (payload) => action(SET_TRAINING_STATUS[SUCCESS], {payload}),
  failure: (payload) => action(SET_TRAINING_STATUS[FAILURE], {payload})
}

export const copyTraining = {
  request: (id) => action(COPY_TRAINING[REQUEST], {id}),
  success: (payload) => action(COPY_TRAINING[SUCCESS], {payload}),
  failure: (payload) => action(COPY_TRAINING[FAILURE], {payload})
}
