import ThemeOptions from './ThemeOptions';
import UserRoles from './UserRoles';
import Licensee from './Licensee';
import Notifications from './Notifications';
import Club from './Club';
import Loading from './Loading';
import Auth from './Auth';
import Admin from './Admin';
import Membership from './Membership';
import Training from './Training';
import Media from './Media';
import Studio from './Studio';
import Calendar from './Calendar';
import Comments from './Comments';
import Documents from './Documents';
import Trash from './Trash';
import LicenseModels from './LicenseModels';
import Groups from './Groups';
import Reports from './Reports';
import DeviceInfo from './DeviceInfo';

export default {
    ThemeOptions,
    UserRoles,
    Licensee,
    Notifications,
    Membership,
    Training,
    Calendar,
    Loading,
    Club,
    Auth,
    Admin,
    Media,
    Studio,
    Comments,
    Documents,
    Trash,
    Groups,
    LicenseModels,
    Reports,
    DeviceInfo, 
};
