import { put, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import history from 'utils/history';
import api from 'utils/api';
import * as authActions from 'actions/Auth';
import * as licenseeActions from 'actions/Licensee';
import { matchesUnauthPath } from 'utils/navHelper';
import { get } from 'lodash';
import jwtDecode from 'jwt-decode';
import { checkTokenExpiration, checkRefreshTokenExpiration } from 'utils/token';

const loginUser = apiRequest.bind(null, authActions.login, api.login);
const refreshToken = apiRequest.bind(null, authActions.getNewToken, api.refreshToken);

const getUserRole = (decoded) => get(decoded, 'realm_access.roles', []).reduce((acc, el) => {
  if (el === 'admin' || acc === 'admin') return 'admin';
  if (el === 'licensee' || acc === 'licensee') return 'licensee';
  if (el === 'user' || acc || 'user') return 'user';
  return acc;
}, '');

function* getToken() {
  const token = checkTokenExpiration();
  if (token && token !== 'expired') {
    const decoded = jwtDecode(token);
    const userRole = getUserRole(decoded);
    yield put ({ type: 'SET_USER_ROLE', payload: userRole });
    yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
  }
  else {
    if (token === 'expired') {
      const refreshToken = checkRefreshTokenExpiration();
      if (!refreshToken || refreshToken === 'expired') {
        yield put({ type: authActions.GET_TOKEN.FAILURE, payload: { error: { message: 'Session expired' }} });
      } else {
        yield put({ type: authActions.GET_NEW_TOKEN.REQUEST, data: refreshToken })
      }
    }
    else yield put({ type: authActions.GET_TOKEN.FAILURE });
  }
}

function* saveToken(action)  {
  const { access_token, refresh_token, decoded, isRefresh } = action.payload;
  const userRole = getUserRole(decoded);
  window.localStorage.setItem('BC_TOKEN', access_token);
  window.localStorage.setItem('BC_REFRESH_TOKEN', refresh_token);
  yield put ({ type: 'SET_USER_ROLE', payload: userRole, isRedirect: isRefresh ? false : true });
  yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
}

function* removeToken() {
  window.localStorage.removeItem('BC_TOKEN');
  window.localStorage.removeItem('BC_REFRESH_TOKEN');
  yield put ({ type: licenseeActions.USERS.SUCCESS, payload: [] }); // clear users
  yield put ({ type: 'SET_USER_ROLE', payload: '' });
  yield put ({ type: authActions.LOGOUT.SUCCESS });
  if (!matchesUnauthPath(history.location.pathname)) {
    history.push('/login');
  }
}

export function* handleInvalidToken() {
  yield put({ type: authActions.LOGOUT.REQUEST });
  yield put({ type: authActions.GET_TOKEN.FAILURE, payload: { error: { message: 'Session expired' }} });
  if (!matchesUnauthPath(history.location.pathname)) {
    history.push('/login');
  }
}

export function* handleSetUserRole(action) {
  if (action.isRedirect) {
    if (action.payload === 'admin') yield history.push('/users');
    if (action.payload === 'licensee') yield history.push('/users');
    if (action.payload === 'user') yield history.push('/my-training');

  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetToken() {
  yield takeEvery(authActions.GET_TOKEN.REQUEST, getToken)
}

export function* watchLoginUser() {
  yield takeEvery(authActions.LOGIN.REQUEST, loginUser)
}

export function* watchLoginSuccess() {
  yield takeEvery(authActions.LOGIN.SUCCESS, saveToken)
}

export function* watchLogout() {
  yield takeEvery(authActions.LOGOUT.REQUEST, removeToken)
}

export function* watchGetTokenFailure() {
  yield takeEvery(authActions.GET_TOKEN.FAILURE, removeToken)
}

export function* watchRefreshToken() {
  yield takeEvery(authActions.GET_NEW_TOKEN.REQUEST, refreshToken)
}

export function* watchRefreshTokenSuccess() {
  yield takeEvery(authActions.GET_NEW_TOKEN.SUCCESS, saveToken)
}

export function* watchRefreshTokenFailure() {
  yield takeEvery(authActions.GET_NEW_TOKEN.FAILURE, handleInvalidToken)
}

export function* watchSetUserRole() {
  yield takeEvery('SET_USER_ROLE', handleSetUserRole)
}
