import { get } from 'lodash';

const setNodeByIdNew = (tree, parts, node, index) => {
  let options = tree;
  return parts.reduce((acc, id, i) => {
    const pathComponents = id.split(":");
    if (i < parts.length - 1) {
      const index = options.findIndex(el => id === el.id);
      options = get(options, `${index}.children`, []);
    } else {
      const index = options.findIndex(el => pathComponents[0] === el.id);
      if (index !== -1) {
        if (!options[index].children) options[index].children = [];
        options[index].children.push(node);
      }
    }
    return tree;
  }, tree);
}

const setSharedNode = (tree, parts, node, index) => {
  let options = tree;
  return parts.reduce((acc, id, i) => {
    const pathComponents = id.split(":");
    if (i < parts.length - 1) {
      const index = options.findIndex(el => id === el.id);
      if (index !== -1) {
        options = get(options, `${index}.children`, []);
      }
    } else {
      const index = options.findIndex(el => pathComponents[0] === el.id);
      if (index !== -1) {
        if (!options[index].children) options[index].children = [];
        options[index].children.push(node);
      } else {
        tree.push(node);
      }
    }
    return tree;
  }, tree);
}

export const flatDataToTreeNew = (flatData, noDocs = false) => {
  if (!noDocs) window.docIdsMap = {};
  else window.sharedDocIdsMap = {};
  const tree = flatData.reduce((acc, el, i) => {
    const elName = get(el, `currentVersion.name`);
    const newNode = {
      ...el,
      title: elName,
      name: elName,
      isDirectory: el.type === 'dir',
      children: [],
      expanded: get(window, `expNodes.${el.id}`, false),
      canNodeHaveChildren: el.type === 'dir'
    }
    if (!noDocs) window.docIdsMap[el.id] = newNode;
    else window.sharedDocIdsMap[el.id] = newNode;
    if (!el.path) return acc;
    const pathComponents = el.path.split(":");
    if (pathComponents.length < 2) return acc;
    if (pathComponents.length === 2 && get(pathComponents, '0') === "") {
      acc.push(newNode);
      return acc;
    } else {
      const parts = el.path.split(".");
      return noDocs ? setSharedNode(acc, parts, newNode, i) : setNodeByIdNew(acc, parts, newNode, i);
    }
  }, []);

  return tree;
}

export const flatDataToTreeNewTrash = (flatData, noDocs = false) => {
  if (!noDocs) window.docIdsMap = {};
  else window.sharedDocIdsMap = {};
  const tree = flatData.reduce((acc, el, i) => {
    const elName = get(el, `currentVersion.name`);
    const newNode = {
      ...el,
      title: elName,
      name: elName,
      isDirectory: el.type === 'dir',
      children: [],
      expanded: get(window, `expNodes.${el.id}`, false),
      canNodeHaveChildren: el.type === 'dir'
    }
    if (!noDocs) window.docIdsMap[el.id] = newNode;
    else window.sharedDocIdsMap[el.id] = newNode;
    if (!el.path) {
      acc.push(newNode);
      return acc;
    }
    const pathComponents = el.path.split(":");
    if (pathComponents.length < 2) return acc;
    if (pathComponents.length === 2 && get(pathComponents, '0') === "") {
      acc.push(newNode);
      return acc;
    } else {
      const parts = el.path.split(".");
      return noDocs ? setSharedNode(acc, parts, newNode, i) : setNodeByIdNew(acc, parts, newNode, i);
    }
  }, []);

  return tree;
}
